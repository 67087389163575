import React, { useEffect, useState } from 'react'
import Card from '../../../../uiComponents/Card'
import Badge from '../../../../uiComponents/common/Badge'
import { withTranslation } from 'react-i18next';
import RowWrap from '../../../../uiComponents/RowWrap';
import InputWrap from '../../../../uiComponents/inputs/InputWrap';
import InputRadioBox from '../../../../uiComponents/inputs/InputRadioBox';
import LabelWrap from '../../../../uiComponents/inputs/LabelWrap';
import TextareaWrap, { inputVariance } from '../../../../uiComponents/inputs/TextareaWrap';
import LocationPin2 from '../../../../uiComponents/icons/LocationPin2';
import InfoFIllIcon from '../../../../uiComponents/icons/InfoFIllIcon';
import InputCheckbox from '../../../../uiComponents/inputs/InputCheckbox';
import LocationPin3 from '../../../../uiComponents/icons/LocationPin3';
import SalonChairIcon from '../../../../uiComponents/icons/SalonChairIcon';
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary';
import PencilIcon from '../../../../uiComponents/icons/PencilIcon';
import UserIcon1 from '../../../../uiComponents/icons/UserIcon1';
import { cn } from '../../../../../../lib/utils';
import SpecialitiesStarIcon from '../../../../uiComponents/icons/SpecialitiesStarIcon';
import Divider from '../../../../uiComponents/Divider';
import InputWithIcon from '../../../../uiComponents/inputs/InputWithIcon';
import TimerClockIcon from '../../../../uiComponents/icons/TimerClockIcon';
import HairIcon from '../../../../uiComponents/icons/HairIcon';
import ChooseSpecialitiesModal from '../../../../modals/ChooseSpecialitiesModal';
import SpecialitiesListCard from '../../../../uiComponents/common/SpecialitiesListCard';
import PlusLineIcon from '../../../../uiComponents/icons/PlusLineIcon';
import CityLocationIcon from '../../../../uiComponents/icons/CityLocationIcon';
import CarIcon from '../../../../uiComponents/icons/CarIcon';
import CustomTabs from '../../../../uiComponents/tabs/CustomTabs';
import RequestForNewOnTheGoCityModal from '../../../../modals/RequestForNewOnTheGoCityModal';
import { ActiveInactiveSwitch } from '../../../../uiComponents/common/ActiveInactiveSwitch';
import ActiveInactiveCompo from '../../../../uiComponents/common/ActiveInactiveCompo';
import InputCheckBoxGroup from '../../../../uiComponents/inputs/InputCheckBoxGroup';
import InputRadioBoxGroup from '../../../../uiComponents/inputs/InputRadioBoxGroup';
import * as Yup from "yup"
import { Form, Formik, FormikProvider, useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import Http from '../../../../../../Http';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios';
import { authStore } from '../../../../../contexts/AuthProvider';
import { PATH_WORKER } from '../../../../../routes/path';
import { Navigate, useNavigate } from 'react-router-dom';
import DollarRoundIcon from '../../../../uiComponents/icons/DollarRoundIcon';


const WorkerProfile = ({ t, setStep, stepsNextClick, workerProfileData, serviceLocation, salonLocation, handelDummyNextClick, ...props }) => {
  const [specialitiesModalOpen, setSpecialitiesModalOpen] = useState(false)
  const [specialitiesList, setSpecialitiesList] = useState([])
  const [specialitiesIds, setSpecialitiesIds] = useState([])
  const [getData, setGetData] = useState([]);
  const [requestForNewOnTheGoCityModalOpen, setRequestForNewOnTheGoCityModalOpen] = useState(false)
  const addNewCityButtonForWorker = true
  const [onSalone, setOnSalone] = useState(true);
  const [onTheGoCheckd, setOnTheGoCheckd] = useState(true);
  const [serviceLocationData, setServiceLocationData] = useState(3);
  const [hairType, setHairType] = useState([]);
  const salonDataId = authStore((state) => state.salonDataId);
  const updateSaloneDataId = authStore((state) => state.updateSaloneDataId);
  const BecomeWorkerCompleteStep = authStore((state) => state.BecomeWorkerCompleteStep);
  const updateBecomeWorkerStep = authStore((state) => state.updateBecomeWorkerStep);
  const becomeWorkerStep = authStore((state) => state.becomeWorkerStep);
  const userType = authStore(state => state.profileType)
  const is_profile_complete = authStore((state) => state.become_worker_profile);
  const [hairTypeSelectItems, setHairTypeSelectItems] = useState([])
  const [isDummy, setIsDummy] = useState(true);
  const queryClient = useQueryClient();

  const navigate = useNavigate();


  const [onTheGoCityCard, setOnTheGoCityCard] = useState([{
    id: 0,
    mapLocation: "",
    transportTime: "",
    transportFees: ""
  },
  {
    id: 1,
    mapLocation: "",
    transportTime: "",
    transportFees: ""
  }])


  const { data: hairTypes } = useQuery(
    ["hairTypes"],
    async () => {
      return await Http.get(PROFILE_COMPLETE_URL.hairTypes).then(
        (res) => {
          // setLoadingScreen(false);
          return res?.data?.data
        }
      );
    },
    { refetchOnWindowFocus: false }
  );


  const hairTypeData = hairTypes?.map(hairType => ({
    id: hairType.name.toLowerCase(),
    label: hairType.name,
    value: hairType.id,
  }));


  const { mutateAsync: updateProfile } = useMutation(
    async (data) => {
      try {
        if (!salonDataId) {
          return await Http.post(PROFILE_COMPLETE_URL.storeBecomeWorker, data);
        } else {
          return await Http.post(PROFILE_COMPLETE_URL.storeWorkerProfile, data);
        }
      } catch (error) {
        const errorData = error?.response?.data?.errors;
        if (errorData) {
          Object.values(errorData).forEach((value) => {
            enqueueSnackbar(value, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }
      }
    },
    {
      onSuccess: ({ data }) => {
        if (userType == 4) {
          queryClient.invalidateQueries(["workerProfileData2"])
        } else
          queryClient.invalidateQueries(["workerProfileData"])
        if (is_profile_complete === 0) {
          // updateUserType(1)
          if (salonDataId && becomeWorkerStep.includes(1)) {
            const updatedArray = [...becomeWorkerStep, 2];
            updateBecomeWorkerStep(updatedArray);
            stepsNextClick();
          } else if (data.data.is_complete == 1) {
            BecomeWorkerCompleteStep(1)
            navigate(PATH_WORKER.dashboard)
          } else if (!salonDataId && becomeWorkerStep.includes(1)) {
            const updatedArray = [...becomeWorkerStep, 3];
            updateBecomeWorkerStep(updatedArray);
            handelDummyNextClick();
          }
        }

        if (data.data?.result_status === 2) {
          updateSaloneDataId("");
          // window.location.reload();
        } else {
          enqueueSnackbar(t('validation_message.profile_updated'), {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }


      },
      onError: (error) => {
        const errorData = error?.response?.data?.errors;
        if (errorData) {
          Object.values(errorData).forEach((value) => {
            enqueueSnackbar(value, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }
      },
    }
  );

  useEffect(() => {
    if (serviceLocation) {
      setServiceLocationData(serviceLocation)
    }
  }, [serviceLocation])

  useEffect(() => {
    if (serviceLocationData === 2) {
      setOnTheGoCheckd(true);
      setOnSalone(false);
    } else if (serviceLocationData === 1) {
      setOnTheGoCheckd(false);
      setOnSalone(true);
    } else {
      setOnTheGoCheckd(true);
      setOnSalone(true);
    }
  }, [serviceLocationData]);

  useEffect(() => {
    var selectedServiceData = [];
    if (salonDataId != "") {
      setIsDummy(false)
    }
    if (workerProfileData && workerProfileData.worker) {
      let workerData = workerProfileData?.worker
      setGetData(workerData);
      if (workerProfileData?.salon_cities) {
        const filteredCities = workerProfileData.salon_cities.filter(city => city.is_requested === 0);
        setOnTheGoCityCard(filteredCities);
      }
      setHairType(workerData?.hair_type_ids);
      if (workerData?.services_ids) {
        workerData?.services_ids.map((item, index) => {
          selectedServiceData.push(parseInt(item))
        })
      }

      if (workerData.salon_id || salonDataId != "") {
        setIsDummy(false)
      }

      let serviceData = workerProfileData?.worker?.services_id?.map((service) => (
        {
          id: service?.id,
          category: service?.category?.name,
          service_title: service?.title,
          service_for: service?.service_for
        }
      ))
      setSpecialitiesList(serviceData)
      let servicesIds = (workerProfileData?.worker?.services_ids || []).map(Number).filter(Boolean);

      setSpecialitiesIds(servicesIds);

    } else {
      setServiceLocationData(3)
    }
  }, [workerProfileData]);


  const handleAddCardClick = (id) => {
    setOnTheGoCityCard(prevCards => [...prevCards, onTheGoCityCard]);
  };


  const handleSaloneChange = () => {
    const updatedOnSalone = !onSalone
    setOnSalone(updatedOnSalone)
    setServiceLocationData(updatedOnSalone && onTheGoCheckd ? 3 : updatedOnSalone ? 1 : onTheGoCheckd ? 2 : '')
  };

  const handleOnTheGoChange = () => {
    const updatedOnTheGoCheckd = !onTheGoCheckd
    setOnTheGoCheckd(updatedOnTheGoCheckd)
    setServiceLocationData(onSalone && updatedOnTheGoCheckd ? 3 : onSalone ? 1 : updatedOnTheGoCheckd ? 2 : '')
  };

  let dummyValidationSchema = Yup.object().shape({
    about_me: Yup.string()
      .required(t("validation_message.about_me_req"))
      .max(250, t("validation_message.aboute_me_max")),
    service_location: Yup.string().required(t("validation_message.service_location_req")),
  });

  let notDummyValidationSchema = Yup.object().shape({
    about_me: Yup.string()
      .required(t("validation_message.about_me_req"))
      .max(250, t("validation_message.aboute_me_max")),
    booking_interval: Yup.number().
      required(t("validation_message.time_between_bookings_req"))
      .min(1, t("validation_message.min_time_between_bookings")),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      about_me: getData?.provider?.about_me ?? "",
      service_location: serviceLocation ?? 3,
      hair_type: getData?.hair_type_ids ?? [],
      salon_id: salonDataId ?? "",
      booking_interval: workerProfileData?.worker?.booking_interval ?? "",
    },
    validationSchema: isDummy ? dummyValidationSchema : notDummyValidationSchema,
    onSubmit: async (values) => {
      try {
        if (salonDataId && specialitiesIds.length == 0) {
          enqueueSnackbar(t('snackbar_message.select_specialities'), {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
          return false;
        }
        const serviceIds = specialitiesIds;

        values.service_location = serviceLocationData;
        values.hair_type = hairType;
        values.services_id = serviceIds;

        await updateProfile(values);
      } catch (error) {
        const errorData = error?.response?.data?.errors;
        if (errorData) {
          Object.values(errorData).forEach((value) => {
            enqueueSnackbar(value, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        } else {
          enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      }
    },
  });

  const {
    errors,
    handleSubmit,
    touched,
    getFieldProps,
    values
  } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Card className={"mb-3"}>
            <Badge text={t("worker_info.worker_profile_info")} />
            <RowWrap className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 lg:gap-x-8 gap-y-5" lg={3}>
              <TextareaWrap
                {...getFieldProps("about_me")}
                label={<>{t("worker_info.about_me")}</>}
                labelClassName={"text-[15px]"}
                labelIcon={<UserIcon1 className={"w-5 h-5"} />}
                rows={9}
                placeholder={t("input_filed_placeholder.about_me")}
                className="rounded-[8px] border-[1.3px]"
                groupClassName={"md:col-span-2 lg:col-span-1"}
                errorType={errors.about_me}
                errorData={errors.about_me}
              />

              <div className="meetime-form-group">
                <LabelWrap require labelClassName="mb-5 text-[15px] w-full"
                  label={t('form_input_label.service_location')}
                  prefixIcon={<LocationPin2 className="w-5 h-5 flex-shrink-0" />}
                  tooltipText={"Salon: Service your customers at the Salon. And/Or On The Go: Service your customers at their home/location."}
                />
                <div className="flex items-center justify-between flex-wrap w-full">
                  {isDummy || (!isDummy && salonLocation == 1 || salonLocation == 3) ?
                    <InputCheckbox
                      id={t('check_box_label.salon')}
                      prefixIcon={<SalonChairIcon className="w-5 h-5" />}
                      text={t('check_box_label.salon')}
                      name="service_location"
                      className={"text-base"}
                      checked={onSalone}
                      // {salonDataId ?  disabled={disabled} : disabled={!onTheGoCheckd} }
                      // disabled={salonDataId ? disabled : !onTheGoCheckd}
                      disabled={!onTheGoCheckd}
                      onChange={handleSaloneChange}

                    /> : <></>
                  }
                  {isDummy || (!isDummy && salonLocation == 2 || salonLocation == 3) ?
                    <InputCheckbox
                      id={t('check_box_label.on_the_go')}
                      prefixIcon={<LocationPin3 className="w-5 h-5" />}
                      text={t('check_box_label.on_the_go')}
                      name="service_location"
                      className={"text-base"}
                      checked={onTheGoCheckd}
                      onChange={handleOnTheGoChange}
                      // disabled={salonDataId ? disabled : !onSalone}
                      disabled={!onSalone}
                    /> : <></>
                  }
                </div>
              </div>
              <div className="meetime-form-group">
                <LabelWrap require labelClassName="text-[15px] w-full" label={t("worker_info.your_specialities")} prefixIcon={<SpecialitiesStarIcon className="h-5 w-5" />} />
                <div className={cn("form-specialities-car border-success-100 text-blue-950 bg-light-950 min-h-[154px] px-4 rounded-[8px] border-[1.3px] mb-4")}>
                  {
                    specialitiesList?.length > 0
                      ?
                      <SpecialitiesListCard services={specialitiesList} listClassName="w-full !border-0 !p-0" />
                      :
                      <div className='ctg-item gap-2 flex justify-center items-center text-center min-h-[154px]'>
                        <p className='text-center text-16'>{t('input_filed_placeholder.finish_registration')} <br /> {t('input_filed_placeholder.connect_to_salon')}</p>
                      </div>
                  }
                </div>
                <ButtonPrimary
                  className="w-full font-500"
                  onClick={() => setSpecialitiesModalOpen(true)}
                  size="sm"
                  type="button"
                  disabled={salonDataId ? false : true}
                >
                  {/* {(getData.services_id && getData.services_id.length > 0) || specialitiesIds?.length > 0 */}
                  {specialitiesIds?.length > 0
                    ? <>
                      {t("comman_text.edit")}
                      <PencilIcon className="w-5 h-5" />
                    </>
                    : <>
                      {t("button.add1")}
                      <PlusLineIcon className="w-5 h-5" />
                    </>
                  }
                </ButtonPrimary>
                {errors?.services_id && <p className="error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium">service required</p>}
              </div>
            </RowWrap>
            <Divider className={"my-6"} />
            <Badge groupClassName={"border-0 p-0"} text={t("comman_text.extra_info")} />
            <RowWrap className="grid grid-cols-1 lg:grid-cols-3 gap-x-6 lg:gap-x-8 gap-y-5 mb-4" lg={2}>
              <InputWithIcon
                {...getFieldProps("booking_interval")}
                size="sm"
                labelClassName={"text-[15px]"}
                labelIcon={<TimerClockIcon className="w-5 h-5" />}
                label={t("form_input_label.time_bookings")}
                placeholder={"0"}
                tooltipText={t("form_input_label.time_bookings")}
                disabled={salonDataId ? false : true}
                groupClassName="w-full"
                errorType={errors.booking_interval && "error"}
                errorData={errors.booking_interval}
              />

              <div>
                <LabelWrap labelClassName="w-full mb-4 text-[15px]" label={t('form_input_label.hair_type')} prefixIcon={<HairIcon className="w-5 h-5" />} />
                <InputCheckBoxGroup
                  // selectValue={hairTypeSelectItems}
                  itemClassName={"lg:text-[15px] text-[15px] w-[80px]  text-start"}
                  setCheckValue={setHairType}
                  data={hairTypeData} selectValue={hairType}
                  groupClassName={"grid grid-cols-2 xsm:grid-cols-3 gap-y-2 xl:gap-y-4 gap-x-2 flex-wrap w-full"}
                />
              </div>
            </RowWrap>
            {!salonDataId &&
              <>
                <div className="flex justify-end ">
                  <ButtonPrimary type="submit" className={"w-[250px] font-500 py-2"} size="sm">{t("button_label.continue")}</ButtonPrimary>
                </div>
              </>
            }
          </Card>
          {
            salonDataId &&
            (
              <>
                {
                  workerProfileData?.offer_request !== 0 && (
                    <>
                      <ActiveInactiveCompo id="offer_request" salonDataId={salonDataId} offerRequestStatus={workerProfileData?.worker?.offer_request_status} groupClassName="mb-3" headerClassName="mb-6" header={<Badge groupClassName={"mb-0"} text={t("form_input_label.offer_requests")} tooltipText="area" />} >
                        <InputWithIcon
                          {...getFieldProps("offer_request_address")}
                          groupClassName="mb-4"
                          labelClassName={"text-[15px]"}
                          value={workerProfileData?.offer_request_address}
                          require
                          size="sm"
                          labelIcon={<LocationPin2 className="w-5 h-5" />}
                          label={t("form_input_label.area")}
                          disabled={true}
                          placeholder={t('Show City name or Address')}
                          suffixWrap={<span>{t("comman_text.km")}</span>}
                          tooltipText={t("form_input_label.area")}
                          lableEndIcon={<InfoFIllIcon className="w-5 h-5" />}
                        />
                        <RowWrap className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-6 lg:gap-8" md={3}>
                          <InputWithIcon
                            require
                            size="sm"
                            labelClassName={"text-[15px]"}
                            labelIcon={<CityLocationIcon className="w-5 h-5" />}
                            label={t("form_input_label.city_address_radius")}
                            disabled={true}
                            {...getFieldProps("offer_request_radius")}
                            placeholder={"5"}
                            suffixWrap={<span>{t('comman_text.km')}</span>}
                            tooltipText={t("form_input_label.city_address_radius")}
                            lableEndIcon={<InfoFIllIcon className="w-5 h-5" />}
                            value={workerProfileData?.offer_request_radius}
                          />
                          <InputWithIcon
                            require
                            size="sm"
                            labelIcon={<DollarRoundIcon className="w-5 h-5" />}
                            label={t("form_input_label.offer_request_budget")}
                            disabled={true}
                            placeholder={t('input_filed_placeholder.minimum_amount')}
                            suffixWrap={<span>{t('comman_text.dkk')}</span>}
                            {...getFieldProps("offer_request_cost")}
                            value={workerProfileData?.offer_request_cost}

                          />
                          <InputWithIcon
                            require
                            size="sm"
                            labelClassName={"text-[15px]"}
                            labelIcon={<TimerClockIcon className="w-5 h-5" />}
                            label={t("form_input_label.transport_time")}
                            {...getFieldProps("offer_transport_time")}
                            placeholder={"45"}
                            disabled={true}
                            suffixWrap={<span>{t("comman_text.min")}</span>}
                            tooltipText={t("form_input_label.transport_time")}
                            lableEndIcon={<InfoFIllIcon className="w-5 h-5" />}
                            value={workerProfileData?.offer_transport_time}
                          />

                        </RowWrap>
                      </ActiveInactiveCompo>
                    </>
                  )}
                {onTheGoCheckd && (
                  <>
                    <Card className="py-2.5 px-3 sm:py-2.5 sm:px-5 mb-3">
                      <div className="flex items-center justify-between">
                        <h6 className='text-base text-blue-950 font-semibold'>{t("comman_text.on_the_go")}</h6>
                        <ButtonPrimary variant={"outline"} size={"sm"} type={"button"}
                          onClick={() => addNewCityButtonForWorker ? setRequestForNewOnTheGoCityModalOpen(true) : handleAddCardClick(onTheGoCityCard.id++)}
                          icon={<PlusLineIcon className="w-5 h-5" />}
                          className={"lg:text-[15px] text-[15px] font-bold px-1.5 py-0.5 rounded-full !leading-[22px] "} >{t('comman_text.new_city')}</ButtonPrimary>
                      </div>
                    </Card>
                    {
                      onTheGoCityCard?.map((data, index) => (
                        <ActiveInactiveCompo id="on_the_go" groupClassName="mb-3" headerClassName="mb-6" header={<Badge groupClassName={"mb-0"} text={`City ${index + 1}`} />}
                          cityData={data} workerCityData={workerProfileData?.worker?.cities} name="city">
                          <InputWithIcon
                            groupClassName="mb-4"
                            labelClassName={"text-[15px]"}
                            require
                            size="sm"
                            {...getFieldProps(`salon_cities[${index}]`)}
                            disabled={true}
                            labelIcon={<LocationPin2 className="w-5 h-5" />}
                            label={t("form_input_label.area")}
                            placeholder={t('Show City name or Address')}
                            suffixWrap={<span>{t('comman_text.km')}</span>}
                            value={data.work_from_address}
                            tooltipText="Lorem ipsum"
                          />
                          <RowWrap className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-6 lg:gap-8" md={3}>
                            <InputWithIcon
                              require
                              size="sm"
                              disabled={true}
                              value={workerProfileData?.work_radius ?? ''}
                              labelClassName={"text-[15px]"}
                              labelIcon={<CityLocationIcon className="w-5 h-5" />}
                              label={t("form_input_label.city_address_radius")}
                              placeholder={"5"}
                              suffixWrap={<span>{t('comman_text.km')}</span>}
                              tooltipText="Lorem ipsum"
                            />
                            <InputWithIcon
                              require
                              size="sm"
                              value={workerProfileData?.transport_time ?? ''}
                              disabled={true}
                              labelClassName={"text-[15px]"}
                              labelIcon={<TimerClockIcon className="w-5 h-5" />}
                              label={t("form_input_label.transport_time")}
                              placeholder={"45"}
                              suffixWrap={<span>{t("comman_text.min")}</span>}
                              tooltipText="Lorem ipsum"
                            />
                            <InputWithIcon
                              require
                              size="sm"
                              labelClassName={"text-[15px]"}
                              labelIcon={<CarIcon className="w-5 h-5" />}
                              label={t('form_input_label.transport_fee')}
                              value={workerProfileData?.transport_fee ?? ''}
                              disabled={true}
                              placeholder={t('comman_text.amount')}
                              suffixWrap={
                                <div className="flex items-center gap-1.5">
                                  <span>{t("comman_text.dkk")}</span> <span className="border-r border-success-100 h-6"></span>
                                  <InputCheckbox
                                    id={"Included1"}
                                    disabled={true}
                                    checked={workerProfileData?.transport_fee_included}
                                    text={t("check_box_label.included")}
                                  />
                                </div>
                              }
                              tooltipText="Lorem ipsum"
                            />
                          </RowWrap>
                        </ActiveInactiveCompo>
                      ))
                    }
                  </>
                )}
                <Card className={"sm:py-3.5 sm:px-5"}>
                  <ButtonPrimary size={"sm"} className={"w-full"} type='submit'>{t('button_label.continue')}</ButtonPrimary>
                </Card>
              </>
            )
          }
        </Form>
      </FormikProvider>
      <RequestForNewOnTheGoCityModal modalOpen={requestForNewOnTheGoCityModalOpen} workerProfileData={workerProfileData} salonDataId={salonDataId} setModalOpen={setRequestForNewOnTheGoCityModalOpen} />

      <ChooseSpecialitiesModal modalOpen={specialitiesModalOpen} setModalOpen={setSpecialitiesModalOpen} setSelectedServices={setSpecialitiesList} selectedServices={specialitiesList} selectedIds={specialitiesIds} setSelectedIds={setSpecialitiesIds} />
    </>
  )
}

export default withTranslation()(WorkerProfile)