import React, { useState } from 'react'
import { cn } from '../../../../lib/utils'
import { withTranslation } from 'react-i18next'
import LabelWrap from '../inputs/LabelWrap'
import CloseFillIcon from '../icons/CloseFillIcon'
import CommonSliderWrapper from './CommonSliderWrapper'
import InputRadioBox from '../inputs/InputRadioBox'
import { checkVariance } from '../inputs/InputCheckbox'

const ChoosenServicesList = ({ t, wrapperClassName, servicesData, showWorkerDetail, groupClassName, chooseServicesId, handleChooseService, label, showPrice = true, cancleIconShow = true, ...props }) => {
    // console.log('servicesData', servicesData);
    const language = localStorage.getItem('i18nextLng') || 'dk';

    const [selectedServicesList, setSelectedServicesList] = useState([
        {
            id: 1,
            category: "haircut",
            gender: "Woman",
            duration: "40min",
            price: "430 Dkk."
        },
        {
            id: 2,
            category: "haircut",
            gender: "Woman",
            duration: "40min",
            price: "430 Dkk."
        },
        {
            id: 3,
            category: "haircut",
            gender: "Woman",
            duration: "40min",
            price: "430 Dkk."
        },
        {
            id: 4,
            category: "haircut",
            gender: "Woman",
            duration: "40min",
            price: "430 Dkk."
        },
        // {
        //     id: 5,
        //     category: "haircut",
        //     gender: "Woman",
        //     duration: "40min",
        //     price: "430 Dkk."
        // },
        // {
        //     id: 6,
        //     category: "haircut",
        //     gender: "Woman",
        //     duration: "40min",
        //     price: "430 Dkk."
        // },
        // {
        //     id: 7,
        //     category: "haircut",
        //     gender: "Woman",
        //     duration: "40min",
        //     price: "430 Dkk."
        // },
        // {
        //     id: 8,
        //     category: "haircut",
        //     gender: "Woman",
        //     duration: "40min",
        //     price: "430 Dkk."
        // }
    ])

    const handeRemoveService = (id) => {
        setSelectedServicesList(selectedServicesList.filter(item => item.id !== id))
    }

    const sliderRef = React.useRef(null)

    const [serviceSelecet, setServiceSelecet] = useState(null);
    return (
        <div className={cn("w-full", wrapperClassName)}>
            <LabelWrap labelClassName={"font-medium mb-1"} label={label} />
            {/* <div className={cn("w-full grid grid-cols-1 xsm:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3", groupClassName)}>
                {
                    selectedServicesList.map((service, index) => (
                        <ul className='border-[1.5px] text-[15px] flex flex-col gap-1 border-success-100 rounded-[15px] bg-light-950 relative w-full text-blue-950 p-3'>
                            <CloseFillIcon className="h-6 w-6 absolute end-1 top-1 cursor-pointer" onClick={() => handeRemoveService(service.id)} />
                            <li>
                                HairCut {service.id}
                            </li>
                            <li>
                                Woman
                            </li>
                            <li>
                                70 min
                            </li>
                            <li>
                                430 Dkk.
                            </li>
                        </ul>
                    ))
                }
            </div> */}

            <CommonSliderWrapper
                centerMode={false}
                ref={sliderRef}
                groupClassName="max-w-full flex sm:gap-2 items-start w-full"
                slidesToShow={5}
                xsSlidesToShow={1}
                smSlidesToShow={2}
                mdSlidesToShow={3}
                lgSlidesToShow={4}
                infinite={false}
                prevArrowClassName={"absolute top-1/2 z-10 -translate-y-1/2 -start-2 rounded-full bg-primary1 text-white"}
                nextArrowClassName={"absolute top-1/2 -translate-y-1/2 -end-2 rounded-full bg-primary1 text-white"}
                arrowBtnGroupClassName={"mt-2 gap-2"}
                SliderClassName={"slider_flex gap_14 h-full [&_.slick-track]:mx-0"}
            >
                {
                    servicesData && servicesData?.length > 0 && servicesData?.map((service, index) => (
                        <>
                            <InputRadioBox
                                key={index}
                                size="xxl"
                                disabled={chooseServicesId?.id == service?.service_id}
                                name="workerList"
                                inputClassName={"hidden"}
                                groupClassName={"block h-full"}
                                className={cn(checkVariance({ size: "xxl", className: "border-[1.5px] border-success-100 rounded-[15px] relative h-full px-3.5 py-2 peer-disabled:opacity-50 peer-disabled:before:hidden peer-disabled:after:hidden peer-disabled:cursor-not-allowed peer-checked:after:rounded-sm flex items-center flex-row-reverse peer-checked:after:w-2.5 peer-checked:after:h-4 md:peer-checked:after:w-[10px] md:peer-checked:after:h-[18px] peer-checked:after:start-[unset] md:peer-checked:after:start-[unset] peer-checked:after:end-6 peer-checked:after:-mt-0.5 peer-checked:after:top-1/2 peer-checked:after:-translate-y-1/2 before:top-1/2" }))}
                                text={
                                    <>
                                        {cancleIconShow && <CloseFillIcon className="h-6 w-6 absolute end-1.5 top-1.5 cursor-pointer" onClick={() => handeRemoveService(service.id)} />}
                                        <ul onClick={() => handleChooseService(index, service)} className={cn('text-[15px] flex flex-col gap-1 flex-grow text-blue-950')}>
                                            <li>
                                                {service?.service ? service?.service : language == 'en' ? service?.title : service?.dk_title}
                                            </li>
                                            <li>
                                                {service?.for === 'Men' && <li className="flex-shrink-0 leading-none"><p>Male</p></li>}
                                                {service?.for === 'Woman' && <li className="flex-shrink-0 leading-none"><p>Female</p></li>}
                                                {service?.for === 'Boy' && <li className="flex-shrink-0 leading-none"><p>Boy</p></li>}
                                                {service?.for === 'Girl' && <li className="flex-shrink-0 leading-none"><p>Girl</p></li>}
                                            </li>
                                            <li>
                                                {service?.duration} min.
                                            </li>
                                            {showPrice &&
                                                <li>
                                                    {service?.price} Dkk.
                                                </li>}
                                        </ul>
                                    </>
                                }
                            // checked={selectedWorkerList.includes(`worker_id_____${index + 1}`) ? true : false}
                            // onChange={(item) => handleWorkerSelection(item.target.value)}
                            />
                            {/* <ul className='border-[1.5px] text-[15px] flex flex-col gap-1 border-success-100 rounded-[15px] bg-light-950 relative w-full text-blue-950 p-3'
                                onClick={() => handleChooseService(index, service)}> */}
                            {/* {cancleIconShow && <CloseFillIcon className="h-6 w-6 absolute end-1 top-1 cursor-pointer" onClick={() => handeRemoveService(service.id)} />} */}

                            {/* </ul> */}
                        </>

                    ))
                }
            </CommonSliderWrapper>
        </div >
    )
}

export default withTranslation()(ChoosenServicesList)