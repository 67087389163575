import React, { useState, Fragment, useRef, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { withTranslation } from 'react-i18next';
import LabelWrap from '../../uiComponents/inputs/LabelWrap';
import CheckboxList from '../../uiComponents/common/CheckboxList';
import ServiceTableWithSelect from '../../uiComponents/common/ServiceTableWithSelect';
import ButtonPrimary from '../../uiComponents/buttons/ButtonPrimary';
import ChoosenServicesList from '../../uiComponents/common/ChoosenServicesList';
import InputCheckbox from '../../uiComponents/inputs/InputCheckbox';
import CommonSliderWrapper from '../../uiComponents/common/CommonSliderWrapper';
import InputRadioBoxGroup from '../../uiComponents/inputs/InputRadioBoxGroup';
import AvailabilityCalendar from '../../uiComponents/common/AvailabilityCalendar';
import OfferRequestCalender from '../../uiComponents/common/OfferRequestCalender';
import TimeSlider from '../../uiComponents/common/TimeSlider';
import RowWrap from '../../uiComponents/RowWrap';
import WorkerCard from '../../pages/customer_panel/components/WorkerCard';
import dayjs from "dayjs"
import { useMutation } from 'react-query';
import Http from '../../../../Http';
import { CUSTOMER_AUTH_API_URL } from '../../../api/axios';
import { enqueueSnackbar } from 'notistack';
import moment from 'moment/moment';

const OfferRequestSecoundStep = ({ t, activeStep, setBookingInfo, bookingInfo, setActiveStep, handleModalClose, salonId }) => {
    const sliderRef = useRef(null);
    const [selectedWorkerList, setSelectedWorkerList] = useState([])
    const [workerPrefrence, setWorkerPrefrence] = useState()
    const [workerInfo, setWorkerInfo] = useState("");
    const [newSelectDate, setNewSelectDate] = useState(dayjs().add(1, "days"));
    const [selectedTime, setSelectedTime] = useState("")
    const [selectDate, setSelectDate] = useState(moment().add(1, "days").format('YYYY-MM-DD'));

    const handleWorkerPre = (e) => {
        setWorkerPrefrence(e.target.value)
        setWorkerInfo("")
        if (e.target.value == 2) {
            workerData()
        }
    }
    useEffect(() => {
        setBookingInfo({
            ...bookingInfo,
            request_offer: {
                ...bookingInfo.request_offer,
                worker_id: workerInfo ? [workerInfo.provider.id] : []
            }
        });
    }, [workerInfo])

    const handleWorkerSelection = (option) => {
        if (selectedWorkerList?.includes(option)) {
            setSelectedWorkerList(selectedWorkerList?.filter(item => item !== option))
        } else {
            setSelectedWorkerList([...selectedWorkerList, option]);
        }
    };

    useEffect(() => {
        setSelectedWorkerList([])
    }, [workerPrefrence])

    const { mutateAsync: workerData } = useMutation(
        async () => {
            try {
                const { data } = await Http.post(CUSTOMER_AUTH_API_URL.getWorker, {
                    salon_id: salonId
                }).then((res) => res.data);
                if (data.length) {
                    setWorkerInfo(data[0])
                    setSelectedWorkerList(data);
                }

            } catch (error) {
                handleModalClose(false)
                enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            }
        }
    );

    const { mutateAsync: sendOffer } = useMutation(
        async () => {
            try {
                const { data } = await Http.post(CUSTOMER_AUTH_API_URL.offerSend, bookingInfo).then((res) => res.data);
                if (data) {
                    setBookingInfo("");
                    enqueueSnackbar(data.message, {
                        variant: "success",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            } catch (error) {
                const errorData = error?.response?.data?.errors;
                if (errorData) {
                    Object.values(errorData).forEach((value) => {
                        enqueueSnackbar(value, {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                } else {
                    enqueueSnackbar(error?.response?.data?.message, {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            }
        }
    );

    const handleAcceptOffer = () => {
        if (selectedTime == "") {
            enqueueSnackbar("The time field is required", {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
            return false
        }
        sendOffer()
        handleModalClose();
    }

    useEffect(() => {
        if (selectDate) {
            const todayDate = selectDate + " " + selectedTime;
            let times = dayjs(todayDate, "YYYY-MM-DD HH:mm").utc().format('YYYY-MM-DD HH:mm')
            setBookingInfo({
                ...bookingInfo,
                request_offer: {
                    ...bookingInfo.request_offer,
                    worker_id: workerInfo ? [workerInfo.provider.id] : []
                },
                'end_time': times,
            });
        }
    }, [selectDate, selectedTime])

    const onSelect = (date, source) => {
        if (source !== "date") return
        const selectDate = dayjs(date).format("YYYY-MM-DD");
        setSelectDate(selectDate)
        setNewSelectDate(date)
    }
    const disabledDate = (date) => {
        const minAllowedDate = dayjs().add(1, 'day').startOf('d'); // Today + 1 day
        return date.isBefore(minAllowedDate, 'day');
    };

    return (

        <div className={`modal-body ${activeStep === 2 ? "block" : 'hidden'}`}>
            <ChoosenServicesList label={"Selected service(s)"} />

            <div className="meetime-form-group w-full my-3">
                <LabelWrap labelClassName=" w-full text-[15px] font-medium mb-1" label={t('comman_text.offer_request_step3')} />

                <RowWrap className="grid grid-cols-1  sm:grid-cols-7 gap-4 lg:gap-8 border-[1.3px] rounded-[15px] py-5" sm={2}>
                    <div className='col-span-1 sm:col-span-5'>
                        <OfferRequestCalender newSelectDate={newSelectDate} setNewSelectDate={setNewSelectDate} />
                    </div>
                    <div className='col-span-1 sm:col-span-2'>
                        <TimeSlider selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
                    </div>
                </RowWrap>
            </div>

            <div className="meetime-form-group w-full ">
                <LabelWrap labelClassName=" w-full text-[15px] font-medium mb-0" label={t("form_input_label.worker_preference")} />
                <InputRadioBoxGroup
                    selectValue={workerPrefrence}
                    itemMainClassName={"w-full"}
                    itemClassName={"lg:text-[15px] text-[15px] justify-between  text-start w-full"}
                    setCheckValue={(value) => setWorkerPrefrence(value)}
                    data={[
                        { label: t("radio_label.salon_choose_best_suited") },
                        { label: t("radio_label.would_like_choose") },
                    ]}
                    groupClassName={"gap-x-10 gap-y-2 w-full flex flex-col md:flex-row  flex-wrap justfy-start  py-1.5"}
                    onChange={handleWorkerPre}
                />
            </div>

            {
                workerPrefrence === t("radio_label.would_like_choose") &&
                <div className='mt-3'>
                    <div className='w-full mb-6 xl:mb-7'>
                        <LabelWrap labelClassName={"text-[15px] mb-1 font-medium"} label={t('form_input_label.choose_worker')} />

                        <div className='w-full '>
                            <CommonSliderWrapper
                                ref={sliderRef}
                                defaultArrows={false}
                                sildNoShow={true}
                                nextArrowClassName="top-9 -end-20 border-[1.6px] !bg-primary1 !border-primary1 !text-white"
                                prevArrowClassName="!border-primary1 top-9 !start-auto !-end-10 border-[1.6px] !bg-primary1 !text-white"
                                groupClassName="sm:max-w-[calc(100%-110px)] sm:flex sm:gap-2 sm:items-center"
                                slidesToShow={5}
                                xsSlidesToShow={1}
                                smSlidesToShow={2}
                                mdSlidesToShow={3}
                                lgSlidesToShow={4}
                                arrowBtnGroupClassName={"mt-2 sm:mt-0 gap-2"}
                                countClassName="min-w-[28px] text-center"
                            >
                                {
                                    [...Array(8)].map((item, index) => (
                                        <InputCheckbox
                                            key={index}
                                            name="workerList"
                                            id={`worker_id_____${index + 1}`}
                                            groupClassName={"flex-grow w-full w-full h-full p-1"}
                                            className={`bg-light-800 rounded-lg xl:rounded-2xl items-center gap-1 before:hidden after:hidden peer-checked:after:hidden py-1 md:py-1.5 px-2 md:px-2.5 border-[1.3px] border-success-100 w-full font-medium peer-checked:border-success-950 peer-checked:bg-success-350 hover:border-success-950 hover:bg-success-350 transition-all duration-300 h-full leading-none`}
                                            text={
                                                <>
                                                    <div className='w-10 h-10 md:h-[55px] md:w-[55px] overflow-hidden rounded-full object-cover object-center bg-white flex-shrink-0'>
                                                        <img src="../../../../../../assets/images/user4.jpg" className='h-full w-full object-cover' alt="" />
                                                    </div>
                                                    <span className="text-base xl:text-lg flex-grow text-center block break-words">Michael J.</span>
                                                </>
                                            }
                                            value={`worker_id_____${index + 1}`}
                                            checked={selectedWorkerList.includes(`worker_id_____${index + 1}`) ? true : false}
                                            onChange={(item) => handleWorkerSelection(item.target.value)}
                                        />
                                    ))
                                }
                            </CommonSliderWrapper>

                        </div>
                    </div>

                    <WorkerCard groupClassName={"bg-light-800"} badgeSlider />
                </div>
            }
            <div className='w-full flex flex-col sm:flex-row justify-between gap-x-5 gap-y-3 mt-3'>
                {/* <ButtonPrimary size="sm" onClick={() => handleAcceptOffer()} className="w-full" onClick={handleModalClose} >Send Request</ButtonPrimary> */}
                <ButtonPrimary size="sm" onClick={() => handleAcceptOffer()} className="w-full"  >Send Request</ButtonPrimary>
            </div>

        </div>
    )
}

export default withTranslation()(OfferRequestSecoundStep)
