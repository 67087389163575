import React, { forwardRef } from 'react'
import { cn } from '../../../../lib/utils'
import { cva } from 'class-variance-authority'
import Tooltip from './../Tooltip'

const inputIconVariance = cva(
  "input-wrap peer rounded text-[15px] leading-[24px] font-medium w-full outline-0 border-[1.3px] border-success-100 bg-light-950 text-blue-950 placeholder:text-dark-950/30 placeholder:text-sm required:border-danger-950 invalid:border-danger-950",
  {
    variants: {
      size: {
        default: '!px-4 h-[45px]',
        sm: 'px-3.5 text-sm leading-[22px] h-[40px]',
      }
    },
    defaultVariants: {
      size: "default",
    }
  },
)

const inputSizeVariance = (size) => {
  const sizeVariants = {
    default: 'py-2.5',
    sm: 'py-1.5 leading-[26px] ',
  };
  return sizeVariants[size] || sizeVariants.default;
};


const InputWithIcon = forwardRef(({ errorData, tooltipText, lableEndIcon, errorType, variant, size, type, ref, className, label, labelIcon, labelClassName, groupClassName, inputClassName, errorClassName, require, placeholder, suffixWrap, suffixIcon, prefixWrap, prefixIcon, tooltipIcon, tooltipIconClass, tooltipContentClass, tooltipButtonClass, iconClassName, buttonType,handleSuffixIconClick,labelSpanClassName, ...props }) => {
  return (
    <div className={cn("meetime-form-group", groupClassName)}>
      {label &&
        <label className={cn(labelIcon || tooltipText ? "flex items-center justify-between gap-2" : "block", "mb-1.5 text-blue-950", labelClassName)}>
          <span className={cn('flex items-center justify-start gap-2', labelSpanClassName)}>
            {labelIcon ? labelIcon : ''} <span className={cn(require ? "before:content-['*'] before:text-danger-950 before:text-2xl before:leading-[1] before:absolute before:top-0.5 before:end-0 inline-block pe-3 relative" : "")}>{label}</span>
          </span>

          {tooltipText ? <Tooltip title={tooltipText} tooltipIconClass={tooltipIconClass} tooltipIcon={tooltipIcon} tooltipContentClass={tooltipContentClass} tooltipButtonClass={tooltipButtonClass} /> : ""}
        </label>}
      <div className={cn(inputIconVariance({ variant, size, className }), "flex items-center !gap-2")}>
        {prefixWrap ? prefixWrap : prefixIcon ? <button aria-label="button" type={buttonType ?? "button"} className={cn("left_icon leading-none ", iconClassName)}>{prefixIcon}</button> : <></>}
        <input {...props} ref={ref} type={type ?? "text"} className={cn(inputSizeVariance(size), "w-full bg-transparent placeholder:text-dark-950/30 placeholder:text-sm outline-0", inputClassName)} placeholder={placeholder && placeholder} />
        {suffixWrap ? suffixWrap : suffixIcon ? <button onClick={handleSuffixIconClick} aria-label="button" role="button" type={buttonType ?? "button"} className={cn("right_icon leading-none", iconClassName)}>{suffixIcon}</button> : <></>}
      </div>
      {errorType && <p className={cn("error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium", errorClassName)}>{errorData}</p>}
    </div>
  )
})

export { inputIconVariance }
export default InputWithIcon
