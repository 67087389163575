import React, { useEffect, useRef, useState } from 'react'
import RightSmallArrowIcon from './icons/RightSmallArrowIcon';
import LeftSmallArrowIcon from './icons/LeftSmallArrowIcon';
import CloseIcon from './icons/CloseIcon';
import Slider from "react-slick"
import { cn } from '../../../lib/utils';


export function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <button onClick={onClick} className='absolute top-1/2 -translate-y-1/2 end-1 bg-white rounded-full h-8 w-8 flex items-center justify-center border border-success-100'><RightSmallArrowIcon className="w-4 h-4 text-blue-950" /></button>
    );
}

export function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button onClick={onClick} className='absolute top-1/2 z-10 -translate-y-1/2 start-1 bg-white rounded-full h-8 w-8 flex items-center justify-center border border-success-100'><LeftSmallArrowIcon className="w-4 h-4 text-blue-950" /></button>
    );
}

const CustomSliderWithThumbnail = ({ thumbSlider, images, handleRemoveImage, groupClassName, ...props }) => {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);

    useEffect(() => {
        setNav1(sliderRef1);
        setNav2(sliderRef2);
    }, []);

    const shopSliderMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        dots: false,
    };
    const shopSliderThumb = {
        infinite: false,
        speed: 500,
        className: '',
        arrows: false,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    infinite: false,
                    dots: false,
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    infinite: false,
                    dots: false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className={cn(groupClassName)}>
            <div className="slider-container">
                <Slider {...shopSliderMain} asNavFor={nav2} ref={slider => (sliderRef1 = slider)}>
                    {images.map((image, index) => {
                        return (
                            <div key={index} className='max-h-[185px] rounded-lg h-full w-full overflow-hidden relative border border-success-100'>
                                <div className='bg-primary1 absolute top-2 end-2 p-1 w-7 h-7 rounded-md'><CloseIcon className="w-5 h-5 text-white" onClick={() => { handleRemoveImage(index) }} /></div>
                                <img src={image.file} className='h-full w-full object-cover' alt="" />
                            </div>
                        )
                    })}
                </Slider>
            </div>
            <div className='slider-container'>
                {thumbSlider ?
                    <Slider {...shopSliderThumb} asNavFor={nav1} ref={slider => (sliderRef2 = slider)} className='slider_flex gap_14'>
                        {images.map((image, index) => {
                            return (
                                <div key={index} className='w-full border border-success-100 h-[60px] rounded-lg overflow-hidden'>
                                    <img src={image.file} className='h-full w-full object-cover object-center' alt="" />
                                </div>
                            )
                        })}
                    </Slider>
                    : ""}
            </div>
        </div>
    )
}

export default CustomSliderWithThumbnail