import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import { cn } from '../../../../lib/utils';
import LeftSmallArrowIcon from '../icons/LeftSmallArrowIcon';
import RightSmallArrowIcon from '../icons/RightSmallArrowIcon';




const CommonSliderWrapper = forwardRef(({ hideBtnOnFirstLastSlide = false, centerMode = false, defaultArrows = true, autoPlaySlide, countClassName, arrowButton, arrowIconClass, sildNoShow, arrowBtnGroupClassName, slidesToShow, xxsSlidesToShow, xsSlidesToShow, smSlidesToShow, mdSlidesToShow, lgSlidesToShow, xlSlidesToShow, xxlSlidesToShow, xxxlSlidesToShow, SliderClassName, children, className, groupClassName, nextArrowClassName, prevArrowClassName, infinite = true, ...props }, ref) => {

    const [activeSlide, setActiveSlide] = useState(0);
    const totalSlides = React.Children.count(children);
    const [totalSlideCount, setTotalSlideCount] = useState(5)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions)
        }
    }, [windowWidth])
    useEffect(() => {
        const totalSlides = React.Children.count(children); // Get the latest count of children slides
        windowWidth > 1399 ?
            setTotalSlideCount(totalSlides - (xxxlSlidesToShow ?? xxlSlidesToShow ?? xlSlidesToShow ?? lgSlidesToShow ?? mdSlidesToShow ?? smSlidesToShow ?? xsSlidesToShow ?? slidesToShow) + 1)
            : windowWidth > 1199 ?
                setTotalSlideCount(totalSlides - (xxlSlidesToShow ?? xlSlidesToShow ?? lgSlidesToShow ?? mdSlidesToShow ?? smSlidesToShow ?? xsSlidesToShow ?? slidesToShow) + 1)
                : windowWidth > 991 ?
                    setTotalSlideCount(totalSlides - (xlSlidesToShow ?? lgSlidesToShow ?? mdSlidesToShow ?? smSlidesToShow ?? xsSlidesToShow ?? slidesToShow) + 1)
                    : windowWidth > 767 ?
                        setTotalSlideCount(totalSlides - (lgSlidesToShow ?? mdSlidesToShow ?? smSlidesToShow ?? xsSlidesToShow ?? slidesToShow) + 1)
                        : windowWidth > 576 ?
                            setTotalSlideCount(totalSlides - (mdSlidesToShow ?? smSlidesToShow ?? xsSlidesToShow ?? slidesToShow) + 1)
                            : windowWidth > 479 ?
                                setTotalSlideCount(totalSlides - (smSlidesToShow ?? xsSlidesToShow ?? slidesToShow) + 1)
                                : windowWidth > 300 ?
                                    setTotalSlideCount(totalSlides - (xsSlidesToShow ?? slidesToShow) + 1)
                                    : setTotalSlideCount(totalSlides - (slidesToShow) + 1)
    }, [windowWidth, children, xxxlSlidesToShow, xxlSlidesToShow, xlSlidesToShow, lgSlidesToShow, mdSlidesToShow, smSlidesToShow, xsSlidesToShow, slidesToShow]);

    function SampleNextArrow(props) {
        const { onClick } = props;
        // Disable next arrow if the active slide is the last slide
        const isAtLastSlide = activeSlide >= totalSlides - slidesToShow;
    
        return (
            <div
                onClick={isAtLastSlide ? null : onClick} // Prevent click if at the last slide
                className={cn(
                    "cursor-pointer absolute top-1/2 -translate-y-1/2 end-1 text-blue-950 bg-white rounded-full h-8 w-8 flex items-center justify-center border border-success-100",
                    { 'cursor-not-allowed opacity-50': isAtLastSlide }, // Disable styles
                    nextArrowClassName
                )}
            >
                <RightSmallArrowIcon className={cn("w-4 h-4", props.arrowIconClass)} />
            </div>
        );
    }
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        // Hide prev arrow if the active slide is the first slide
        if (!infinite && activeSlide === 0) {
            return null;
        }
        return (
            <div onClick={onClick} className={cn("cursor-pointer absolute top-1/2 z-10 -translate-y-1/2 text-blue-950 start-1 bg-white rounded-full h-8 w-8 flex items-center justify-center border border-success-100", prevArrowClassName)}>
                <LeftSmallArrowIcon className={cn("w-4 h-4", props.arrowIconClass)} />
            </div>
        );
    }


    const handleAfterChange = (currentSlide) => {
        setActiveSlide(Math.max(0, currentSlide));
    };


    const sliderRef = useRef(null)

    const nextSlide = () => {
        if (activeSlide < totalSlides - slidesToShow) {
            sliderRef.current.slickNext();
        }
    };

    const prevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };


    const shopSliderMain = {
        centerMode: centerMode,
        slidesToShow: xxxlSlidesToShow ?? xxlSlidesToShow ?? xlSlidesToShow ?? lgSlidesToShow ?? mdSlidesToShow ?? smSlidesToShow ?? xsSlidesToShow ?? slidesToShow,
        slidesToScroll: 1,
        autoplay: autoPlaySlide ? autoPlaySlide : false,
        arrows: defaultArrows ?? true,
        nextArrow: defaultArrows ? <SampleNextArrow arrowIconClass={arrowIconClass} /> : null,
        prevArrow: defaultArrows ? <SamplePrevArrow arrowIconClass={arrowIconClass} /> : null,
        dots: false,
        infinite: infinite,
        afterChange: handleAfterChange,
        responsive: [
            {
                breakpoint: 300, // For small screens (xs)
                settings: {
                    slidesToShow: xxsSlidesToShow ?? xsSlidesToShow ?? slidesToShow,
                },
            },
            {
                breakpoint: 479, // For small screens (xs)
                settings: {
                    slidesToShow: xsSlidesToShow ?? slidesToShow,
                },
            },
            {
                breakpoint: 575, // For small screens (sm)
                settings: {
                    slidesToShow: smSlidesToShow ?? xsSlidesToShow ?? slidesToShow,
                },
            },
            {
                breakpoint: 767, // For medium screens (md)
                settings: {
                    slidesToShow: mdSlidesToShow ?? smSlidesToShow ?? xsSlidesToShow ?? slidesToShow,
                },
            },
            {
                breakpoint: 991, // For large screens (lg)
                settings: {
                    slidesToShow: lgSlidesToShow ?? mdSlidesToShow ?? smSlidesToShow ?? xsSlidesToShow ?? slidesToShow,
                },
            },
            {
                breakpoint: 1199, // For extra-large screens (xl)
                settings: {
                    slidesToShow: xlSlidesToShow ?? lgSlidesToShow ?? mdSlidesToShow ?? smSlidesToShow ?? xsSlidesToShow ?? slidesToShow,
                },
            },
            {
                breakpoint: 1399, // For extra-extra-large screens (xxl)
                settings: {
                    slidesToShow: xxlSlidesToShow ?? xlSlidesToShow ?? lgSlidesToShow ?? mdSlidesToShow ?? smSlidesToShow ?? xsSlidesToShow ?? slidesToShow,
                },
            },
            {
                breakpoint: 1920, // For extra-extra-extra-large screens (xxxl)
                settings: {
                    slidesToShow: xxxlSlidesToShow ?? xxlSlidesToShow ?? xlSlidesToShow ?? lgSlidesToShow ?? mdSlidesToShow ?? smSlidesToShow ?? xsSlidesToShow ?? slidesToShow,
                },
            },
        ],
    };

    return (
        <div className={cn("slider-container", groupClassName)} >
            <Slider {...shopSliderMain} infinite={false} className={cn(`slider_flex gap_8 flex-grow w-full`, SliderClassName)} ref={defaultArrows ? ref : sliderRef}>
                {children}
            </Slider>
            {
                !defaultArrows && <div className={cn("flex justify-center items-center gap-3 flex-shrink-0 mt-3", arrowBtnGroupClassName)}>
                    <button className={cn("border-[1.6px] bg-primary1 border-primary1 text-white rounded-full h-8 w-8 flex items-center justify-center", arrowButton)} onClick={prevSlide}>
                        <LeftSmallArrowIcon className={cn("w-4 h-4", arrowIconClass)} />
                    </button>
                    {/* {sildNoShow && <span className={cn(countClassName)}> {activeSlide + 1}/{children?.length} </span>} */}
                    {sildNoShow && <span className={cn(countClassName)}>{Math.max(1, activeSlide + 1)} / {Math.max(1, totalSlideCount)}</span>}
                    <button className={cn("border-[1.6px] bg-primary1 border-primary1 text-white rounded-full h-8 w-8 flex items-center justify-center", arrowButton)} onClick={nextSlide}>
                        <RightSmallArrowIcon className={cn("w-4 h-4", arrowIconClass)} />
                    </button>
                </div>
            }
            {
                (defaultArrows && sildNoShow) &&
                <div className={cn("flex justify-center items-center gap-3 flex-shrink-0 mt-3", arrowBtnGroupClassName)}>
                    {/* <span className={cn(countClassName)}>{children?.length > 0 ? `${activeSlide + 1}/${children?.length}` : '0/0'}</span> */}
                    <span className={cn(countClassName)}>{Math.max(1, activeSlide + 1)} / {Math.max(1, totalSlideCount)}</span>
                </div>
            }
        </div>
    )
})

export default CommonSliderWrapper