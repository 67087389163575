import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary';
import AboutUsDeatils from '../uiComponents/common/AboutUsDeatils';
import JobDetails from '../uiComponents/common/JobDetails';
import PortfolioDetails from '../uiComponents/common/PortfolioDetails';
import ReviewDetails from '../uiComponents/common/ReviewDetails';
import SalonCard from '../uiComponents/common/SalonCard';
import StudentBadgeIcon from '../uiComponents/icons/badge-icon/StudentBadgeIcon';
import TrustedBadgeIcon from '../uiComponents/icons/badge-icon/TrustedBadgeIcon';
import SkilledBadgeIcon from '../uiComponents/icons/badge-icon/SkilledBadgeIcon';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { PROFILE_COMPLETE_URL } from '../../api/axios';
import Http from '../../../Http';
import { enqueueSnackbar } from 'notistack';
import { Socket } from 'socket.io-client';
import CloseIcon from '../uiComponents/icons/CloseIcon';


const SalonInviteModal = ({ t, modalOpen, setModalOpen, providerData, refetch, salonData, salonId }) => {
    const [rejectId, setRejectId] = useState();
    const [acceptId, setAcceptId] = useState();
    const queryClient = useQueryClient();

    // const { profileId, connected, chatClient: socket } = useContext(SocketContext);

    const { mutateAsync: acceptInvitation } = useMutation(
        async (dataId) => {
            try {
                const { data } = await Http.post(PROFILE_COMPLETE_URL.acceptInvitation.replace(":id", dataId));
                if (data) {
                    setModalOpen(false)
                    refetch();
                    queryClient.invalidateQueries(["workerProfileData"]);
                    enqueueSnackbar(data?.message, {
                        variant: "success",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                    // setAcceptLodingBtn(false);
                    queryClient.invalidateQueries(["mySalonList"]);
                }
            } catch (error) {
                const errorData = error?.response?.data?.errors;
                const errorDataMsg = error?.response?.data?.message;
                if (errorData) {
                    Object.values(errorData).forEach(message => {
                        enqueueSnackbar("something went wrong", {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                }
                else if (errorDataMsg) {
                    enqueueSnackbar(errorDataMsg, {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            }
        }
    );

    const { mutateAsync: rejectInvitation } = useMutation(
        async (dataId) => {
            // setLodingBtn(true);
            try {
                const { data } = await Http.post(PROFILE_COMPLETE_URL.rejectInvitation.replace(":id", dataId));
                if (data) {
                    enqueueSnackbar(data?.message, {
                        variant: "success",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                    refetch();
                }
            } catch (error) {
                const errorData = error?.response?.data?.errors;
                // setLodingBtn(false);
                if (errorData) {
                    Object.values(errorData).forEach((value) => {
                        enqueueSnackbar(value, {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                } else {
                    enqueueSnackbar(error?.response?.data?.message, {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }

            }
        }
    );
    const hasAnyPortfolio = salonData?.workers?.some(worker => worker.protfolios && worker.protfolios.length > 0);
    const tabsData = [
        // {
        //     id: "1",
        //     title: t('salon_invite_modal.job'),
        //     content: <JobDetails salonData={salonData}/>
        // },
        {
            id: "2",
            title: t('salon_invite_modal.about_us'),
            content: <AboutUsDeatils salonData={salonData?.about_us} />
        },
        {
            id: "3",
            title: t('salon_invite_modal.reviews'),
            content: <ReviewDetails
                item={3}
                headerLeftTextClass={"text-sm font-medium"}
                headerReviewTextClass={"text-sm font-medium"}
                ratingStartIconClass={"w-3.5 h-3.5"}
                ratingGroupClass={"gap-0.5"}
            />
        },
        ...(hasAnyPortfolio ? [{
            id: "4",
            title: t('salon_invite_modal.portfolio'),
            content: <PortfolioDetails item={5} workerData={salonData?.workers} />
        }] : [])
    ];
    const badgeListData = [
        {
            id: "1",
            title: t('salon_invite_modal.student'),
            badge: <StudentBadgeIcon className='h-[60px] w-[60px]' />
        },
        {
            id: "2",
            title: t('salon_invite_modal.trusted'),
            badge: <TrustedBadgeIcon className='h-[60px] w-[60px]' />
        }
    ];


    const onHandleAccept = async (data) => {
        setAcceptId(data?.invitation_id)
        try {
            await acceptInvitation(data?.invitation_id);
        } catch (error) {
        }
    }

    const onHandleReject = async (dataId) => {
        setRejectId(dataId?.invitation_id);
        await rejectInvitation(dataId?.invitation_id);
        setModalOpen(false);
    }

    return (
        <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => { return; }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div id={"add-bulk-address"} className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[1200px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start align-middle shadow-110 transition-all">
                                <div className="modal-header pe-7 mb-4">
                                    <h2 className="font-semibold text-xl text-center text-blue-950">{t('salon_invite_modal.salon_invite')}</h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setModalOpen(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <h5 className='text-center text-blue-950 fw-500 w-full mb-5'>
                                        {providerData?.provider?.first_name} {providerData?.provider?.last_name} {t('salon_invite_modal.you_are_added_to_the_team_of_Salon')} {providerData?.salon_details?.business_name}.<br /> {t('salon_invite_modal.your_position')}: {providerData?.is_manager == 1 ? t('salon_invite_modal.manager') : providerData?.is_manager == 0 ? t('salon_invite_modal.worker') : null}
                                    </h5>
                                    <div className='mb-5'>
                                        <div className='rounded-lg lg:rounded-2xl border-[1.5px] py-2 px-3 max-w-[178px] flex items-center gap-3.5 mx-auto bg-light-800'>
                                            <div className='lg:h-[55px] lg:w-[55px] w-10 h-10 overflow-hidden rounded-full flex justify-center bg-white items-center'>
                                                <img src="../../../../../../assets/images/user4.jpg" className='h-full w-full object-cover object-center' alt="" />
                                            </div>
                                            <h6 className="flex-grow text-blue-950 text-center flex flex-col break-all gap-0.5 !leading-none text-lg font-medium">{providerData?.provider?.first_name}  {providerData?.provider?.last_name.charAt(0).toUpperCase()}. <span className='text-[15px] font-normal'>({t('salon_invite_modal.owner')})</span></h6>
                                        </div>
                                    </div>
                                    <SalonCard
                                        badgeGroupClassName='px-2 gap-2 min-w-[146px]'
                                        badgeTextClass='text-[13px]'
                                        className="mb-5"
                                        heartIcon={true}
                                        phoneIcon2={true}
                                        tabsData={tabsData}
                                        badgeListData={badgeListData}
                                        // salonData={salonData}
                                        salonId={salonId}
                                        cardTitleClass="text-blue-950"
                                        ratingItemClassName={"text-[13px]"}
                                    />
                                    <p className='text-lg font-medium text-blue-950 !leading-normal mb-5 text-center'>
                                        {t('salon_invite_modal.would_you_like_to_be_part_of_this_salon')}
                                    </p>
                                    <div className='w-full flex flex-col xsm:flex-row justify-center gap-x-5 gap-y-3'>
                                        <ButtonPrimary size="sm" className="w-full xsm:max-w-[334px] bg-danger-950 border-danger-950 hover:bg-danger-900 hover:border-danger-900" onClick={(e) => onHandleReject(providerData)}>{t('salon_invite_modal.decline')}</ButtonPrimary>
                                        <ButtonPrimary id={"acceptId_" + providerData?.invitation_id} size="sm" className="w-full xsm:max-w-[334px]" onClick={(e) => onHandleAccept(providerData)} >{t('salon_invite_modal.accept')}</ButtonPrimary>
                                    </div>
                                </div >
                            </Dialog.Panel >
                        </Transition.Child >
                    </div >
                </div >
            </Dialog >
        </Transition >
    )
}
export default withTranslation()(SalonInviteModal);