import React from 'react'

const CloseIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38 2L2 37.6664" stroke="currentcolor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2 2L38 37.6664" stroke="currentcolor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default CloseIcon