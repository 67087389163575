import React from 'react'

const AddCircularIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_817_15)">
    <path d="M19.3665 18.5994C23.3171 14.74 23.3171 8.48267 19.3665 4.62328C15.416 0.763885 9.01087 0.763885 5.06031 4.62328C1.10975 8.48267 1.10975 14.74 5.06031 18.5994C9.01087 22.4588 15.416 22.4588 19.3665 18.5994Z" stroke="currentcolor" stroke-width="2"/>
    <path d="M12.1218 6.76642L12.1354 16.4969" stroke="currentcolor" stroke-width="2.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.9932 11.643L7.2627 11.6298" stroke="currentcolor" stroke-width="2.3" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_817_15">
    <rect width="25" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default AddCircularIcon