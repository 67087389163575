import React from 'react'

function MaleIconNew(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.1918 39.9893C19.5681 39.9893 18.9444 39.9893 18.3207 39.9893C16.8551 39.9786 15.9299 39.016 15.9195 37.4972C15.9091 35.7538 15.8988 33.9998 15.9299 32.2564C15.9403 31.7537 15.7532 31.5077 15.3063 31.3793C14.8593 31.251 14.4123 31.0799 13.9861 30.898C12.9363 30.4595 12.323 29.6466 12.323 28.4808C12.3022 25.1545 11.9695 21.8282 12.1359 18.4911C12.2086 17.0579 12.0319 15.6568 12.0007 14.2343C11.9799 13.1968 12.4061 12.4267 13.2897 11.9133C14.3291 11.3037 15.3582 10.6726 16.3977 10.0844C16.7823 9.87047 16.8551 9.73143 16.5016 9.36778C13.7263 6.55483 14.6306 1.91294 18.2272 0.372771C20.0774 -0.418704 22.489 0.08399 23.9131 1.55998C25.4931 3.19641 25.9505 5.49597 25.1293 7.51744C24.8486 8.22335 24.464 8.85439 23.9235 9.35708C23.5909 9.66726 23.5597 9.83839 23.9963 10.0844C25.0045 10.6406 25.9713 11.2609 26.9795 11.8278C27.967 12.3839 28.3932 13.1861 28.362 14.3519C28.2789 17.7852 28.2789 21.2292 28.1853 24.6625C28.1542 25.9139 28.1334 27.1546 28.1022 28.406C28.071 29.6146 27.5201 30.4702 26.4078 30.9087C25.9401 31.0906 25.4723 31.2724 24.9941 31.4328C24.6303 31.5505 24.4848 31.7644 24.4952 32.1815C24.516 33.9142 24.5056 35.6362 24.4952 37.3689C24.4952 39.0481 23.5701 39.9893 21.9589 40C21.3664 40 20.7739 39.9893 20.1918 39.9893Z" fill="currentcolor" />
        </svg>
    )
}

export default MaleIconNew