import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary';
import SalonCard from '../uiComponents/common/SalonCard';
import CloseIcon from '../uiComponents/icons/CloseIcon';
import JobDetails from '../uiComponents/common/JobDetails';
import AboutUsDeatils from '../uiComponents/common/AboutUsDeatils';
import ReviewDetails from '../uiComponents/common/ReviewDetails';
import PortfolioDetails from '../uiComponents/common/PortfolioDetails';
import StudentBadgeIcon from '../uiComponents/icons/badge-icon/StudentBadgeIcon';
import TrustedBadgeIcon from '../uiComponents/icons/badge-icon/TrustedBadgeIcon';
import SkilledBadgeIcon from '../uiComponents/icons/badge-icon/SkilledBadgeIcon';
import TextareaWrap from '../uiComponents/inputs/TextareaWrap';
import ReviewCardFull from '../uiComponents/common/ReviewCardFull';


const ReportReviewModal = ({ t, modalOpen, setModalOpen }) => {


    const ratingAndReviews = [
        {
            id: 0,
            rating: "4.5/5",
            text: "Overall"
        },
        {
            id: 1,
            rating: "4.5/5",

            text: "Quality/price"
        },
        {
            id: 2,
            rating: "4/5",
            text: "Salon"
        },
        {
            id: 3,
            rating: "4/5",
            text: "Atmosphere"
        },
        {
            id: 4,
            rating: "4.3/5",
            text: "Service"
        },
        {
            id: 5,
            rating: "5/5",
            text: "Workers"
        },]



    return (
        <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => setModalOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div id={"add-bulk-address"} className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[1012px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start align-middle shadow-110 transition-all">
                                <div className="modal-header mb-4">
                                    <h2 className="font-semibold text-xl text-center text-blue-950">Report Review</h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setModalOpen(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <ReviewCardFull
                                        colored
                                        salonReviwes={true}
                                        isDateShow={false}
                                        groupClassName={"mb-2 border-[1.3px] rounded-[10px] border-[1.3px] rounded-[10px] border-borderColor  p-3.5 lg:p-0"}
                                        largeRGroupClassName="border-0 w-full"
                                        ratingAndReviews={ratingAndReviews}
                                    />

                                    <TextareaWrap
                                        placeholder="Kindly explain why you wish to report this review.."
                                        labelClassName="font-500"
                                        label="Reason for report"
                                        className="rounded-lg w-full common-scrollbar resize-none text-sm  sm:text-base min-h-[118px]"
                                        groupClassName={"w-full mt-5"}
                                        rows={2}
                                    />


                                    <ButtonPrimary size="sm" className="w-full mt-5">Send</ButtonPrimary>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withTranslation()(ReportReviewModal);