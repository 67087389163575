import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Card from '../../../uiComponents/Card'
import ButtonPrimary from '../../../uiComponents/buttons/ButtonPrimary'
import PlusLineIcon from '../../../uiComponents/icons/PlusLineIcon'
import ButtonBadge from '../../../uiComponents/buttons/ButtonBadge'
import LabelWrap from '../../../uiComponents/inputs/LabelWrap'
import { withTranslation } from 'react-i18next'
import InputRadioBox from '../../../uiComponents/inputs/InputRadioBox'
import InputWrap from '../../../uiComponents/inputs/InputWrap'
import RowWrap from '../../../uiComponents/RowWrap'
import NewSalonJoinRequestModal from '../../../modals/NewSalonJoinRequestModal'
import LeftSmallArrowIcon from '../../../uiComponents/icons/LeftSmallArrowIcon'
import WorkersIcon from '../../../uiComponents/icons/WorkersIcon'
import { PATH_BOTH, PATH_PROVIDER } from '../../../../routes/path'
import { Form, FormikProvider, useFormik } from "formik"
import { enqueueSnackbar, useSnackbar } from 'notistack';
import Http from '../../../../../Http';
import { useMutation, useQuery } from 'react-query';
import { PROFILE_COMPLETE_URL } from '../../../../api/axios';
import { authStore } from '../../../../contexts/AuthProvider';
import * as Yup from 'yup';
import SalonInviteModal from '../../../modals/SalonInviteModal';

const WorkerAdd = ({ t, ...props }) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const profile_setup = state?.profile_setup ?? true;
    const profileType = authStore((state) => state?.profileType)
    const salon_id = state?.salon_id ?? authStore((state) => state.salonDataId);
    const [addWorkerModalOpen, setAddWorkerModalOpen] = useState(false)
    const [workerCards, setWorkerCards] = useState([{ worker_email: '', worker_role: '' }]);
    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        workerCards: Yup.array().of(
            Yup.object().shape({
                worker_email: Yup.string().required(t("validation_message.worker_email_required")).email(t("validation_message.email_not_valid")),
                worker_role: Yup.boolean().required(t("validation_message.worker_role_required")),
            })
        )
    });

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: { workerCards },
        validationSchema,
        onSubmit: async (values) => {
            const workers = workerCards.map(card => ({ id: card.worker_id, email: card.worker_email, is_manager: card.worker_role }));
            const data = { workers, salon_id };
            await addWorker(data);
        },
    });
    const { handleSubmit, setFieldValue, setFieldTouched, getFieldProps, errors, touched, setErrors } = formik;

    const handleAddWorkerCardClick = () => {
        const newCard = { worker_email: '', worker_role: '' };
        setWorkerCards([...workerCards, newCard]); // Add the new card
    };

    const handleWorkerEmailChange = (index, value) => {
        const updatedCards = [...workerCards];
        updatedCards[index] = { ...updatedCards[index], worker_email: value };
        setWorkerCards(updatedCards);

    };

    const handleWorkerRoleChange = (index, value) => {
        const updatedCards = [...workerCards];
        updatedCards[index] = { ...updatedCards[index], worker_role: value };
        setWorkerCards(updatedCards);

    };

    const handleRemoveWorkerCardClick = async (index) => {
        // Check if there is more than one worker card
        if (workerCards.length > 1) {
            try {
                formik.setFieldTouched(`workerCards.${index}.worker_email`, false);
                formik.setFieldTouched(`workerCards.${index}.worker_role`, false);

                const updatedErrors = { ...errors };
                if (updatedErrors.workerCards && typeof updatedErrors.workerCards === 'object') {
                    delete updatedErrors.workerCards[index];
                } else {
                    console.log("updatedErrors.workerCards is not properly initialized");
                }
                setErrors(updatedErrors);

                const workerToRemove = workerCards[index];
                if (workerToRemove && workerToRemove.worker_id) {
                    await deleteData(workerToRemove.worker_id);
                }

                const updatedCards = workerCards.filter((_, i) => i !== index);
                setWorkerCards(updatedCards);

                const updatedValues = { ...formik.values };
                updatedValues.workerCards = updatedCards;
                formik.setValues(updatedValues);

            } catch (error) {
                enqueueSnackbar(t("snackbar_message.failed_to_remove_worker"), {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            }
        } else {
            enqueueSnackbar(t('snackbar_message.at_least_one_worker_is_required'), {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
        }
    };

    const { mutateAsync: addWorker } = useMutation(
        async (datas) => {
            try {
                const response = await Http.post(PROFILE_COMPLETE_URL.addWorker, datas);
                return { responseData: response.data, requestData: datas }
            } catch (error) {
                const errorData = error?.response?.data?.errors;
                const errorDataMsg = error?.response?.data?.message;
                if (errorData) {
                    Object.values(errorData).forEach(message => {
                        enqueueSnackbar(message, {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                }
                else if (errorDataMsg) {
                    enqueueSnackbar(errorDataMsg, {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            }
        },
        {
            onSuccess: (response) => {
                enqueueSnackbar(t("validation_message.worker_updated"), {
                    variant: "success",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
                const hasNoWorkerId = response.requestData.workers.some(worker => typeof worker.id === 'undefined');

                if (hasNoWorkerId) {
                    setAddWorkerModalOpen(true);
                }
                else {
                    setAddWorkerModalOpen(false);
                }
            },
        }
    );

    useEffect(() => {
        workerCards.forEach((card, index) => {
            setFieldValue(`workerCards.${index}.worker_email`, card.worker_email);
            setFieldValue(`workerCards.${index}.worker_role`, card.worker_role);
        });
    }, [workerCards, setFieldValue]);

    const { data: editWorkerData, refetch: refetchWorkerData } = useQuery(
        'editWorkerData',
        async () => {
            try {
                const response = await Http.get(PROFILE_COMPLETE_URL.getWorkerList, { params: { salon_id: salon_id } });

                if (response) {
                    return response.data.data;
                }
            } catch (error) {
                // setInitialDynamicFormData([{ email: '', is_manager: '' }]);
            } finally {
                // setIsLoading(false);
            }
        },
        { refetchOnWindowFocus: false }
    );

    useEffect(() => {
        if (editWorkerData) {
            const initialWorkerCards = editWorkerData.map((worker) => ({
                worker_id: worker.id,
                worker_email: worker.email || '',
                worker_role: worker.is_manager ? 1 : 0,
                disabled: !!worker.email
            }));
            setWorkerCards(initialWorkerCards);
        }
    }, [editWorkerData]);

    const { mutateAsync: deleteData } = useMutation(
        async (DataId) => {
            try {
                const { data } = await Http.del(PROFILE_COMPLETE_URL.deleteWorker.replace(":id", DataId));
                if (data) {
                    enqueueSnackbar(data.message, {
                        variant: "success",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                    return data;
                }
            } catch (error) {
                const errorData = error?.response?.data?.errors;
                if (errorData) {
                    Object.values(errorData).forEach((value) => {
                        enqueueSnackbar(value, {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                }
            }
        }
    );

    const handleBackClick = () => {
        if (profileType == 2) {
            if (profile_setup) {
                navigate(PATH_PROVIDER.salonProfile, { state: { selectedTab: 4 } })
            } else {
                navigate(PATH_PROVIDER.editSalon, { state: { selectedTab: 4 } })
            }
        } else if (profileType == 4) {
            navigate(PATH_BOTH.bothProfile, { state: { selectedTab: 4 } })
        } else {
            navigate(-1);
        }

    }

    return (
        <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit} autoComplete="off">
                <div className="page-title mb-3.5 flex items-center justify-center gap-2">
                    <LeftSmallArrowIcon className='h-8 w-8 text-white cursor-pointer' onClick={() => handleBackClick()} />
                    <WorkersIcon className='h-10 w-10 text-white' />
                    <h1 className="text-[40px] font-bold leading-[1.2] text-white">{t('comman_text.add_worker')}</h1>
                </div>
                {
                    workerCards?.map((card, index) => (
                        <Card className={"mb-3"} key={index} >

                            <ButtonBadge onClick={() => handleRemoveWorkerCardClick(index)} className={"mb-6 text-center font-semibold"}  >{t('check_box_label.worker')} {index + 1}</ButtonBadge>
                            <RowWrap className="grid md:grid-cols-2 gap-x-6 lg:gap-x-8 gap-y-5" lg={2}>
                                <InputWrap
                                    require
                                    size="sm"
                                    {...getFieldProps(`workerCards.${index}.worker_email`)}
                                    name={`workerCards[${index}].worker_email`}
                                    label={t('form_input_label.worker_email')}
                                    placeholder={t('input_filed_placeholder.input_worker_email')}
                                    labelClassName="text-[15px] mb-2"
                                    disabled={card.disabled}
                                    onChange={(e) => handleWorkerEmailChange(index, e.target.value)}
                                    errorType={touched.workerCards && touched.workerCards[index]?.worker_email && "error"}
                                    errorData={touched.workerCards && touched.workerCards[index]?.worker_email && errors.workerCards && errors.workerCards[index]?.worker_email}
                                />

                                <div className="meetime-form-group">
                                    <LabelWrap require labelClassName="mb-[18px] text-[15px]" label={t('form_input_label.worker_role')} />
                                    <div className="flex items-center flex-wrap gap-3 sm:gap-4">
                                        <InputRadioBox
                                            name={`workerCards[${index}].worker_role`}
                                            id={`${t('radio_label.worker')}_role_${index}`}
                                            text={t('check_box_label.worker')}
                                            labelClassName={"text-sm"}
                                            value={0}
                                            checked={card.worker_role === 0}
                                            onChange={() => handleWorkerRoleChange(index, 0)}
                                        />
                                        <InputRadioBox
                                            name={`workerCards[${index}].worker_role`}
                                            id={`${t('radio_label.manager')}_role_${index}`}
                                            text={t('check_box_label.manager_same_access_owner')}
                                            labelClassName={"text-sm"}
                                            value={1}
                                            checked={card.worker_role === 1}
                                            onChange={() => handleWorkerRoleChange(index, 1)}
                                        />
                                    </div>

                                    {touched.workerCards?.[index]?.worker_role && (
                                        <p className="error-label text-start mt-1 text-danger-950 text-sm font-medium">
                                            {touched.workerCards && touched.workerCards[index]?.worker_role && errors.workerCards && errors.workerCards[index]?.worker_role}
                                        </p>
                                    )}
                                </div>

                            </RowWrap>
                        </Card>
                    ))
                }
                <Card className={"sm:py-3 mb-3"}>
                    <ButtonPrimary type="button" onClick={handleAddWorkerCardClick} variant={"outline"} className={"w-full"} icon={<PlusLineIcon className="w-5 h-5" />} size="sm">{t('button_label.worker')}</ButtonPrimary>
                </Card>
                <Card className="sm:py-3">
                    <ButtonPrimary type="submit" className="w-full" size="sm"> {t('button_label.send_invite')} </ButtonPrimary>
                </Card>
                <NewSalonJoinRequestModal newWorkerJoinList={workerCards} modalOpen={addWorkerModalOpen} setModalOpen={setAddWorkerModalOpen} refetchWorkerData={refetchWorkerData} profile_setup={profile_setup} />
                {/* <SalonInviteModal newWorkerJoinList={newWorkerJoinList} modalOpen={addWorkerModalOpen} setModalOpen={setAddWorkerModalOpen} /> */}
            </Form>
        </FormikProvider>
    )
}

export default withTranslation()(WorkerAdd)