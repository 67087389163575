import LogoutIcon from "../../view/uiComponents/icons/LogoutIcon"
import ContactSupportIcon from "../../view/uiComponents/icons/header-footer-icon/ContactSupportIcon"

const CustomerLoginMenuData = [
    {
        list: [
            // {
            //     path: "",
            //     id: '',
            //     key: '',
            //     text: "Logout",
            //     icon: <LogoutIcon className="mr-2 h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />,
            // }
        ]
    },
]

export default CustomerLoginMenuData