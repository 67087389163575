import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary';
import CloseIcon from '../uiComponents/icons/CloseIcon';
import { withTranslation } from 'react-i18next'
import LabelWrap from '../uiComponents/inputs/LabelWrap';
import ServicesTypeList from '../uiComponents/common/ServicesTypeList';
import BarberIcon from '../uiComponents/icons/service-icon/BarberIcon';
import BodyTreatmentIcon from '../uiComponents/icons/service-icon/BodyTreatmentIcon';
import FacialTreatmentIcon from '../uiComponents/icons/service-icon/FacialTreatmentIcon';
import HairdresserIcon from '../uiComponents/icons/service-icon/HairdresserIcon';
import CustomSlider from '../uiComponents/CustomSlider';
import CommonSliderWrapper from '../uiComponents/common/CommonSliderWrapper';
import InputCheckbox from '../uiComponents/inputs/InputCheckbox';
import InputRadioBox from '../uiComponents/inputs/InputRadioBox';
import UserGroupIcon from '../uiComponents/icons/UserGroupIcon';
import CalenderIcon from '../uiComponents/icons/CalenderIcon';
import FilterIcon from '../uiComponents/icons/FilterIcon';
import AvailabilityCalendar from '../uiComponents/common/AvailabilityCalendar';
import LocationPin2 from '../uiComponents/icons/LocationPin2';
import InfoFIllIcon from '../uiComponents/icons/InfoFIllIcon';
import SalonChairIcon from '../uiComponents/icons/SalonChairIcon';
import LocationPin1 from '../uiComponents/icons/LocationPin1';
import LocationPin3 from '../uiComponents/icons/LocationPin3';
import RowWrap from '../uiComponents/RowWrap';
import ShopIcon from '../uiComponents/icons/ShopIcon';
import DropdownMenu from '../uiComponents/DropdownMenu';
import BottomArrowIcon from '../uiComponents/icons/BottomArrowIcon';
import HairRemovalIcon from '../uiComponents/icons/service-icon/HairRemovalIcon';
import HairstylistIcon from '../uiComponents/icons/service-icon/HairstylistIcon';
import LashesBrowsIcon from '../uiComponents/icons/service-icon/LashesBrowsIcon';
import MakeUpIcon from '../uiComponents/icons/service-icon/MakeUpIcon';
import ManicurePedicureIcon from '../uiComponents/icons/service-icon/ManicurePedicureIcon';
import MasseuseIcon from '../uiComponents/icons/service-icon/MasseuseIcon';
import PhysiotherapistIcon from '../uiComponents/icons/service-icon/PhysiotherapistIcon';
import SpaWellnessIcon from '../uiComponents/icons/service-icon/SpaWellnessIcon';
import CategorySelectDropdown from '../uiComponents/inputs/CategorySelectDropdown';
import InputCheckBoxGroup from '../uiComponents/inputs/InputCheckBoxGroup';
import InputRadioBoxGroup from '../uiComponents/inputs/InputRadioBoxGroup';
import { Formik, FormikProvider, useFormik } from 'formik';
import * as Yup from "yup";
import { PROFILE_COMPLETE_URL } from '../../api/axios';
import { useMutation } from 'react-query';
import Http from '../../../Http';
import { enqueueSnackbar } from 'notistack';
import { authStore } from '../../contexts/AuthProvider';
import IconShop2 from '../uiComponents/icons/IconShop2';
import { Checkbox } from 'antd';



// Filter for Salon profile (Service page) 




const SalonDetailsFilterModal = ({ t, modalOpen, setModalOpen, serviceFor, setServiceFor, serviceLocation, setServiceLocation, setFilterData, promotionType, selectCategoryTpes, servicesIds, salonService, ...props }) => {
    const [categorySelectItems, setCategorySelectItems] = useState([])
    const salonDataId = authStore((state) => state.salonDataId);
    const [gender, setGender] = useState([]);

    // const handleSelectServiceIds = (categoryId) => {
    //     setCategorySelectItems(categoryId)
    // };

    const handleSelectFillter = () => {
        salonService({
            service_type_ids: servicesIds,
            category_ids: selectCategoryTpes,
            gender: gender,
            promotions: promotionType == '' ? undefined : promotionType
        })
        setModalOpen(false)
    }

    const handleGenderChoose = (list) => {
        setGender(list);
    }

    const serviceForData = [
        {
            id: "man",
            name: "gender[]",
            label: t('comman_text.men'),
            value: 1,
            className: "peer-checked:text-blue-950",
            icon: "",
        },
        {
            id: "woman",
            name: "gender[]",
            label: t('comman_text.woman'),
            value: 2,
            className: "peer-checked:text-blue-950",
            icon: "",
        },
        {
            id: "boy",
            name: "gender[]",
            label: t('comman_text.boy'),
            value: 3,
            className: "peer-checked:text-blue-950",
            icon: "",
        },
        {
            id: "girl",
            name: "gender[]",
            label: t('comman_text.girl'),
            value: 4,
            className: "peer-checked:text-blue-950",
            icon: "",
        },
    ];    

    const handleApply = async () => {
        const values = {
            gender: serviceFor,
            salon_id: salonDataId,
            location: serviceLocation,
        };
        setFilterData(values)
        setModalOpen(false)
    };


    return (
        <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => setModalOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[864px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                <div className="modal-header pe-7 mb-6">
                                    <h2 className="font-semibold text-xl text-center text-blue-950">Filter</h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setModalOpen(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body flex flex-col justify-center items-center">
                                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-10 gap-5 w-full justify-start items-start mb-3'>
                                        <div className="meetime-form-group  lg:col-span-5 order-2 md:order-1">
                                            <LabelWrap labelClassName=" w-full text-[15px] font-medium" label={t('form_input_label.gender')} />
                                            <Checkbox.Group className="mt-0 flex_item_cb gap-2 md:gap-3 flex flex-wrap  justfy-start xsm:justify-between border border-success-100 rounded-md py-1.5 px-2.5" onChange={handleGenderChoose}>
                                                <Checkbox value={2}>{t('comman_text.woman')}</Checkbox>
                                                <Checkbox value={1}>{t('comman_text.men')}</Checkbox>
                                                <Checkbox value={3}>{t('comman_text.girl')}</Checkbox>
                                                <Checkbox value={4}>{t('comman_text.boy')}</Checkbox>
                                            </Checkbox.Group>
                                            {/* <InputCheckBoxGroup                                                      
                                                name = "gender"                                                      
                                                itemMainClassName={"w-full"}
                                                itemClassName={"text-[13px] lg:text-[15px] text-[15px] justify-between  text-start w-full"}
                                                data={serviceForData} 
                                                selectValue={serviceFor}
                                                setCheckValue={setServiceFor}
                                                onChange={handleGenderChoose}
                                                groupClassName={"gap-2 md:gap-3 flex flex-wrap  justfy-start xsm:justify-between border border-success-100 rounded-md py-1.5 px-2.5"}
                                            /> */}
                                        </div>
                                        <div className="meetime-form-group lg:col-span-4 order-1 md:order-2">
                                            <LabelWrap labelClassName="mb-1.5 w-full text-[15px] font-medium justify-start"
                                                label={t('form_input_label.service_location')}
                                                tooltipText={"Service your customers at the Salon"}
                                                tooltipContentClass={"z-[999999]"}
                                            // lableEndIcon={<InfoFIllIcon className="w-5 h-5" />}
                                            />
                                            <RowWrap className="grid xsm:grid-cols-2 sm:gap-x-6 md:gap-x-8 gap-y-2 border border-success-100 rounded-md py-1.5 px-2.5" sm={2}>
                                                <InputCheckbox name="location"
                                                    value={1}
                                                    checked={serviceLocation?.includes(1)}
                                                    // onChange={() => setServiceLocation(
                                                    //     serviceLocation?.includes(1)
                                                    //     ? serviceLocation.filter(loc => loc !== 1)
                                                    //     : [...serviceLocation, 1]
                                                    // )}
                                                    id={"t('check_box_label.salon')"}
                                                    prefixIcon={<IconShop2 className="w-5 h-5" />}
                                                    text={t('check_box_label.salon')}
                                                    className={"before:rounded-full"}
                                                />
                                                <InputCheckbox name="location"
                                                    value={2}
                                                    checked={serviceLocation?.includes(2)}
                                                    // onChange={() => setServiceLocation(
                                                    //     serviceLocation?.includes(2)
                                                    //     ? serviceLocation.filter(loc => loc !== 2)
                                                    //     : [...serviceLocation, 2]
                                                    // )}
                                                    id={t('check_box_label.on_the_go')}
                                                    prefixIcon={<LocationPin3 className="w-5 h-5" />}
                                                    text={t('check_box_label.on_the_go')}
                                                    className={"before:rounded-full"}
                                                />
                                            </RowWrap>
                                        </div>
                                        
                                    </div>
                                    <ButtonPrimary size="sm"  className="w-full mt-5" onClick={() => handleSelectFillter()}>Apply</ButtonPrimary>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withTranslation()(SalonDetailsFilterModal)