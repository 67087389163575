import React from 'react'
import { cn } from '../../../../lib/utils'

const MenuTabs = ({ tabsData, mainClass, tabClass, step, setStep, isClickEvent, ...props }) => {
    return (
        <div className={cn("outline-0 shadow-none flex items-center gap-6 rounded-[10px] px-[22px] text-blue-950 mb-[10px] bg-white focus:border-transparent overflow-hidden", mainClass)}>
            {
                tabsData && tabsData?.map((item, ind) => (
                    <button
                        disabled={item?.disabled ? "disabled" : ""}
                        type="button"
                        onClick={() => isClickEvent && setStep(ind + 1)}
                        className={cn(`block outline-0 shadow-none text-center text-sm font-medium !leading-none rounded-md py-[22px] relative ${isClickEvent ? 'cursor-pointer hover:text-success-950' : 'cursor-not-allowed'} ${step === ind + 1 ? 'text-success-950 before:absolute before:w-full before:h-[3px] before:rounded-t-[5px] before:bg-success-950 before:bottom-0' : ''}`,
                            tabClass)}>
                        {item?.title}
                    </button>
                ))
            }
        </div>
    )
}

export default MenuTabs