import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary';
import CloseIcon from '../uiComponents/icons/CloseIcon';
import { withTranslation } from 'react-i18next';
import CheckboxList from '../uiComponents/common/CheckboxList';
import InputCheckBoxGroup from '../uiComponents/inputs/InputCheckBoxGroup';


const SortByModal = ({ t, modalOpen, setModalOpen, SortByOption, selectedSortBy, setselectedSortBy, ...props }) => {

    const handleModalClose = () => {
        //------close modal------
        setModalOpen(false);
    }

    return (
        <>
            <Transition appear show={modalOpen} as={Fragment}>
                <Dialog as="div" className={`relative z-[999]`} onClose={handleModalClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-dark-950 bg-opacity-70"
                        />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto" >
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95">
                                <Dialog.Panel className="w-full max-w-[369px]  transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                    <div className="modal-header mb-4">
                                        <h2 className="font-semibold text-xl text-center text-blue-950">Sort By</h2>
                                        <button
                                            type={"button"}
                                            aria-label="button"
                                            className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                            onClick={handleModalClose}>
                                            <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <InputCheckBoxGroup
                                            selectValue={selectedSortBy}
                                            itemClassName={"before:hidden after:hidden border-[1.3px] text-[15px] peer-checked:text-white peer-checked:border-success-950 peer-checked:bg-success-950 border-success-100 py-0.5 px-4 rounded-full w-full flex items-center  justify-center border-[1.5] peer-checked:bg-[#A2FFF7] peer-checked:text-success-900 peer-checked:font-[600] py-[4.1px] peer-checked:border-borderColor"}
                                            setCheckValue={(sortByList) => setselectedSortBy(sortByList)}
                                            data={SortByOption}
                                            groupClassName={"flex-col flex gap-y-2 w-full"}
                                        />
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default withTranslation()(SortByModal)