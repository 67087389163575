import { Listbox, Transition } from '@headlessui/react'
import React, { useState } from 'react'
import { cn } from '../../../../lib/utils'
import BarberIcon from '../icons/service-icon/BarberIcon'
import BottomArrowIcon from '../icons/BottomArrowIcon'
import InputRadioBox from './InputRadioBox'
import InputRadioBoxGroup from './InputRadioBoxGroup'
import InputCheckBoxGroup from './InputCheckBoxGroup'
import { withTranslation } from 'react-i18next'

const CategorySelectDropdown = ({ selcetAll, t, selectedOption, handleSelectChange, categorySelectItems, size, children, groupClassName, className, disabledList, buttonIconClass, buttonClass, buttonTextClass, prefixIcon, dropArrowIcon, buttonText, dropMenuClass, dropItemClass, dropTextClass, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleMouseLeave = () => {
        setIsOpen(false)
    }
    return (
        <Listbox onMouseLeave={handleMouseLeave} static value={selectedOption} className={cn(groupClassName)} disabled={props.disabled ?? false}>
            <div className={cn("category-dropdown relative ", className)}>
                <Listbox.Button
                    aria-label="button"
                    type='button'
                    disabled={disabledList ? true : false}
                    onClick={() => setIsOpen(!isOpen)}
                    className={cn("w-full text-start outline-0 cursor-default bg-light-800 border-[1.5px] border-success-100 rounded-lg text-[15px] font-medium text-success-300 px-3.5 py-1 pe-7 min-h-12 flex items-center", buttonClass)}>
                    <div className="flex items-center text-start gap-2 justify-start">
                        {prefixIcon ? prefixIcon : <span className={cn("w-6 h-6", buttonIconClass)}><BarberIcon className={`w-6 h-6`} /></span>}
                        <span className={cn("flex-grow", buttonTextClass)}>{buttonText}</span>
                    </div>
                    <span className="pointer-events-none absolute inset-y-0 end-3 flex items-center">
                        {dropArrowIcon ? dropArrowIcon : <BottomArrowIcon className={`h-3 w-3 md:h-4 md:w-4 text-success-300/90 transition-all duration-150 ${isOpen && "rotate-180"}`} />}
                    </span>
                </Listbox.Button>
                <Transition
                    as={"div"}
                    show={isOpen}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                    className={cn("absolute min-w-[200px] max-h-60 w-full overflow-y-auto theme-scrollbar rounded-md py-1 text-base shadow-110 bg-white z-[1]", dropMenuClass)}>
                    <Listbox.Options onClick={(e) => e.stopPropagation()} className={cn("px-3", dropMenuClass)}>
                        {/* {[...Array(10)].map((item, ind) => */}
                        <Listbox.Option
                            key={"index"}
                            value={""}
                            className={cn("relative cursor-default select-none", dropItemClass)}>
                            <InputCheckBoxGroup
                                selcetAll={selcetAll}
                                checkSize="lg"
                                selectValue={categorySelectItems}
                                itemMainClassName={"w-full"}
                                itemClassName={"lg:text-[15px] text-[15px] justify-between text-success-300 text-start flex-row-reverse before:rounded-full before:peer-checked:bg-success-800 before:peer-checked:border-success-800 md:after:peer-checked:start-[unset] after:peer-checked:start-[unset] md:after:peer-checked:end-[7px] w-full"}
                                setCheckValue={handleSelectChange}
                                data={children}
                                itemGroupClassName={"py-2 border-b border-success-100 last:border-b-0"} />
                        </Listbox.Option>
                        {/* )} */}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
}

export default withTranslation()(CategorySelectDropdown)