import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next';
import Congratulation from '../../../common/Congratulation';
import CongratulationsIcon from '../../../uiComponents/icons/CongratulationsIcon';
import ButtonPrimary from '../../../uiComponents/buttons/ButtonPrimary';
import { PATH_BOTH, PATH_PROVIDER, PATH_WORKER } from '../../../../routes/path';
import { useNavigate } from 'react-router-dom';
import { authStore } from '../../../../contexts/AuthProvider';
import Card from '../../../uiComponents/Card';
import { PROFILE_COMPLETE_URL } from '../../../../api/axios';
import { useMutation } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import Http from '../../../../../Http';


const Congrats = ({ t, ...props }) => {
    const navigate = useNavigate();
    const userType = authStore((state) => state?.profileType)
    const ProfileCompleteStep = authStore((state) => state?.ProfileCompleteStep)
    const is_profile_complete = authStore((state) => state.is_profile_complete)
    const salon_id = authStore((state) => state?.salonDataId)


    const { mutateAsync: completeProfile } = useMutation(
        async (datas) => {
            try {
                const { data } = await Http.post(PROFILE_COMPLETE_URL.completeProfile, datas);
                if (data) {
                    if (userType == 2) {
                        ProfileCompleteStep(1)
                        navigate(PATH_PROVIDER.dashboard)
                    }
                    else if (userType == 4) {
                        ProfileCompleteStep(1)
                        navigate(PATH_BOTH.bothProfile, { state: { selectedTab: 6 } })
                    }
                }
            } catch (error) {
                const errorMessage = error?.response?.data?.message || 'Something went wrong';
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            }
        }
    );

    const handleContinue = async () => {
        try {
            await completeProfile({ salon_id: salon_id });
        } catch (error) {
        }
    }

    useEffect(() => {
        if (userType === 2 && is_profile_complete == 1) {
            navigate(PATH_PROVIDER.dashboard)
        }
    }, [])

    return (
        <>
            <div className="main-content-wrap">
                {
                    userType == 4 ?
                        <Card className={"mt-2.5 p-3 sm:p-5"}>
                            <Congratulation
                                icon={<CongratulationsIcon className="w-[120px]" />}
                                title={t("otp_verification_module.congratulations")}
                                desClassName={"mb-6 text-center"}
                                text={t('congratulation_modal.both_congrats')}
                                cardButton={<ButtonPrimary className={"w-full max-w-[310px]"} size="sm" type="button" onClick={() => handleContinue()}>{t("button.continue")}</ButtonPrimary>}
                            />
                        </Card>
                        :
                        <Card className={"mt-2.5 p-3 sm:p-5"}>
                            <Congratulation
                                icon={<CongratulationsIcon className="w-[120px]" />}
                                title={t("otp_verification_module.congratulations")}
                                desClassName={"mb-6 text-center"}
                                text={t('congratulation_modal.provider_congrats')}
                                cardButton={<ButtonPrimary className={"w-full max-w-[310px]"} size="sm" type="button" onClick={() => handleContinue()}>{t("button.continue")}</ButtonPrimary>}
                            />
                        </Card>
                }

            </div>
        </>
    )
}

export default withTranslation()(Congrats)