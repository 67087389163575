import React from 'react';

const EyeIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <path d="M20 6.66663C27.5283 6.66663 33.3233 11.5033 37.3767 17.9216L37.575 18.24L37.705 18.47L37.81 18.69C37.89 18.8719 37.9525 19.0611 37.9967 19.255C38.1003 19.7457 38.1003 20.2526 37.9967 20.7433C37.9525 20.9371 37.89 21.1263 37.81 21.3083L37.705 21.5283L37.575 21.7583L37.3767 22.0783C33.3233 28.4966 27.5283 33.3333 20 33.3333C12.4717 33.3333 6.67666 28.4966 2.62333 22.0783L2.35499 21.6383L2.24333 21.4233L2.19166 21.31C2.11166 21.128 2.04915 20.9388 2.00499 20.745C1.90109 20.2537 1.90109 19.7462 2.00499 19.255C2.04915 19.0611 2.11166 18.8719 2.19166 18.69C2.22412 18.6154 2.25914 18.5421 2.29666 18.47L2.42666 18.24L2.93166 17.4416C6.96833 11.265 12.6617 6.66663 20 6.66663ZM20 9.99996C13.9233 9.99996 8.94499 14.1566 5.44166 19.7016L5.25666 19.9983L5.74499 20.77C9.22999 26.0783 14.0983 30 20 30C26.0767 30 31.0567 25.8433 34.5583 20.2983L34.74 19.9966L34.255 19.23C30.7717 13.9216 25.9033 9.99996 20 9.99996ZM20 13.3333C21.7681 13.3333 23.4638 14.0357 24.714 15.2859C25.9643 16.5362 26.6667 18.2318 26.6667 20C26.6667 21.7681 25.9643 23.4638 24.714 24.714C23.4638 25.9642 21.7681 26.6666 20 26.6666C18.2319 26.6666 16.5362 25.9642 15.2859 24.714C14.0357 23.4638 13.3333 21.7681 13.3333 20C13.3333 18.2318 14.0357 16.5362 15.2859 15.2859C16.5362 14.0357 18.2319 13.3333 20 13.3333ZM20 16.6666C19.1159 16.6666 18.2681 17.0178 17.643 17.6429C17.0179 18.2681 16.6667 19.1159 16.6667 20C16.6667 20.884 17.0179 21.7319 17.643 22.357C18.2681 22.9821 19.1159 23.3333 20 23.3333C20.884 23.3333 21.7319 22.9821 22.357 22.357C22.9821 21.7319 23.3333 20.884 23.3333 20C23.3333 19.1159 22.9821 18.2681 22.357 17.6429C21.7319 17.0178 20.884 16.6666 20 16.6666Z" fill="currentColor" />
        </svg>

    );
}

export default EyeIcon;