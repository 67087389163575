import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonPrimary from '../../uiComponents/buttons/ButtonPrimary';
import CloseIcon from '../../uiComponents/icons/CloseIcon';
import { withTranslation } from 'react-i18next'
import LabelWrap from '../../uiComponents/inputs/LabelWrap';
import ServicesTypeList from '../../uiComponents/common/ServicesTypeList';
import BarberIcon from '../../uiComponents/icons/service-icon/BarberIcon';
import BodyTreatmentIcon from '../../uiComponents/icons/service-icon/BodyTreatmentIcon';
import FacialTreatmentIcon from '../../uiComponents/icons/service-icon/FacialTreatmentIcon';
import HairdresserIcon from '../../uiComponents/icons/service-icon/HairdresserIcon';
import CustomSlider from '../../uiComponents/CustomSlider';
import CommonSliderWrapper from '../../uiComponents/common/CommonSliderWrapper';
import InputCheckbox from '../../uiComponents/inputs/InputCheckbox';
import InputRadioBox from '../../uiComponents/inputs/InputRadioBox';
import UserGroupIcon from '../../uiComponents/icons/UserGroupIcon';
import CalenderIcon from '../../uiComponents/icons/CalenderIcon';
import FilterIcon from '../../uiComponents/icons/FilterIcon';
import AvailabilityCalendar from '../../uiComponents/common/AvailabilityCalendar';
import LocationPin2 from '../../uiComponents/icons/LocationPin2';
import InfoFIllIcon from '../../uiComponents/icons/InfoFIllIcon';
import SalonChairIcon from '../../uiComponents/icons/SalonChairIcon';
import LocationPin1 from '../../uiComponents/icons/LocationPin1';
import LocationPin3 from '../../uiComponents/icons/LocationPin3';
import RowWrap from '../../uiComponents/RowWrap';
import ShopIcon from '../../uiComponents/icons/ShopIcon';
import DropdownMenu from '../../uiComponents/DropdownMenu';
import BottomArrowIcon from '../../uiComponents/icons/BottomArrowIcon';
import HairRemovalIcon from '../../uiComponents/icons/service-icon/HairRemovalIcon';
import HairstylistIcon from '../../uiComponents/icons/service-icon/HairstylistIcon';
import LashesBrowsIcon from '../../uiComponents/icons/service-icon/LashesBrowsIcon';
import MakeUpIcon from '../../uiComponents/icons/service-icon/MakeUpIcon';
import ManicurePedicureIcon from '../../uiComponents/icons/service-icon/ManicurePedicureIcon';
import MasseuseIcon from '../../uiComponents/icons/service-icon/MasseuseIcon';
import PhysiotherapistIcon from '../../uiComponents/icons/service-icon/PhysiotherapistIcon';
import SpaWellnessIcon from '../../uiComponents/icons/service-icon/SpaWellnessIcon';
import CategorySelectDropdown from '../../uiComponents/inputs/CategorySelectDropdown';
import IconShop2 from '../../uiComponents/icons/IconShop2';

const PortfolioFilterModal = ({ t, modalOpen, gender = true, setModalOpen, ...props }) => {
    const [categorySelectItems, setCategorySelectItems] = useState([])

    const serviceCategory = [
        {
            id: 1,
            name: "Barber",
            icon: <BarberIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 11, label: 'Color' },
                { id: 12, label: 'Balayage' },
            ]
        },
        {
            id: 2,
            name: "Body Treatment",
            icon: <BodyTreatmentIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 13, label: 'Service 11' },
                { id: 14, label: 'Color 22' },
            ]
        },
        {
            id: 3,
            name: "Facial Treatment",
            icon: <FacialTreatmentIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 15, label: 'Service 13' },
                { id: 16, label: 'Service 14' },
            ]
        },
        {
            id: 4,
            name: "Hairdresser",
            icon: <HairdresserIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 17, label: 'Service 15' },
                { id: 18, label: 'Service 16' },
            ]
        },
        {
            id: 5,
            name: "Hair Removal",
            icon: <HairRemovalIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 19, label: 'Service 17' },
                { id: 110, label: 'Service 18' },
            ]
        },
        {
            id: 6,
            name: "Hairstylist",
            icon: <HairstylistIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 111, label: 'Service 19' },
                { id: 112, label: 'Service 20' },
            ]
        },
        {
            id: 7,
            name: "Lashes & Brows",
            icon: <LashesBrowsIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 113, label: 'Service 21' },
                { id: 114, label: 'Service 22' },
            ]
        },
        {
            id: 8,
            name: "MakeUp",
            icon: <MakeUpIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 115, label: 'Service 23' },
                { id: 116, label: 'Service 24' },
            ]
        },
        {
            id: 9,
            name: "Manicure Pedicure",
            icon: <ManicurePedicureIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 117, label: 'Service 25' },
                { id: 118, label: 'Service 26' },
            ]
        },
        {
            id: 10,
            name: "Masseuse",
            icon: <MasseuseIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 119, label: 'Service 27' },
                { id: 120, label: 'Service 28' },
            ]
        },
        {
            id: 11,
            name: "Physiotherapist",
            icon: <PhysiotherapistIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 121, label: 'Service 29' },
                { id: 122, label: 'Service 30' },
            ]
        },
        {
            id: 12,
            name: "Spa & Wellness",
            icon: <SpaWellnessIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 123, label: 'Service 31' },
                { id: 124, label: 'Service 32' },
            ]
        },
    ]


    const handleSelectServiceIds = (categoryId) => {
        setCategorySelectItems(categoryId)
    };


    return (
        <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => setModalOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[864px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                <div className="modal-header pe-7 mb-6">
                                    <h2 className="font-semibold text-xl text-center text-blue-950">Filter</h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setModalOpen(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body flex flex-col justify-center items-center">
                                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-5 w-full justify-start items-start mb-3'>
                                        {gender &&
                                            <div className="meetime-form-group lg:col-span-6">
                                                <LabelWrap labelClassName="mb-2 w-full text-[15px] font-medium" label={"Customer´s gender"} />
                                                <div className="grid grid-cols-2 xsm:grid-cols-4 gap-2 border border-success-100 rounded-[10px] py-1.5 px-2.5">
                                                    <InputCheckbox name="men" id="men" text={t('comman_text.men')} />
                                                    <InputCheckbox name="woman" id="woman" text={t('comman_text.woman')} />
                                                    <InputCheckbox name="boy" id="boy" text={t('comman_text.boy')} />
                                                    <InputCheckbox name="girl" id="girl" text={t('comman_text.girl')} />
                                                </div>
                                            </div>
                                        }
                                        <div className="meetime-form-group lg:col-span-5">
                                            <LabelWrap labelClassName="mb-2 w-full text-[15px] font-medium justify-start"
                                                label={t('form_input_label.service_location')}
                                                tooltipText={"Service your customers at the Salon"}
                                            // lableEndIcon={<InfoFIllIcon className="w-5 h-5" />}
                                            />
                                            <RowWrap className="grid grid-cols-2 sm:gap-x-6 md:gap-x-8 gap-y-2 border bg-light-800 border-success-100 rounded-[10px] py-1 px-2.5" sm={2}>
                                                <InputCheckbox name="location"
                                                    id={"t('check_box_label.salon')"}
                                                    prefixIcon={<IconShop2 className="h-4 w-4 xsm:w-5 xsm:h-5" />}
                                                    text={t('check_box_label.salon')}
                                                    className={"before:rounded-full peer-checked:before:bg-success-800 peer-checked:before:border-success-800 flex-row-reverse w-full peer-checked:after:start-[unset] peer-checked:after:end-[6px] text-success-900 font-medium text-sm sm:text-base"}
                                                    titleClass={"me-auto gap-1 sm:gap-2.5 text-success-900"}
                                                    groupClassName="border-e pe-3 border-borderColor sm:border-e-0"
                                                />
                                                <InputCheckbox name="location"
                                                    id={t('check_box_label.on_the_go')}
                                                    prefixIcon={<LocationPin3 className="h-4 w-4 xsm:w-5 xsm:h-5" />}
                                                    text={t('check_box_label.on_the_go')}
                                                    className={"before:rounded-full peer-checked:before:bg-success-800 peer-checked:before:border-success-800 flex-row-reverse w-full peer-checked:after:start-[unset] peer-checked:after:end-[6px] text-success-900 font-medium text-sm sm:text-base"}
                                                    titleClass={"me-auto gap-1 sm:gap-2.5 text-success-900"}
                                                />
                                            </RowWrap>
                                        </div>
                                    </div>

                                    <div className="gap-5 w-full">
                                        <LabelWrap labelClassName="mb-2 w-full text-[15px] font-medium" label={"Service(s)"} />
                                        <RowWrap className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-5">
                                            {
                                                serviceCategory.map((category, index) => (
                                                    <CategorySelectDropdown selcetAll={true} categorySelectItems={categorySelectItems} handleSelectChange={handleSelectServiceIds} children={category.categories} prefixIcon={category.icon} buttonText={category.name} />
                                                ))
                                            }
                                        </RowWrap>
                                    </div>

                                    <ButtonPrimary size="sm" className="w-full mt-5" onClick={() => setModalOpen(false)}>Apply</ButtonPrimary>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withTranslation()(PortfolioFilterModal)