import React, {  useState } from 'react'
import NoDataFound from '../../../../uiComponents/table/NoDataFound'
import Table from '../../../../uiComponents/table/Table'
import TR from '../../../../uiComponents/table/TR'
import TH from '../../../../uiComponents/table/TH'
import TD from '../../../../uiComponents/table/TD'
import THead from '../../../../uiComponents/table/THead'
import TBody from '../../../../uiComponents/table/TBody'
import { withTranslation } from 'react-i18next'
import MaleIcon from '../../../../uiComponents/icons/MaleIcon'
import FeMaleIcon from '../../../../uiComponents/icons/FeMaleIcon'
import BabyBoyIcon from '../../../../uiComponents/icons/BabyBoyIcon'
import BabyGirlIcon from '../../../../uiComponents/icons/BabyGirlIcon'
import { InputSwitch } from '../../../../uiComponents/inputs/InputSwitch'
import TrashIcon from '../../../../uiComponents/icons/TrashIcon'
import PencilIcon from '../../../../uiComponents/icons/PencilIcon'
import PercentageIcon from '../../../../uiComponents/icons/PercentageIcon'
import RightSmallArrowIcon from '../../../../uiComponents/icons/RightSmallArrowIcon'
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary'
import ImageGalleryIcon from '../../../../uiComponents/icons/ImageGalleryIcon'
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios'
import Http from '../../../../../../Http'
import { enqueueSnackbar } from 'notistack'
import { PATH_PROVIDER } from '../../../../../routes/path'
import { useNavigate } from 'react-router-dom'
import { authStore } from '../../../../../contexts/AuthProvider'
import ServiceDetailsModal from '../../../../modals/ServiceDetailsModal'

const ServiceTable = ({ t, serviceList, salonService, serviceChoose, categoryChoose,profile_setup, ...props }) => {
    const navigate = useNavigate()
    const salonId = authStore((state) => state.salonDataId);
    const [serviceDetailsModal, setServiceDetailsModal] = useState(false)
    const [actionId, setActionId] = useState(false)
    const [action, setAction] = useState(false)
    
    const handleStatusChange = async (id) => {

        Http.post(PROFILE_COMPLETE_URL.serviceActiveInactive, { 'id': id }).then(
            (res) => {
                salonService({
                    service_type_ids: serviceChoose,
                    category_ids: categoryChoose,
                });
                enqueueSnackbar(t("snackbar_message.status_updated_successfully"), {
                    variant: "success",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
                // setLoadingScreen(false);
            }
        );

    };

    const handleActionClick = async (id, type) => {
        setServiceDetailsModal(true);
        setActionId(id);
        if (type == "delete") {
            setAction("delete");
        } else {
            setAction("edit");
        }
    };

    const handleDelete = (id) => {
        Http.del(PROFILE_COMPLETE_URL.deleteService.replace(':id', id)).then(
            (res) => {
                salonService({
                    service_type_ids: serviceChoose,
                    category_ids: categoryChoose,
                });

                setServiceDetailsModal(false);
                setActionId("");

                enqueueSnackbar(t("snackbar_message.service_deleted_successfully"), {
                    variant: "success",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            }
        );
    }

    const handleEdit = async (id) => {

        navigate(PATH_PROVIDER.editService, { state: { salonId: salonId, id: id, edit: true , profile_setup: profile_setup } })
    }
    const calculateDiscount = (price, discountPrice, discountType) => {
        // 1=per , 2=kr
        if (discountType == 1) {
            let discount = price * discountPrice / 100;
            return price - discount + " Dkk.";
        } else if (discountType == 2) {
            return price - discountPrice + " Dkk.";
        }
        return price + " Dkk.";
    }
    const calculateDiscount2 = (price, discountPrice, discountType) => {
        // 1=per , 2=kr
        if (discountType == 1) {
            return discountPrice + " %";
        } else if (discountType == 2) {
            return price - discountPrice + " Dkk.";
        }
        return "-";
    }


    return (
        <div className="category-table-wrapper border-[1.3px] bg-light-800 border-success-100 rounded-xl p-3 mb-5">
            <div className="overflow-auto">
                <Table className={"text-[15px] text-blue-950 border-separate border-spacing-y-2"}>
                    <THead>
                        <TR>
                            <TH className={"font-medium border-b-0 py-1.5 px-3 md:px-5"}>{t("comman_text.service")}</TH>
                            <TH className={"font-medium border-b-0 py-1.5 px-3 md:px-5"}>{t("comman_text.price_Standard/Student/Senio")}</TH>
                            <TH className={"font-medium border-b-0 py-1.5 px-3 md:px-5"}>{t("comman_text.for")}</TH>
                            <TH className={"font-medium border-b-0 py-1.5 px-3 md:px-5"}>{t("comman_text.images")}</TH>
                            <TH className={"font-medium border-b-0 py-1.5 px-3 md:px-5"}>{t("comman_text.duration")}</TH>
                            <TH className={"font-medium border-b-0 py-1.5 px-3 md:px-5"}>{t("comman_text.active")}</TH>
                            <TH className={"font-medium border-b-0 py-1.5 px-3 md:px-5"}>{t("comman_text.action")}</TH>
                        </TR>
                    </THead>
                    <TBody>
                        {
                            serviceList?.length ? serviceList.map((data, index) => (
                                <>
                                    <TR>
                                        <TD className={"px-5 py-2 font-semibold border-[1.5px] leading-[20px] border-success-100 text-success-900 rounded-md bg-success-150 text-center"} colSpan={7}>{data[0].category?.name}</TD>
                                    </TR>
                                    {
                                        data.length && data.map((service, ind) => (
                                            <TR className={"[&>td:not(:last-child)]:before:w-[1px] [&>td:not(:last-child)]:before:h-[calc(100%-8px)] [&>td:not(:last-child)]:before:bg-success-100 [&>td:not(:last-child)]:before:block [&>td:not(:last-child)]:before:absolute [&>td:not(:last-child)]:before:end-0 [&>td:not(:last-child)]:before:top-1 [&>td:not(:last-child)]:relative"} key={index + "_" + ind}>
                                                <TD className={"px-1 py-1 ps-5 border-t-[1.5px] border-b-[1.5px] border-s-[1.5px] border-success-100 rounded-tl-md rounded-bl-md xl:min-w-[300px]"}><p className="text-blue-950 font-medium">{service.title}</p></TD>
                                                <TD className={"px-1 py-1 border-t-[1.5px] border-b-[1.5px] border-success-100 w-[220px] xl:w-[unset]"}>
                                                    <ul className="flex items-center gap-1 text-center text-blue-950 font-normal">
                                                        <li className="w-1/3">{calculateDiscount(service?.price, service?.discount_price, service?.discount_type)}</li>
                                                        <li>/</li>
                                                        <li className="w-1/3">{calculateDiscount2(service?.price, service?.discount_price, service?.discount_type)}</li>
                                                        <li>/</li>
                                                        <li className="w-1/3">{calculateDiscount2(service?.price, service?.discount_price, service?.discount_type)}</li>
                                                    </ul>
                                                </TD>
                                                <TD className={"px-1 py-1 border-t-[1.5px] border-b-[1.5px] border-success-100 w-[100px] xl:w-[unset]"}>
                                                    <ul className="flex items-end text-center justify-center">
                                                        {service.service_for.includes('1') ? <li className="flex-shrink-0 leading-none"><MaleIcon className="h-5 text-[#3BDFFD]" /></li> : <></>}
                                                        {service.service_for.includes('2') ? <li className="flex-shrink-0 leading-none"><FeMaleIcon className="h-5 text-[#FE7AB6]" /></li> : <></>}
                                                        {service.service_for.includes('4') ? <li className="flex-shrink-0 leading-none"><BabyBoyIcon className="h-3.5 text-[#3BDFFD]" /></li> : <></>}
                                                        {service.service_for.includes('3') ? <li className="flex-shrink-0 leading-none"><BabyGirlIcon className="h-3.5 text-[#FE7AB6]" /></li> : <></>}
                                                    </ul>
                                                </TD>
                                                <TD className={"text-center px-1 py-1 border-t-[1.5px] border-b-[1.5px] border-success-100 w-[110px] xl:w-[unset]"}><p className="text-blue-950">{service?.images?.length ? (<ImageGalleryIcon className="w-6 h-6" />) : ""}</p></TD>
                                                <TD className={"text-center px-1 py-1 border-t-[1.5px] border-b-[1.5px] border-success-100 w-[100px] xl:w-[unset]"}><p className="text-blue-950 text-sm font-medium">{service?.duration} min.</p></TD>
                                                <TD className={"text-center px-1 py-1 border-t-[1.5px] border-b-[1.5px] border-success-100 w-[100px] xl:w-[unset]"}>
                                                    <p><InputSwitch checked={service.status ? true : false} onChange={() => handleStatusChange(service?.id)} /></p>
                                                </TD>
                                                <TD className={"action px-1 py-1 pe-5 border-t-[1.5px] border-b-[1.5px] border-e-[1.5px] border-success-100 rounded-tr-md rounded-br-md w-[190px] xl:w-[unset]"}>
                                                    <div className="flex items-center justify-end gap-2">
                                                        <ButtonPrimary size={"sm"} className={"p-1 rounded-[5px]"} icon={<TrashIcon className="w-4 h-4" />} type="button" onClick={() => handleActionClick(service.id, "delete")} />
                                                        <ButtonPrimary size={"sm"} className={"p-1 rounded-[5px]"} icon={<PencilIcon className="w-4 h-4" />} type="button" onClick={() => handleEdit(service.id)} />
                                                        <ButtonPrimary size={"sm"} className={"p-1 rounded-[5px]"} icon={<PercentageIcon className="w-4 h-4" />} type="button" />
                                                        <ButtonPrimary size={"sm"} className={"p-1 rounded-[5px]"} icon={<RightSmallArrowIcon className="w-4 h-4" />} type="button" onClick={() => handleActionClick(service.id, "edit")} />
                                                    </div>
                                                </TD>
                                            </TR>
                                        ))
                                    }
                                </>
                            )) : <NoDataFound colSpan={7} className={"text-center"} />
                        }


                    </TBody>
                </Table>
            </div>

            <ServiceDetailsModal serviceDetailsModal={serviceDetailsModal} setServiceDetailsModal={setServiceDetailsModal} handleDelete={handleDelete} id={actionId} action={action} />
        </div>
    )
}

export default withTranslation()(ServiceTable)