import { Button, Modal } from 'antd';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import CloseIcon from '../uiComponents/icons/CloseIcon';
import { Dialog, Transition } from '@headlessui/react';
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary';
import TrustedBadgeIcon from '../uiComponents/icons/badge-icon/TrustedBadgeIcon';
import StudentBadgeIcon from '../uiComponents/icons/badge-icon/StudentBadgeIcon';
import SkilledBadgeIcon from '../uiComponents/icons/badge-icon/SkilledBadgeIcon';

const BadgeInfoModal = ({ t, modalOpen, setModalOpen, ...props }) => {


    const badgeInfoData = [
        {
            id: "badge_id_1",
            title: "Trusted",
            icon: <TrustedBadgeIcon className="h-[50px] w-[50px] sm:h-[60px] sm:w-[60px] md:h-[50px] md:w-[50px]  lg:h-[60px] lg:w-[60px]" />,
            descriptipn: 'Provider is a Student getting an education.'
        },
        {
            id: "badge_id_2",
            title: "Top Rated",
            icon: <StudentBadgeIcon className="h-[50px] w-[50px] sm:h-[60px] sm:w-[60px] md:h-[50px] md:w-[50px]  lg:h-[60px] lg:w-[60px]" />,
            descriptipn: 'Provides is able to treat customers with mental disabilities, requirering patience and calmness.'
        },
        {
            id: "badge_id_3",
            title: "Advisor",
            icon: <SkilledBadgeIcon className="h-[50px] w-[50px] sm:h-[60px] sm:w-[60px] md:h-[50px] md:w-[50px]  lg:h-[60px] lg:w-[60px]" />,
            descriptipn: 'Provider is one of the best rated, they are highly recommended.'
        },
        {
            id: "badge_id_4",
            title: "Skilled",
            icon: <TrustedBadgeIcon className="h-[50px] w-[50px] sm:h-[60px] sm:w-[60px] md:h-[50px] md:w-[50px]  lg:h-[60px] lg:w-[60px]" />,
            descriptipn: 'Provider is a Student getting an education.'
        },
        {
            id: "badge_id_5",
            title: "Very Skilled",
            icon: <StudentBadgeIcon className="h-[50px] w-[50px] sm:h-[60px] sm:w-[60px] md:h-[50px] md:w-[50px]  lg:h-[60px] lg:w-[60px]" />,
            descriptipn: 'Provides is able to treat customers with mental disabilities, requirering patience and calmness.'
        },
        {
            id: "badge_id_6",
            title: "Top Skilled",
            icon: <SkilledBadgeIcon className="h-[50px] w-[50px] sm:h-[60px] sm:w-[60px] md:h-[50px] md:w-[50px]  lg:h-[60px] lg:w-[60px]" />,
            descriptipn: 'Provider is one of the best rated, they are highly recommended.'
        },
        {
            id: "badge_id_7",
            title: "Relax & Enjoy",
            icon: <TrustedBadgeIcon className="h-[50px] w-[50px] sm:h-[60px] sm:w-[60px] md:h-[50px] md:w-[50px]  lg:h-[60px] lg:w-[60px]" />,
            descriptipn: 'Provider is a Student getting an education.'
        },
        {
            id: "badge_id_8",
            title: "Student",
            icon: <SkilledBadgeIcon className="h-[50px] w-[50px] sm:h-[60px] sm:w-[60px] md:h-[50px] md:w-[50px]  lg:h-[60px] lg:w-[60px]" />,
            descriptipn: 'Provider is one of the best rated, they are highly recommended.'
        },
        {
            id: "badge_id_9",
            title: "Exclusive",
            icon: <TrustedBadgeIcon className="h-[50px] w-[50px] sm:h-[60px] sm:w-[60px] md:h-[50px] md:w-[50px]  lg:h-[60px] lg:w-[60px]" />,
            descriptipn: 'Provider is a Student getting an education.'
        },
        {
            id: "badge_id_10",
            title: "Organic",
            icon: <StudentBadgeIcon className="h-[50px] w-[50px] sm:h-[60px] sm:w-[60px] md:h-[50px] md:w-[50px]  lg:h-[60px] lg:w-[60px]" />,
            descriptipn: 'Provides is able to treat customers with mental disabilities, requirering patience and calmness.'
        }
    ]

    return (
        <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => setModalOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[900px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                <div className="modal-header mb-6">
                                    <h2 className="font-semibold text-xl text-center text-blue-950">{t("comman_text.badge_explanation")}</h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setModalOpen(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body flex flex-col justify-center items-center">
                                    <div className="gap-x-3  lg::gap-x-5 gap-y-3 grid  grid-cols-1 md:grid-cols-2 w-full">
                                        {
                                            badgeInfoData?.map((item, index) => (
                                                <div className='border border-success-100 text-blue-950 grid grid-cols-1  sm:grid-cols-3 rounded-xl md:rounded-[14px] py-1.5 px-2.5 w-full h-auto sm:h-[85px]'>
                                                    <div className='col-span-1 py-1 sm:py-0 border-b sm:border-b-0 border-e-0 sm:border-e border-success-100 flex flex-col sm:flex-row items-center w-full gap-1'>
                                                        <div>{item.icon}</div>
                                                        <p className="text-center text-sm font-500 flex justify-center items-center w-full">{item.title}</p>
                                                    </div>
                                                    <div className='ps-1 py-2 sm:py-1 col-span-1 sm:col-span-2 text-center text-[13px] font-400 w-full flex justify-center items-center h-100 text-wrap truncate'>
                                                        <p>{item.descriptipn}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>

                                    <ButtonPrimary size="sm" className="w-full mt-5" onClick={() => setModalOpen(false)}>Got It</ButtonPrimary>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withTranslation()(BadgeInfoModal)