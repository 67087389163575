import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonPrimary from '../../uiComponents/buttons/ButtonPrimary';
import CloseIcon from '../../uiComponents/icons/CloseIcon';
import { withTranslation } from 'react-i18next'
import LabelWrap from '../../uiComponents/inputs/LabelWrap';
import ServicesTypeList from '../../uiComponents/common/ServicesTypeList';
import BarberIcon from '../../uiComponents/icons/service-icon/BarberIcon';
import BodyTreatmentIcon from '../../uiComponents/icons/service-icon/BodyTreatmentIcon';
import FacialTreatmentIcon from '../../uiComponents/icons/service-icon/FacialTreatmentIcon';
import HairdresserIcon from '../../uiComponents/icons/service-icon/HairdresserIcon';
import CustomSlider from '../../uiComponents/CustomSlider';
import CommonSliderWrapper from '../../uiComponents/common/CommonSliderWrapper';
import InputCheckbox from '../../uiComponents/inputs/InputCheckbox';
import InputRadioBox from '../../uiComponents/inputs/InputRadioBox';
import UserGroupIcon from '../../uiComponents/icons/UserGroupIcon';
import CalenderIcon from '../../uiComponents/icons/CalenderIcon';
import FilterIcon from '../../uiComponents/icons/FilterIcon';
import AvailabilityCalendar from '../../uiComponents/common/AvailabilityCalendar';
import OfferRequestCalender from '../../uiComponents/common/OfferRequestCalender';

const CalendarModal = ({ t, modalOpen, setModalOpen, ...props }) => {

    return (
        <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => setModalOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[914px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                <div className="modal-header mb-4">
                                    <h2 className="font-semibold text-xl text-center text-blue-950">Calender</h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setModalOpen(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body flex flex-col justify-center items-center">
                                    <OfferRequestCalender mainClassName={"bg-light-800 shadow-120 rounded-[10px] py-4 px-3.5"} />
                                    <ButtonPrimary size="sm" className="w-full mt-5" onClick={() => setModalOpen(false)} >Apply</ButtonPrimary>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withTranslation()(CalendarModal)