import React from 'react';

const ChairWithManIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_520_7)">
                <mask id="mask0_520_7" maskUnits="userSpaceOnUse" x="1" y="2" width="40" height="38">
                    <path d="M40.377 2H1V39.8018H40.377V2Z" fill="currentColor" />
                </mask>
                <g mask="url(#mask0_520_7)">
                    <path d="M23.8391 31.6317L23.8485 31.6377L23.8581 31.6433C24.3523 31.9399 24.5873 32.2993 24.6365 32.7233L24.6415 32.7665L24.6513 32.8089C24.7627 33.2909 24.6521 33.7681 24.3739 34.1421L24.3627 34.1571L24.3523 34.1727C22.1219 37.4953 18.4289 39.4221 14.3294 39.4221C7.67112 39.4221 2.30469 34.2259 2.30469 27.9093C2.30469 24.8329 3.5968 21.8923 5.97776 19.6716C6.41026 19.3215 6.88792 19.1529 7.4384 19.1529C7.96198 19.1529 8.49876 19.3783 8.85104 19.7053C9.20862 20.1151 9.37272 20.5569 9.37272 21.0577C9.37272 21.4505 9.15094 21.9651 8.79 22.3115C7.25518 23.7849 6.33742 25.7931 6.33742 27.9093C6.33742 32.2245 9.99488 35.6913 14.4114 35.6913C17.1146 35.6913 19.6604 34.3939 21.1251 32.1969L21.1297 32.1899C21.7073 31.3027 22.9485 31.0617 23.8391 31.6317Z" fill="currentColor" />
                    <path d="M31.8849 25.3251L33.6565 31.7033H36.6071C37.7483 31.7033 38.6807 32.6199 38.7521 33.5993C38.7405 34.6947 37.7587 35.6129 36.6071 35.6129H32.0131C31.0627 35.6129 30.2567 35.0095 30.0085 34.1157L28.2369 27.7375H18.2312C17.218 27.7375 16.4006 27.0747 16.146 26.1667L13.1965 14.7619C12.9522 13.6878 13.6436 12.5529 14.756 12.3156C15.9419 12.0626 17.1203 12.775 17.3575 13.7998L17.8914 15.9524H25.9425C27.1705 15.9524 28.0877 16.8699 28.0877 17.986C28.0877 19.0902 27.1011 20.0196 25.9425 20.0196H18.8746L19.8663 23.8279H29.8803C30.8307 23.8279 31.6365 24.4313 31.8849 25.3251Z" fill="currentColor" />
                    <path d="M16.5207 6.803C16.5207 8.15538 15.3574 9.30912 13.8833 9.30912C12.4092 9.30912 11.2459 8.15538 11.2459 6.803C11.2459 5.45064 12.4092 4.29688 13.8833 4.29688C15.3574 4.29688 16.5207 5.45064 16.5207 6.803Z" fill="currentColor" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_520_7">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default ChairWithManIcon;
