import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary';
import CloseIcon from '../uiComponents/icons/CloseIcon';
import { withTranslation } from 'react-i18next'
import ButtonBadge from '../uiComponents/buttons/ButtonBadge';
import InputWrap from '../uiComponents/inputs/InputWrap';
import RowWrap from '../uiComponents/RowWrap';
import InputRadioBox from '../uiComponents/inputs/InputRadioBox';
import LabelWrap from '../uiComponents/inputs/LabelWrap';
import PlusLineIcon from '../uiComponents/icons/PlusLineIcon';
import ServicesTypeList from '../uiComponents/common/ServicesTypeList';
import Divider from '../uiComponents/Divider';
import { PROFILE_COMPLETE_URL } from '../../api/axios';
import Http from '../../../Http';
import { useQuery } from 'react-query';


const ServicesFilterModal = ({ t, modalOpen, setModalOpen, selectedServices, setSelectedServices }) => {

  const [serviceTypeData, setServiceTypeData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryChoose, setCategoryChoose] = useState([]);
  const [serviceChoose, setServiceChoose] = useState([]);

  const subCategory = async (id, index = null) => {
    return await Http.post(PROFILE_COMPLETE_URL.categories, { 'service_type_ids': [id] }).then(
      (res) => {
        let data = [...categoryData];
        let dataSelected = [...categoryChoose];
        if (index !== null) {
          data[index] = res?.data?.data;
          dataSelected[index] = res?.data?.data[0]?.id;
        } else {
          data.push(res.data?.data);
          dataSelected.push(res?.data?.data[0]?.id);
        }
        setCategoryData(data);
        setCategoryChoose(dataSelected)
      });
  }

  useQuery(["serviceRefetchData3"],
    async ({ }) => {
      return await Http
        .get(PROFILE_COMPLETE_URL.serviceTypes)
        .then((res) => {
          setServiceTypeData(res.data?.data)
          setServiceChoose([res.data?.data[0].id])
          subCategory(res.data?.data[0].id)
        });
    },
    { refetchOnWindowFocus: false }
  );

  const handleAddCardClick = (id) => {

    if (categoryData.length === 10) return true;

    let dataSelected = [...serviceChoose];
    dataSelected.push(serviceTypeData[0]?.id);
    setServiceChoose(dataSelected);
    subCategory(serviceTypeData[0]?.id);
  };


  const handleRemoveCardClick = (id) => {

    setCategoryData(prev => prev.filter((item, ind) => ind !== id));
    setServiceChoose(prev => prev.filter((item, ind) => ind !== id));
    setCategoryChoose(prev => prev.filter((item, ind) => ind !== id));

  };

  const handleServiceSelection = (id, index) => {
    let dataSelected = [...serviceChoose];
    dataSelected[index] = id;
    setServiceChoose(dataSelected);
    subCategory(id, index)

  };

  const handleCategorySelection = (id, index) => {
    let dataSelected = [...categoryChoose];
    dataSelected[index] = id;
    setCategoryChoose(dataSelected);

  };

  const handleClose = () => {
    setModalOpen(false);

    setSelectedServices({
      service_types: serviceChoose,
      categories: categoryChoose,
      len: categoryChoose?.length
    })
  }

  return (
    <Transition appear show={modalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[999]" onClose={() => handleClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div id={"add-bulk-address"} className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-[1092px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start align-middle shadow-110 transition-all">
                <div className="modal-header mb-6">
                  <h2 className="font-semibold text-xl text-center text-blue-950">Select concerned Service Type / Category  {t("job_manager.concerned_service_type")}</h2>
                  <button
                    type={"button"}
                    aria-label="button"
                    className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                    onClick={() => handleClose()}>
                    <CloseIcon className="w-5 h-5 text-blue-950/50" />
                  </button>
                </div>
                <div className="modal-body flex flex-col justify-center items-center ">
                  <ul className="mb-6 w-full">
                    {
                      categoryData?.map((item, index) => (
                  
                          <li className={""}>
                            <ButtonBadge onClick={() => handleRemoveCardClick(index)} className={`mb-6`} >{t("check_box_label.service")} {index + 1}</ButtonBadge>
                            <div className='w-full'>
                              <div className="flex flex-col gap-2 mb-4">
                                <LabelWrap label={t('form_input_label.service_type') + "(s)"} labelClassName={"mb-0"}  require />
                                <ServicesTypeList radioName={`service-${index}`} 
                                labelClassName="bg-white"
                                className="gap-4 sm:gap-3.5 lg:flex grid grid-cols-1 xsm:grid-cols-2 sm:grid-cols-3 md:grid-cols-4" itemClassName="w-full max-w-full lg:max-w-[162px]"
                                id={`serviceCheck-${index}`} dataList={serviceTypeData}
                                  handleSelect={(id) => handleServiceSelection(id, index)} checked={serviceChoose[index]}
                                />
                              </div>
                              <div className="flex flex-col gap-2">
                                <LabelWrap label={t('form_input_label.category')} labelClassName={"mb-0"} require />
                                <ServicesTypeList radioName={`category-${index}`} id={`categoryCheck-${index}`} icon={false}
                                  dataList={categoryData[index] ?? []}
                                  handleSelect={(id) => handleCategorySelection(id, index)}
                                   checked={categoryChoose[index]} 
                                  className="gap-3.5 lg:flex grid grid-cols-1 xsm:grid-cols-2 sm:grid-cols-3 md:grid-cols-4" itemClassName="w-full max-w-full lg:max-w-[162px]"
                                  />
                              </div>
                            </div>
                            {selectedServices?.length - 1 !== index && <Divider className="my-6" />}
                          </li>

                      ))
                    }
                  </ul>
                  <div className={"w-full mb-5"}>
                    <ButtonPrimary onClick={() => handleAddCardClick(selectedServices.id++)} variant={"outline"} className={"w-full font-500 !text-base"} icon={<PlusLineIcon className="w-5 h-5" />} size="sm">{t("form_input_label.service_type")} / {t('form_input_label.category')}</ButtonPrimary>
                  </div>
                  <div className="w-full">
                    <ButtonPrimary onClick={() => handleClose()} className={"w-full font-medium lg:text-base"} size="sm" type="button">{t('job_manager.select')}</ButtonPrimary>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition >
  )
}

export default withTranslation()(ServicesFilterModal)