import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import CloseIcon from '../uiComponents/icons/CloseIcon'
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary'
import CalendarIcon from '../uiComponents/icons/CalenderIcon'
import ChooseBookingCard from '../uiComponents/common/ChooseBookingCard'
import Divider from '../uiComponents/Divider'
import DropZone from '../uiComponents/inputs/DropZone'
import DragandDropIcon from '../uiComponents/icons/DragandDropIcon'
import RowWrap from '../uiComponents/RowWrap'
import LabelWrap from '../uiComponents/inputs/LabelWrap'
import CustomTabs from '../uiComponents/tabs/CustomTabs'

const AddPortfolioWithUploadImageModal = ({ addPortfolioWithUploadImageModal, setAddPortfolioWithUploadImageModal }) => {
    return (
        <Transition appear show={addPortfolioWithUploadImageModal} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => setAddPortfolioWithUploadImageModal(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div id={"add-bulk-address"} className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[940px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start align-middle shadow-110 transition-all">
                                <div className="modal-header mb-2">
                                    <h2 className="font-semibold text-xl text-center text-blue-950">Add a Portfolio</h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setAddPortfolioWithUploadImageModal(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body text-blue-950 text-[15px] ">
                                    <p className='text-center my-3 mb-5'>Who are you adding the Portfolio for?</p>
                                    <div className="">
                                        <h5 className='text-[15px] text-blue-950'>Concerned booking</h5>
                                        <ChooseBookingCard />
                                    </div>
                                    <Divider className="my-5" />
                                    <RowWrap className="flex flex-col md:flex-row gap-x-5 gap-y-0 mt-5 justify-start items-start" >
                                        <DropZone labelClassName="p-2 min-h-[164px] w-[300px] bg-light-950 py-5"
                                            heading={<LabelWrap
                                                labelClassName="!mb-2"
                                                label={<>Upload pictures <span className="text-black/40"> (Max 4)</span></>}
                                            />}
                                            labelTitleClass="mt-1.5 inline" preBr
                                            icon={<DragandDropIcon className="w-10 h-10 text-primary1" />}
                                        />
                                        <div className='grid grid-cols-2 sm:grid-cols-4 md:grid-cols-2 lg:grid-cols-4 gap-4 min-h-[164px] mt-7'>

                                            <CustomTabs tabGroupClass="flex flex-col-reverse" tabListClass="border-[1.3px] border-primary1 mb-0 mt-1.5" tabButtonClass="!py-0 !text-sm !font-500" tabsData={[
                                                {
                                                    id: "1",
                                                    title: "After",
                                                    content: <div className='w-full max-h-[136px] max-w-[156px] rounded-lg overflow-hidden relative'>
                                                        <button className='bg-primary1 absolute top-2 end-2 p-1 w-6 h-6 rounded-md flex justify-center items-center'><CloseIcon className="w-4 h-4 text-white" /></button>
                                                        <img src="../../../../../../assets/images/user1.jpg" className='h-full w-full object-cover' alt="" />
                                                    </div>
                                                },
                                                {
                                                    id: "2",
                                                    title: "Before",
                                                    content: <div className='w-full max-h-[136px] max-w-[156px] rounded-lg overflow-hidden relative'>
                                                        <button className='bg-primary1 absolute top-2 end-2 p-1 w-6 h-6 rounded-md flex justify-center items-center'><CloseIcon className="w-4 h-4 text-white" /></button>
                                                        <img src="../../../../../../assets/images/user1.jpg" className='h-full w-full object-cover' alt="" />
                                                    </div>
                                                }
                                            ]} />
                                            <CustomTabs tabGroupClass="flex flex-col-reverse" tabListClass="border-[1.3px] border-primary1 mb-0 mt-1.5" tabButtonClass="!py-0 !text-sm !font-500" tabsData={[
                                                {
                                                    id: "1",
                                                    title: "After",
                                                    content: <div className='w-full max-h-[136px] max-w-[156px] rounded-lg overflow-hidden relative'>
                                                        <button className='bg-primary1 absolute top-2 end-2 p-1 w-6 h-6 rounded-md flex justify-center items-center'><CloseIcon className="w-4 h-4 text-white" /></button>
                                                        <img src="../../../../../../assets/images/user1.jpg" className='h-full w-full object-cover' alt="" />
                                                    </div>
                                                },
                                                {
                                                    id: "2",
                                                    title: "Before",
                                                    content: <div className='w-full max-h-[136px] max-w-[156px] rounded-lg overflow-hidden relative'>
                                                        <button className='bg-primary1 absolute top-2 end-2 p-1 w-6 h-6 rounded-md flex justify-center items-center'><CloseIcon className="w-4 h-4 text-white" /></button>
                                                        <img src="../../../../../../assets/images/user1.jpg" className='h-full w-full object-cover' alt="" />
                                                    </div>
                                                }
                                            ]} />

                                        </div>
                                    </RowWrap>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default AddPortfolioWithUploadImageModal