import React from 'react'
import { Children } from 'react'
import { cn } from '../../../lib/utils'

const Card = ({children, className, ...props}) => {
  return (
    <div className={cn("card bg-white border-2 border-success-100 shadow-100 rounded-[10px] p-3 sm:p-5", className)} {...props}>{children}</div>
  )
}

export default Card