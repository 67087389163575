import React from 'react';
import Slider from "react-slick"
import ReviewCard from './ReviewCard';
import LeftSmallArrowIcon from '../icons/LeftSmallArrowIcon';
import RightSmallArrowIcon from '../icons/RightSmallArrowIcon';
import CommonSliderWrapper from './CommonSliderWrapper';
import { cn } from '../../../../lib/utils';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH, PATH_CUSTOMER } from '../../../routes/path';
import { authStore } from '../../../contexts/AuthProvider';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <div onClick={onClick} className='absolute top-1/2 -translate-y-1/2 end-0 bg-primary1 rounded-full h-[22px] w-[22px] flex items-center justify-center cursor-pointer'><RightSmallArrowIcon className="w-3.5 h-3.5 text-white" /></div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div onClick={onClick} className='absolute top-1/2 z-10 -translate-y-1/2 start-1 bg-primary1 rounded-full h-[22px] w-[22px] flex items-center justify-center cursor-pointer'><LeftSmallArrowIcon className="w-3.5 h-3.5 text-white" /></div>
    );
}

const ReviewDetails = ({ salonData, colored, rating, groupClassName, dataList, headerGroupClass, headerLeftPartClass, headerRightPartClass, genderIconClass, headerLeftTextClass, headerLeftTextClassName, headerReviewTextClass, ratingWrapperClass, ratingStartIconClass, ratingGroupClass, reviewBodyClassName, reviewBodyTextMainClass, reviewBodyTextClass, reviewMoodIconClass, reviewMoodMainClass, reviewMoodTextClass, reviewMoodTextMainClass, item, centerMode, defaultArrows, autoPlaySlide, arrowButton, arrowIconClass, arrowBtnGroupClassName, slidesToShow, xsSlidesToShow, smSlidesToShow, mdSlidesToShow, lgSlidesToShow, xlSlidesToShow, xxlSlidesToShow, xxxlSlidesToShow, SliderClassName, csClassName, nextArrowClassName, prevArrowClassName, infinite, ref, data, navigateUrl, handelClickEvent, ...props }) => {
    const navigate = useNavigate();
    const { isAuthenticated,profileType } = authStore((state) => state)
    return (
        // <Slider {...shopSliderMain} className='slider_flex gap_14 h-full'>
        <CommonSliderWrapper
            ref={ref}
            centerMode={false}
            slidesToShow={1}
            xsSlidesToShow={1}
            smSlidesToShow={2}
            infinite={false}
        >
            {
                [...Array(item)].map((data, index) =>
                    <div className={cn("slider_item", handelClickEvent === false ? "" : "cursor-pointer")}
                        onClick={() => handelClickEvent === false ? "" : navigate((isAuthenticated && profileType == "") ? PATH_CUSTOMER.findSalonProfile : PATH_AUTH.findSalonProfile, {
                            state: { id: data?.salon_id || data?.id || salonData.id, data: salonData || data }
                        })}>
                        <ReviewCard
                            rating={rating}
                            groupClassName={cn(`${colored && "bg-light-800"}`, groupClassName)}
                            dataList={dataList}
                            headerGroupClass={headerGroupClass}
                            headerLeftPartClass={headerLeftPartClass}
                            headerRightPartClass={headerRightPartClass}
                            genderIconClass={genderIconClass}
                            headerLeftTextClassName={headerLeftTextClassName}
                            headerLeftTextClass={headerLeftTextClass}
                            headerReviewTextClass={headerReviewTextClass}
                            ratingWrapperClass={ratingWrapperClass}
                            ratingStartIconClass={ratingStartIconClass}
                            ratingGroupClass={ratingGroupClass}
                            reviewBodyClassName={reviewBodyClassName}
                            reviewBodyTextMainClass={reviewBodyTextMainClass}
                            reviewBodyTextClass={reviewBodyTextClass}
                            reviewMoodIconClass={reviewMoodIconClass}
                            reviewMoodMainClass={reviewMoodMainClass}
                            reviewMoodTextClass={reviewMoodTextClass}
                            reviewMoodTextMainClass={reviewMoodTextMainClass} />
                    </div>
                )
            }
        </CommonSliderWrapper>
        // </Slider>
    );
}

export default ReviewDetails;
