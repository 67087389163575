import React from 'react';
import { withTranslation } from 'react-i18next';
import Divider from '../../../../uiComponents/Divider';
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary';
import Badge from '../../../../uiComponents/common/Badge';
import Rating from '../../../../uiComponents/common/Rating';
import RatingList from '../../../../uiComponents/common/RatingList';
import FeMaleIcon from '../../../../uiComponents/icons/FeMaleIcon';
import LocationPin3 from '../../../../uiComponents/icons/LocationPin3';
import PencilIcon from '../../../../uiComponents/icons/PencilIcon';
import TrashIcon from '../../../../uiComponents/icons/TrashIcon';
import CommonSliderWrapper from '../../../../uiComponents/common/CommonSliderWrapper';
import ShopIcon from '../../../../uiComponents/icons/ShopIcon';
import MaleIcon from '../../../../uiComponents/icons/MaleIcon';
import BabyBoyIcon from '../../../../uiComponents/icons/BabyBoyIcon';
import BabyGirlIcon from '../../../../uiComponents/icons/BabyGirlIcon';
import { enqueueSnackbar } from 'notistack';
import Http from '../../../../../../Http';
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { PATH_WORKER } from '../../../../../routes/path';

const PortfolioCard = ({ t, colored, PortfolioData, hasActionBtns = true, workerData, ...props }) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();


    const handleDeletePortFolio = async (id) => {
        const response = await Http.del(PROFILE_COMPLETE_URL.deletePortfolio.replace(':id', id));
        if (response?.data?.success) {
            enqueueSnackbar(response?.data?.message, {
                variant: "success",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
            queryClient.invalidateQueries(["userPortfolioAllData"]);

        } else {
            enqueueSnackbar(response?.data?.message, {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
        }
    }

    const handleEditPrtfolio = (id) => {
        navigate(PATH_WORKER.addPortfolio, { state: { id: id, edit: true } })
    }


    return (
        <div className={`border-[1.5px] border-success-100 rounded-xl p-2 sm:p-4 sm:py-2.5 flex flex-col gap-2 w-full ${colored && "bg-light-800"}`}>
            {/* <div className='!grid grid-cols-1 gap-3'> */}
            <CommonSliderWrapper SliderClassName="!mx-0 [&_.slick-track]:!gap-1 sm:[&_.slick-track]:!gap-2.5" slidesToShow={2} nextArrowClassName="h-6 w-6 sm:h-8 sm:w-8 -end-2 sm:-end-3 top-[55%]" prevArrowClassName="h-6 w-6 sm:h-8 sm:w-8 -start-1 sm:-start-2 top-[55%]" infinite={false} >
                {
                    PortfolioData?.images?.map((item, ind) => (
                        <div className='flex flex-col gap-2 justify-center items-center w-full'>
                            <div className="flex items-center justify-center mb-1.5">{
                                item?.type == 0 ?
                                    <Badge text={t("form_input_label.after")} groupClassName={"mb-0 w-full max-w-[94px] w-full gap-0 font-medium text-sm py-0 border-success-850 justify-center px-6 sm:px-6"} />
                                    :
                                    <Badge text={t("form_input_label.before")} groupClassName={"mb-0 w-full max-w-[94px] w-full gap-0 font-medium text-sm py-0 border-success-850 justify-center px-5 sm:px-5 bg-white"} />
                            }
                            </div>
                            <div className='img_wrapper rounded-lg  w-full object-cover object-center overflow-hidden h-[205px]'>
                                <img src={item?.image?.file} alt="" className='img-fluid w-full h-full' />
                            </div>
                        </div>
                    ))
                }
            </CommonSliderWrapper>
            <div className='border-[1.3px] border-success-100 rounded-lg  px-3 py-1 w-full bg-white'>
                <div className='w-full flex justify-between items-center'>
                    <h5 className='flex items-center justify-center gap-1 text-sm text-blue-950 font-medium'>
                        {PortfolioData?.gender == 1 ? <MaleIcon className=" h-5 w-5 text-[#3BDFFD]" /> : <></>}
                        {PortfolioData?.gender == 2 ? <FeMaleIcon className="h-5 w-5 text-[#FE7AB6]" /> : <></>}
                        {PortfolioData?.gender == 4 ? <BabyBoyIcon className="h-5 w-5 text-[#3BDFFD]" /> : <></>}
                        {PortfolioData?.gender == 3 ? <BabyGirlIcon className="h-5 w-5 text-[#FE7AB6]" /> : <></>}
                        {PortfolioData?.booking_date}  </h5>
                    <h5 className='flex items-center justify-center gap-1 text-sm text-blue-950 font-medium'>
                        {PortfolioData?.service_location == 1 ?
                            <><ShopIcon className="h-5 w-5" /> {t("comman_text.salon")}</>
                            :
                            <><LocationPin3 className="h-5 w-5" /> {t("comman_text.on_the_go")}</>}

                    </h5>
                </div>
                <Divider className={"w-full my-1"} />
                <div className='text-center'>
                    <p className='text-sm font-medium text-blue-950'>{
                        PortfolioData?.work_done_at == 1 ?
                            PortfolioData?.services_title :
                            PortfolioData?.services?.map(service => service.title).join(' - ')
                    } </p>
                </div>
                <Divider className={"w-full my-1.5"} />
                <div className='w-full flex flex-col justify-center items-center'>
                    {/* ------------------------- for review ----------------------- */}
                    {/* <Badge text={"Review"} groupClassName={"py-0 mb-0 text-sm px-2 sm:px-2"} />
                    <p className='w-full min-h-[68px] flex items-center justify-center pt-1 sm:pt-2 text-sm text-center text-blue-950'>Awesome result, very happy.</p> */}
                    {/* ------------------------- for Description ----------------------- */}
                    <Badge text={t("comman_text.description")} groupClassName={"py-0 mb-0 text-sm px-2 sm:px-2 font-medium"} />
                    <p className='w-full min-h-[68px] flex items-center justify-center pt-1 sm:pt-2 text-sm text-center text-blue-950'>{PortfolioData?.description}</p>
                </div>
            </div>
            {/* need to resolve issue */}
            <div className='border-[1.3px] border-success-100 rounded-lg text-center flex px-3 py-1 items-center bg-white'>
                <div className='flex-shrink-0 h-full flex justify-center items-center border-e border-success-100 pe-2 xsm:pe-5 '>
                    <h2 className='text-blue-950 font-medium leading-none'>Worker</h2>
                </div>
                <div className='flex-grow w-full flex justify-center items-center gap-x-3'>
                    <div className='h-9 w-9 shrink-0 overflow-hidden rounded-full object-cover object-center flex justify-center bg-white items-center'>
                        <img src={workerData ? workerData?.provider?.profile : PortfolioData?.provider?.profile} className='h-full w-full object-cover' alt="" />
                    </div>
                    <h5 className='text-sm text-blue-950 font-medium capitalize'>{workerData ? workerData?.provider?.first_name : PortfolioData?.provider?.first_name} {workerData ? workerData?.provider?.last_name?.charAt(0) : PortfolioData?.provider?.last_name?.charAt(0)}.</h5>
                </div>
            </div>
            {
                PortfolioData?.work_done_at == 2 ? <Rating readOnly selected={4} rate={4.5} wrapperClass="flex-row gap-1.5 py-0.5 rounded-lg bg-white" textClassName="mt-0 leading-[16px]" starClass={"w-5 h-5"} showPeopleCount={false} />
                    : <></>
            }
            {
                PortfolioData?.work_done_at == 2 ?
                    <RatingList groupClassName="px-3 py-1 grid-cols-2 xsm:grid-cols-3 sm:grid-cols-2" iconClassName={"w-3.5 h-3.5"} textClassName="text-[13px] leading-[1.4]" ratingNumberClassName={"min-w-[20px]"} />
                    : <></>
            }
            {
                hasActionBtns && <div className='flex w-full justify-end items-end gap-2'>
                    <ButtonPrimary size={"sm"} className={"p-1"} icon={<PencilIcon className="h-[18px] w-[18px]" type="button" onClick={() => handleEditPrtfolio(PortfolioData?.id)} />} />
                    <ButtonPrimary size={"sm"} className={"p-1"} icon={<TrashIcon className="h-[18px] w-[18px]" type="button" onClick={() => handleDeletePortFolio(PortfolioData?.id)} />} />
                </div>
            }
        </div >
    )
}

export default withTranslation()(PortfolioCard)