import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next';
import Divider from '../Divider';
import StarIcon from '../icons/StarIcon';
import dayjs from "dayjs"
import RowWrap from '../RowWrap';
import InputCheckbox from '../inputs/InputCheckbox';
import { cn } from '../../../../lib/utils';
import Badge from './Badge';
import Badge2 from './Badge2';
import { buttonVariance } from '../buttons/ButtonPrimary';
import RatingList from './RatingList';



const ChooseBookingCard = ({ t, wrapperClassName, id, showCheckBox, selectedRows, setSelectedRows, ...props }) => {

    const currentDate = dayjs();
    const formattedDate = currentDate.format("DD/MM/YYYY");
    const formattedTime = currentDate.format('HH:mm');




    const handleRowSelect = () => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter(_id => _id !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    };



    return (
        <>

            {/* ------------------------------large screen------------------------------------------ */}
            <div className={cn(`w-full gap-2.5 hidden lg:flex`, wrapperClassName)}>
                <div className='border-[1.5px] border-success-100 flex flex-col justify-center items-center rounded-xl flex-shrink-0 min-w-[100px] min-h-[140px] h-auto'>
                    <div className='flex-grow border-b-[1.5px] border-success-100 w-full flex flex-col gap-2 justify-center items-center h-full text-blue-950 text-[15px]'>
                        <span>{formattedDate}</span>
                        <span>{formattedTime}</span>
                    </div>
                    <div className='flex items-center justify-center gap-1 py-1 flex-shrink-0 text-blue-950'>
                        <StarIcon className='h-4 w-4 lg:h-5 lg:w-5 text-success-800' />
                        <span className='text-xs lg:text-sm !leading-[20px] -mb-[3px]'>4.5/5</span>
                    </div>
                </div>
                <div className='border-[1.5px] border-success-100 flex flex-col justify-center items-center rounded-xl flex-grow'>
                    <ul className='flex-grow border-b-[1.5px] border-success-100 w-full h-full flex py-2 px-3.5'>
                        <li className='min-w-[205px] flex gap-1 lg:gap-3 items-center pe-2 border-e-[1.5px] last:border-e-0 border-success-100 w-full  '>
                            <div className='h-[80px] w-[80px] overflow-hidden flex-shrink-0 rounded-full bg-white'>
                                <img src="../../../../../../assets/images/user6.jpg" className='h-full w-full object-cover object-center' alt="" />
                            </div>
                            <div className='flex flex-col justify-center flex-grow items-center text-center gap-1 text-[15px] text-blue-950'>
                                <p>Mark L.</p>
                                <p>Man</p>
                                <p>25 Years</p>
                            </div>
                        </li>
                        <li className='min-w-[205px] px-2 h-full flex flex-col justify-center items-center border-e-[1.5px] last:border-e-0 border-success-100  w-full'>
                            <h6 className='text-blue-950/50 text-[15px] relative border-b px-2 border-b-success-100 pb-1.5'>{t("comman_text.services")}</h6>
                            <div className='flex flex-col gap-0.5 justify-center items-center h-full text-blue-950 text-[15px]'>
                                <p>Haircut -  Permanemt</p>
                                <p>Color - Styling</p>
                            </div>``
                        </li>
                        <li className='min-w-[125px] px-2 h-full flex flex-col justify-center items-center border-e-[1.5px] last:border-e-0 border-success-100'>
                            <h6 className='text-blue-950/50 text-[15px] relative border-b px-2 border-b-success-100 pb-1.5'>Price</h6>
                            <div className='flex flex-col gap-0.5 justify-center items-center h-full text-blue-950 text-[15px]'>
                                <p className='w-full'>500 Dkk.</p>
                            </div>
                        </li>
                        <li className='min-w-[165px] px-2 h-full flex flex-col justify-center items-center border-e-[1.5px] last:border-e-0 border-success-100 w-full'>
                            <h6 className='text-blue-950/50 text-[15px] relative border-b px-2 border-b-success-100 pb-1.5'>{t("check_box_label.worker")}</h6>
                            <div className='flex gap-2 justify-center items-center h-full w-full'>
                                <div className='h-[40px] w-[40px] shrink-0 overflow-hidden rounded-full object-cover object-center flex justify-center bg-white items-center'>
                                    <img src="../../../../../../assets/images/user4.jpg" className='h-full w-full object-cover' alt="" />
                                </div>
                                <div className='text-[15px] text-blue-950'>
                                    <p>Michael J.</p>
                                </div>
                            </div>
                        </li>
                        {
                            showCheckBox &&
                            <li className='flex justify-center items-center gap-1 border-e-[1.5px] last:border-e-0 border-success-100 ps-3.5'>
                                <InputCheckbox
                                    id={id}
                                    size={"xxl"}
                                    className={"before:rounded-full"}
                                    value={id}
                                    checked={selectedRows.includes(id)}
                                    onChange={handleRowSelect}
                                />
                            </li>
                        }

                    </ul>
                    <RatingList textClassName="-mb-[3px]" groupClassName={"border-0 p-0 flex gap-y-2 py-1 h-auto w-full justify-around items-center"} itemClassName={"text-12 lg:text-sm text-blue-950 flex justify-center items-center gap-1 border-e-[1.5px] last:border-e-0 border-success-100  w-full"} ratingNumberClassName={"min-w-1"} iconClassName={"h-4 w-4 lg:h-5 lg:w-5"} />
                </div>
            </div>


            {/* ------------------------------small screen------------------------------------------ */}
            <div className={cn('w-full border-[1.5px] border-success-100 flex flex-col justify-center items-center rounded-xl flex-shrink-0 p-3 lg:hidden', wrapperClassName)}>
                <div className='w-full flex justify-between items-center'>
                    <div className='flex gap-2 justify-center items-center h-full'>
                        <div className='h-9 w-9 shrink-0 overflow-hidden rounded-full bg-white'>
                            <img src="../../../../../../assets/images/user6.jpg" className='h-full w-full object-cover object-center ' alt="" />
                        </div>
                        <div className='flex flex-col text-[15px] justify-center items-start text-center gap-[1px] text-blue-950'>
                            <p className='font-medium'>Mark L.
                                <span className='text-blue-950/50 text-12'>(25 Years)</span>
                            </p>
                            <p className='text-[13px] text-blue-950'>{formattedDate} {" "}{formattedTime}</p>
                        </div>
                    </div>
                    {
                        showCheckBox &&
                        <div className='flex flex-col justify-end items-end gap-1 border-e-[1.5px] last:border-e-0 border-success-100'>
                            <InputCheckbox
                                // size={"xl"}
                                id={`checkbox-${id}`}
                                className={"before:rounded-full peer-checked:before:!bg-success-800 peer-checked:before:!border-success-800"}
                                value={id}
                                checked={selectedRows.includes(id)}
                                onChange={handleRowSelect}
                            />

                            <div className='flex items-center justify-center gap-1 text-sm flex-shrink-0 text-blue-950'>
                                <span className='text-[13px]'>4.5/5</span>
                                <StarIcon className='h-4 w-4 text-success-800' />
                            </div>
                        </div>
                    }
                </div>
                <Divider className={"w-full border-b-[1.5px] my-2"} />
                <div className='w-full'>
                    <div className='flex flex-col gap-0.5 justify-center items-center py-1 text-[15px] text-blue-950'>
                        <p>Haircut -  Permanemt</p>
                        <p>Color - Styling</p>
                    </div>
                    <Divider className={"my-2"} />
                    <div className='flex justify-around items-center'>
                        <div className='flex gap-2 items-center h-full w-full'>
                            <div className='h-8 w-8 shrink-0 overflow-hidden rounded-full bg-white'>
                                <img src="../../../../../../assets/images/user4.jpg" className='h-full w-full object-cover object-center' alt="" />
                            </div>
                            <p className='text-[15px] font-medium leading-[1.3] text-blue-950'>Michael J. <span className='text-blue-950/50 text-12 block'>(Worker)</span></p>
                        </div>
                        <div className={cn(buttonVariance({ size: "sm" }), 'text-sm flex-shrink-0 px-2.5 !leading-[1]')}>500 Dkk.</div>
                    </div>
                </div>
                <Divider className={"w-full border-b-[1.5px] my-2"} />
                <RatingList groupClassName={"border-0 p-0 grid-cols-2 w-full"} itemClassName="gap-1" ratingNumberClassName="min-w-[18px]" textClassName={"text-xs gap-1"} iconClassName={"w-3.5 h-3.5"} />
            </div>
        </>
    )
}

export default withTranslation()(ChooseBookingCard)