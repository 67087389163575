import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import InputCheckbox from "../inputs/InputCheckbox";
import { cn } from "../../../../lib/utils";
import _ from "lodash";

const CheckboxList = ({
  selcetAll = true,
  selectAllId,
  // setServiceChoose,
  serviceType,
  groupClassName,
  inputGroupClassName,
  inputLableClassName,
  itemClassName,
  subCategoryData,
  categoryChoose,
  setCategoryChoose,
  serviceChoose,
  setserviceTypeIds,
  setCategoryIds,
  checkedList,
  setCheckedList,
  ...props
}) => {
  // const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const handelChecboxOnChange = (event) => {
    let list = [...checkedList];
    const value = parseInt(event.target.value);

    if (event.target.checked) {
      list.push(value);
    } else {
      list = list.filter((id) => id !== value);
    }

    setCheckedList(list);
    setCategoryChoose(list);
    setCategoryIds(list);
    setserviceTypeIds(list);

    if (list.length !== serviceType?.length) {
      setCheckAll(false);
    }

    setCheckAll(list.length === serviceType?.length);
  };

  const handelAllChecboxOnChange = (e) => {
    const allCheck = _.map(serviceType, "id");
    if (!checkAll) {
      setCheckAll(true);
      setCheckedList(allCheck);
      setCategoryChoose(allCheck);
      setserviceTypeIds(serviceChoose);
      setCategoryIds(allCheck);
    } else {
      setCheckAll(false);
      setCheckedList([]);
      setCategoryChoose([]);
      setserviceTypeIds([]);
      setCategoryIds([]);
    }
  };

  useEffect(() => {
    const allCheck = _.map(serviceType, "id");
    if (categoryChoose?.length === allCheck?.length) {
      setCheckAll(true);
    } else if (categoryChoose?.length === 0) {
      setCheckAll(false);
    }
  }, [categoryChoose, serviceType]);

  return (
    <div
      className={cn(
        "flex items-center justify-start gap-2 flex-wrap ",
        groupClassName
      )}
      {...props}
    >
      {subCategoryData && subCategoryData.length > 0 ? (
        <>
          <InputCheckbox
            className={cn(
              "before:hidden after:hidden border-[1.3px] text-[15px] peer-checked:text-white peer-checked:border-success-950 peer-checked:bg-success-950 border-success-100 py-0.5 px-4 rounded-full",
              inputLableClassName
            )}
            // className={checkAll ? "all_select_checkbox" : "all_select_unchecked"}
            checked={checkAll}
            id={selectAllId}
            text={"All"}
            onChange={handelAllChecboxOnChange}
            groupClassName={inputGroupClassName}
          />
          <div
            className={cn("flex items-center justify-start gap-2 flex-wrap", itemClassName)}
            // value={checkedList}
            value={categoryChoose}
            onChange={handelChecboxOnChange}
          >
            {subCategoryData.map((data, ind) => (
              <InputCheckbox
                id={data?.id + "_" + ind}
                value={data?.id}
                key={ind}
                text={data?.name}
                groupClassName={inputGroupClassName}
                checked={checkedList?.includes(data?.id)}
                className={cn(
                  "before:hidden after:hidden border-[1.3px] text-[15px] peer-checked:text-white peer-checked:border-success-950 peer-checked:bg-success-950 border-success-100 py-0.5 px-4 rounded-full",
                  inputLableClassName
                )}
              />
            ))}
          </div>
        </>
      ) : (
        <div className="text-gray-500">Category not found</div>
      )}
    </div>
  );
};

export default withTranslation()(CheckboxList);