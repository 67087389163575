import React, { useEffect, useState } from 'react'
import QuickTipModal from '../../../modals/QuickTipModal';
import SyncYourCalendarModal from '../../../modals/SyncYourCalendarModal';
import ImportCalendarModal from '../../../modals/ImportCalendarModal';
import { useLocation } from 'react-router-dom';
import Http from '../../../../../Http';
import { PROFILE_COMPLETE_URL } from '../../../../api/axios';
import MenuTabs from '../../../uiComponents/tabs/MenuTabs';
import BusinessProfile from '../salonProfile/component/BusinessProfile';
import Availability from '../salonProfile/component/Availability';
import Services from '../salonProfile/component/Services';
import ShopIcon from '../../../uiComponents/icons/ShopIcon';
import AddWorker from '../salonProfile/component/AddWorker';
import Finance from '../salonProfile/component/Finance';
import { authStore } from '../../../../contexts/AuthProvider';
import ButtonPrimary from '../../../uiComponents/buttons/ButtonPrimary';
import Step from '../../../uiComponents/Step';
import LeftSmallArrowIcon from '../../../uiComponents/icons/LeftSmallArrowIcon';
import { isNull } from 'lodash';
import { useQuery } from 'react-query';
import { withTranslation } from 'react-i18next';

const AddSalon = (props) => {

    const { state } = useLocation();
    const salonId = authStore((state) => state.salonDataId);
    const salopStep = authStore((state) => state.salonProfileStep);
    const updateSaloneDataId = authStore((state) => state.updateSaloneDataId);
    const is_profile_complete = authStore((state) => state.is_profile_complete);

    const { t } = props
    const [step, setStep] = useState(salopStep == 0 || !salopStep ? 1 : salopStep && state ? state.selectedTab : salopStep);
    const [current, setCurrent] = useState(salopStep == 0 || !salopStep ? 1 : salopStep && state ? state.selectedTab : salopStep);

    const [salonAvailability, setSalonAvailability] = useState([]);
    const [onTheGoAvailability, setOnTheGoAvailability] = useState([]);
    const [salonUnAvailability, setSalonUnAvailability] = useState([]);


    const [quickTipModalOpen, setQuickTipModalOpen] = useState(false)
    const [syncYourCalendarModalOpen, setSyncYourCalendarModalOpen] = useState(false)
    const [importCalendarModalOpen, setImportCalendarModalOpen] = useState(false)

    const { data: userProfileData, refetch: userProfile } = useQuery(
        "userProfileSetting2",
        async () => {
          if (!salonId) {
            setCurrent(1)
            return
          };
          const response = await Http.post(PROFILE_COMPLETE_URL.getSalon, {
            salonId: atob(salonId),
          }).then((res) => res.data);
          if (response && response?.data?.availability) {
            if (response?.data?.availability?.salon?.length) {
              setSalonAvailability(response.data.availability.salon);
            }
            if (response?.data?.availability?.onthego?.length) {
              setOnTheGoAvailability(response.data.availability.onthego);
            }
    
            if (response?.data?.unavailability?.length) {
              response?.data?.unavailability.map((unavailability) => {
                unavailability.date = unavailability.holiday_date
                unavailability.salon = unavailability.service_location == 3 || unavailability.service_location == 1 ? true : false;
                unavailability.onTheGo = unavailability.service_location == 3 || unavailability.service_location == 2 ? true : false;
              });
              setSalonUnAvailability(response?.data?.unavailability);
            }
          }
          setStep(response?.data.step + 1);
          // setCurrent(response?.data.is_complete == 0 ? response.data.step + 1 : 1);
          return response;
        },
        { refetchOnWindowFocus: false }
      );
      const handelNextClick = () => {
        setCurrent(current + 1);
        setStep(step + 1);
        // setShopListArray([1]);
      };

    //   useEffect(() => {
    //     let selectedTab = location?.state?.selectedTab;
    //     if (selectedTab && !isNull(selectedTab)) {
    //       setCurrent(selectedTab);
    //     }
    //   }, [location]);

    //   useEffect(() => {
    //     setBusinessName(userProfileData?.data?.business_name)
    //   }, [userProfileData])

    const AuthTabsRegister = [
        {
            id: "1",
            icon: <ShopIcon className="w-6 h-6" />,
            title: t('comman_text.profile')
        },
        {
            id: "2",
            icon: <ShopIcon className="w-6 h-6" />,
            title: 'Salon Availability',
            disabled: is_profile_complete == 0 && salopStep < 2
        },
        {
            id: "3",
            icon: <ShopIcon className="w-6 h-6" />,
            title: 'Services',
            disabled: is_profile_complete == 0 && salopStep < 3
        },
        {
            id: "4",
            icon: <ShopIcon className="w-6 h-6" />,
            title: 'Workers',
            disabled: is_profile_complete == 0 && salopStep < 4
        },
        {
            id: "5",
            icon: <ShopIcon className="w-6 h-6" />,
            title: 'finance',
            disabled: is_profile_complete == 0 && salopStep < 5
        }
    ]
    


    return (

        <div className="main-content-wrap">
            <div className="page-title text-center">
                <h1 className="text-[40px] font-bold leading-[1.2] text-white mb-2 flex items-center gap-3 justify-center">{step === 1 ? <></> : <ButtonPrimary className="bg-transparent p-0 border-0 hover:bg-transparent" onClick={() => setStep(step - 1)} size="sm" icon={<LeftSmallArrowIcon className="w-6 h-6" />} />} {AuthTabsRegister[step - 1]?.icon}{AuthTabsRegister[step - 1]?.title}</h1>
            </div>
            <div className="mb-5">
                <Step data={AuthTabsRegister} step={step} setStep={setStep} />
            </div>
            <MenuTabs tabsData={AuthTabsRegister} isClickEvent={true} step={step} setStep={setStep} />
            {step === 1 && <BusinessProfile
                // step={step} setStep={setStep} 
                stepsNextClick={handelNextClick}
                userProfileData={userProfileData}
                salonAvailability={salonAvailability}
                onTheGoAvailability={onTheGoAvailability}
                salonUnAvailability={salonUnAvailability}
                refetch={userProfile}
                tabStep={current}
            />}
            {step === 2 && <Availability
                stepsNextClick={handelNextClick}
                salonAvailabilityData={salonAvailability}
                onTheGoAvailabilityData={onTheGoAvailability}
                salonUnAvailabilityData={salonUnAvailability}
                refetch={userProfile}
            />}
            {step === 3 && <Services
                stepsNextClick={handelNextClick}
                userProfileData={userProfileData}
                refetch={userProfile}
                profile_setup={true}

            />}
            {step === 4 && <AddWorker stepsNextClick={handelNextClick} />}
            {step === 5 && <Finance step={step} setStep={setStep} />}

            <QuickTipModal quickTipModal={quickTipModalOpen} setQuickTipModal={setQuickTipModalOpen} />
            <SyncYourCalendarModal syncYourCalendarModal={syncYourCalendarModalOpen} setSyncYourCalendarModal={setSyncYourCalendarModalOpen} />
            <ImportCalendarModal importCalendarModal={importCalendarModalOpen} setImportCalendarModal={setImportCalendarModalOpen} />
        </div>

    )
}

export default withTranslation()(AddSalon)