
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import Card from '../../../../uiComponents/Card';
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary';
import EducationListCard from './EducationListCard';
import PortfolioListCard from './PortfolioListCard';
import { useNavigate } from 'react-router-dom';
import { authStore } from '../../../../../contexts/AuthProvider';
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios';
import Http from '../../../../../../Http';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { PATH_WORKER } from '../../../../../routes/path';

const EducationExpertise = ({ t, error, stepsNextClick, ...props }) => {

    const salonDataId = authStore((state) => state.salonDataId);
    const navigate = useNavigate();
    const become_worker_profile = authStore((state) => state.become_worker_profile);
    const BecomeWorkerCompleteStep = authStore((state) => state.BecomeWorkerCompleteStep);
    const becomeWorkerStep = authStore((state) => state.becomeWorkerStep);
    const updateBecomeWorkerStep = authStore((state) => state.updateBecomeWorkerStep);
    const [count, setCount] = useState(0);
    const queryClient = useQueryClient()

    const { mutateAsync: updateStep } = useMutation(
        async () => {
            try {
                const { data } = await Http.post(PROFILE_COMPLETE_URL.updateEducationStep, {
                    salon_id: salonDataId
                });
                if (data) {
                    if (become_worker_profile == 0 && salonDataId) {
                        const maxStep = Math.max(...becomeWorkerStep);
                        let missingStep = null;
                        for (let i = 1; i <= maxStep; i++) {
                            if (!becomeWorkerStep.includes(i)) {
                                missingStep = i;
                                break;
                            }
                        }
                        if (missingStep != null) {
                            const updatedArray = [...new Set([...becomeWorkerStep, missingStep == 5 ? 5 : missingStep])];
                            updateBecomeWorkerStep(updatedArray);
                            stepsNextClick()
                            if (data.data.is_complete == 1) {
                                BecomeWorkerCompleteStep(1)
                                queryClient.invalidateQueries("mySalonList")
                                navigate(PATH_WORKER.dashboard)
                            }
                        } else {
                            const updatedArray = [...new Set([...becomeWorkerStep, 5])];
                            updateBecomeWorkerStep(updatedArray);
                            stepsNextClick()

                        }
                    }
                    if (becomeWorkerStep.includes(4) && !salonDataId) {
                        const updatedArray = [...new Set([...becomeWorkerStep, 5])];
                        updateBecomeWorkerStep(updatedArray);
                        stepsNextClick()
                    }
                    enqueueSnackbar(data?.message, {
                        variant: "success",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            } catch (error) {
                const errorData = error?.response?.data?.errors;
                if (errorData) {
                    Object.values(errorData).forEach((value) => {
                        enqueueSnackbar(value, {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                } else {
                    enqueueSnackbar(error?.response?.data?.message, {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            }
        }
    );

    const updateProfileStep = () => {
        if (become_worker_profile == 0) {
            updateStep();
        }
    }

    return (

        <>
            <EducationListCard setCount={setCount} />
            <PortfolioListCard setCount={setCount} />
            {
                become_worker_profile == 0 ?
                    <Card className={"mt-2.5"}>
                        {count === 1 || becomeWorkerStep?.includes(5) ?
                            <ButtonPrimary variant={"outline"} className={"w-full font-extrabold text-xl"} size="sm" onClick={() => updateProfileStep()}>{t("button.continue")}</ButtonPrimary>
                            :
                            <ButtonPrimary variant={"outline"} className={"w-full font-extrabold text-xl"} size="sm" onClick={updateProfileStep}>{t("button.skip")}</ButtonPrimary>}
                    </Card>
                    : <></>
            }
        </>

    )
}

export default withTranslation()(EducationExpertise);