import { Dialog, Transition } from '@headlessui/react';
import { FormikProvider, useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import * as Yup from "yup";
import Http from '../../../Http';
import { PROFILE_COMPLETE_URL } from '../../api/axios';
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary';
import CloseIcon from '../uiComponents/icons/CloseIcon';
import LocationPin2 from '../uiComponents/icons/LocationPin2';
import InputWithIcon from '../uiComponents/inputs/InputWithIcon';



const RequestForNewOnTheGoCityModal = ({ t, modalOpen, setModalOpen, workerProfileData, salonDataId }) => {

  let cityRequestValidationSchema = Yup.object().shape({
    on_the_go_area: Yup.string()
      .required(t("validation_message.on_the_go_area_required")),
  });

  const { mutateAsync: workerCityRequest } = useMutation(
    async (datas) => {
      try {
        const { data } = await Http.post(PROFILE_COMPLETE_URL.workerCityRequest, datas);
        if (data) {
          setModalOpen(false);
          enqueueSnackbar(t('snackbar_message.on_the_go_area_is_requested'), {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
          resetForm();
        }
      } catch (error) {
        const errorData = error?.response?.data?.errors;
        if (errorData) {
          Object.values(errorData).forEach((value) => {
            enqueueSnackbar(value, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }

      }
    }
  );

  const handleClose = () => {
    setModalOpen(false);
    resetForm();
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      on_the_go_area: '', // Provide initial values for your form fields here
    },
    validationSchema: cityRequestValidationSchema,
    onSubmit: async (values) => {
      try {
        const on_the_go_area = values.on_the_go_area;
        const salon_id = salonDataId;
        const data = { on_the_go_area, salon_id }
        await workerCityRequest(data);
      } catch (error) {
        const errorData = error?.response?.data?.errors;
        if (errorData) {
          Object.values(errorData).forEach((value) => {
            enqueueSnackbar(value, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        } else {
          enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      }
    },
  });


  const {
    errors,
    handleSubmit,
    touched,
    getFieldProps,
    setFieldValue,
    values,
    resetForm
  } = formik;


  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Transition appear show={modalOpen} as={Fragment}>
          <Dialog as="div" className="relative z-[9999]" onClose={handleClose}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
              <form id={"add-bulk-address"} className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95">
                  <Dialog.Panel className="w-full max-w-[800px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                    <div className="modal-header pe-7 mb-6">
                      <h2 className="font-semibold text-xl text-center text-blue-950">Request For New On The Go City</h2>
                      <button
                        type={"button"}
                        aria-label="button"
                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                        onClick={handleClose}>
                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                      </button>
                    </div>
                    <div className="modal-body">
                      <InputWithIcon
                        require
                        size="sm"
                        labelClassName="text-[15px] font-medium mb-2"
                        labelIcon={<LocationPin2 className="w-5 h-5" />}
                        label={"On The Go area (City or Address)"}
                        {...getFieldProps("on_the_go_area")}
                        placeholder={"Paris"}
                        tooltipText="Lorem ipsum"
                        groupClassName={"w-full mb-4"}
                        errorType={Boolean(touched.on_the_go_area && errors.on_the_go_area) && "error"}
                        errorData={touched.on_the_go_area && errors.on_the_go_area}
                      />
                      <h5 className='text-center text-base text-blue-950 mb-4'>Your Request will be sent to the Salon owner, and as soon as it is accepted, it will appear here (in your Worker Profile)</h5>
                      <div className='w-full flex flex-col sm:flex-row justify-between gap-x-5 gap-y-3'>
                        <ButtonPrimary size="sm" type="button" onClick={handleClose} className="w-full bg-danger-950 hover:bg-danger-900 hover:border-danger-900 border-danger-950">Cancle</ButtonPrimary>
                        <ButtonPrimary size="sm" type="submit" className="w-full" >send Request</ButtonPrimary>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </form>
            </div>
          </Dialog>
        </Transition>
      </form>
    </FormikProvider>
  )
}

export default withTranslation()(RequestForNewOnTheGoCityModal);