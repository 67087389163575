import { Menu, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { cn } from '../../../lib/utils'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ShopLineIcon from '../../view/uiComponents/icons/line-icon/ShopLineIcon'
import BottomArrowIcon from '../../view/uiComponents/icons/BottomArrowIcon'
import { authStore } from '../../contexts/AuthProvider'
import { PATH_AUTH, PATH_CUSTOMER, PATH_PROVIDER, PATH_WORKER } from '../../routes/path'
import { enqueueSnackbar } from 'notistack'
import { InputLanguageSwitch } from '../../view/uiComponents/inputs/InputLanguageSwitch'
import LogoutIcon from '../../view/uiComponents/icons/LogoutIcon'
import MenuIcon from '../../view/uiComponents/icons/MenuIcon'
import { withTranslation } from 'react-i18next'
import LoginIcon from '../../view/uiComponents/icons/header-footer-icon/LoginIcon'
import RegisterIcon from '../../view/uiComponents/icons/header-footer-icon/RegisterIcon'

const UserLoginDropDownMenu = ({ t, data, dropdownItemClassName, className }) => {
    const authStores = authStore();
    const { user, isAuthenticated, profileType, is_profile_complete } = authStore((state) => state)
    const navigate = useNavigate();
    const location = useLocation();
    const logout = async () => {
        const handleLogout = async (logoutFn) => {
            const [success, error] = await logoutFn();
            enqueueSnackbar(success?.message || error?.message, {
                variant: success ? "success" : "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
            if (success) navigate(PATH_AUTH.login);
        };
        if (location.pathname.includes("/customer")) {
            await handleLogout(authStores.customerLogout);
        } else if (location.pathname.includes("/provider")) {
            await handleLogout(authStores.logout);
        } else {
            await handleLogout(authStores.logout ?? authStores.customerLogout);
        }
    };

    const dashboard =
        profileType == 2 || profileType == 3 ?
            is_profile_complete == 1 ? PATH_PROVIDER.dashboard : PATH_PROVIDER.salonProfile :
            profileType == 1 ?
                is_profile_complete == 1 ? PATH_WORKER.dashboard : PATH_WORKER.profile
                : PATH_CUSTOMER.profile

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="">
                    {   (isAuthenticated && (location.pathname === '/' || location.pathname === '/find-salon')) || !isAuthenticated ?
                            <MenuIcon className="h-7 w-7 text-white" aria-hidden="true" />
                            :
                            <span className="md:rounded-lg md:border-[1.5px] md:flex md:items-center md:gap-1 md:p-1.5 md:border-white md:px-2 md:py-1 md:h-11 text-sm font-medium text-white outline-0 max-w-[140px] leading-none">
                                <span className='w-9 h-9 border-[1.5px] md:border border-white rounded-full overflow-hidden text-white flex-shrink-0 block'>
                                    <img src={user?.profile ? user?.profile : require('../../../assets/images/user-image/user3.jpg')} className="object-cover object-center w-full h-full" alt="userImage" />
                                </span>
                                <span className='text-xs font-semibold leading-[1.1] flex-grow md:line-clamp-2 hidden'>{user?.first_name} {user?.last_name?.charAt(0)}.</span>
                                <BottomArrowIcon className="h-3.5 w-3.5 flex-shrink-0 hidden md:block" aria-hidden="true" />
                            </span>
                            // : <MenuIcon className="h-7 w-7 text-white" aria-hidden="true" />
                            }
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className={cn("absolute pt-3 pb-2 px-3 right-0 mt-6 w-[300px] origin-top-right rounded-2xl rounded-tr-none bg-white shadow-lg ring-1 ring-success-100 outline-0", className)}>
                    {
                        data?.map((item, index) => {
                            return (
                                <div className='mb-2' key={index}>
                                    <p className='text-light-500 text-sm ps-1'>{item?.Title}</p>
                                    <div className="px-4">
                                        {
                                            !isAuthenticated && index == 0 ?
                                                <>
                                                    <Menu.Item className={cn("border-b last:border-0 border-success-100", dropdownItemClassName)}>
                                                        {({ active }) => (
                                                            <Link to={"/login"} className={`${active ? 'bg-blue-500 text-white' : 'text-blue-350'} hover:rounded-md group flex w-full items-center px-2 py-2 text-base`}>
                                                                <LoginIcon className="mr-2 h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />
                                                                {t('comman_text.login')}
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item className={cn("border-b last:border-0 border-success-100", dropdownItemClassName)}>
                                                        {({ active }) => (
                                                            <Link to={"/register"} className={`${active ? 'bg-blue-500 text-white' : 'text-blue-350'} hover:rounded-md group flex w-full items-center px-2 py-2 text-base`}>
                                                                <RegisterIcon className="mr-2 h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />
                                                                {t('comman_text.register')}
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                </>
                                                :
                                                <></>
                                        }
                                        {
                                            index === 0 && (isAuthenticated && (location.pathname === '/' || location.pathname === '/find-salon')) ?
                                            // (location.pathname == '/' && isAuthenticated) || (location.pathname == '/find-salon' && isAuthenticated) && isAuthenticated && index == 0 ?
                                                <Menu.Item className={cn("border-b last:border-0 border-success-100", dropdownItemClassName)}>
                                                    {({ active }) => (
                                                        <Link to={dashboard} className={`${active ? 'bg-blue-500 text-white' : 'text-blue-350'} hover:rounded-md group flex w-full items-center px-2 py-2 text-base`}>
                                                            <LoginIcon className="mr-2 h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />
                                                            {t('comman_text.dashboard')}
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                                : <></>
                                        }
                                        {
                                            item?.list?.map((data, index) =>
                                                data.langswitch ?
                                                    <div className="text-center my-1">{data.langswitch}</div> :
                                                    <Menu.Item className={cn("border-b last:border-0 border-success-100", dropdownItemClassName)}>
                                                        {({ active }) => (
                                                            <Link to={data.path ? data.path : ""} className={`${active ? 'bg-blue-500 text-white' : 'text-blue-350'} hover:rounded-md group flex w-full items-center px-2 py-2 text-base`}>
                                                                {active ? data.icon : data.icon}
                                                                {t(data.text)}
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                            )
                                        }
                                        {
                                            isAuthenticated && data.length - 1 === index ?
                                                <Menu.Item className={cn("border-b last:border-0 border-success-100", dropdownItemClassName)}>
                                                    {({ active }) => (
                                                        <Link role='button' type='button' to={""} onClick={logout} className={`${active ? 'bg-blue-500 text-white' : 'text-blue-350'} hover:rounded-md group flex w-full items-center px-2 py-2 text-base`}>
                                                            <LogoutIcon className="mr-2 h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />
                                                            {t('comman_text.logout')}
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                                 :
                                                !isAuthenticated && data.length - 1 === index ?
                                                    <div className="text-center my-1"><InputLanguageSwitch /></div>
                                                    : <></>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default withTranslation()(UserLoginDropDownMenu)