import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import RowWrap from '../RowWrap'
import HappyIcon from '../icons/HappyIcon'
import RatingList from './RatingList'
import ReviewCard from './ReviewCard'
import image1 from '../../../../assets/images/user-image/user4.jpg'
import { cn } from '../../../../lib/utils'
import AlertIcon from '../icons/AlertIcon'
import ButtonPrimary from '../buttons/ButtonPrimary'
import ReportReviewModal from '../../modals/ReportReviewModal'





const ReviewCardFull = ({ t, colored, isDateShow = true, salonReviwes, dataList, groupClassName, largeRGroupClassName, showReportReviewBtn, ...props }) => {
    const [reportReviewModalOpen, setReportReviewModalOpen] = useState(false)

    return (
        <>
            {/* ----------------------------for large screen--------------------------- */}
            <div className={cn("hidden lg:grid grid-cols-12 gap-2.5", groupClassName)}>
                {
                    isDateShow && <div className={`relative col-span-1 flex flex-col items-center justify-center border-[1.3px] rounded-[10px] border-borderColor text-blue-950 text-[15px] ${colored && "bg-light-800"}`}>
                        <span>12/11</span>
                        <span>2022</span>
                        {/* ---------------------new then show------------------ */}
                        {/* <span className='absolute bottom-8'><AlertIcon className="w-5 h-5 sm:w-6 sm:h-6 md:w-8 md:h-8 text-success-950" /></span> */}
                    </div>
                }
                <div className={cn(`${isDateShow ? "col-span-11 " : "col-span-12"} grid grid-cols-9 gap-2.5 border-[1.3px] rounded-[10px] border-borderColor p-3.5 text-blue-950 ${colored && "bg-light-800"}`, largeRGroupClassName)}>
                    <RowWrap className="grid grid-cols-4 gap-2.5 col-span-3 border border-borderColor rounded-lg p-2 lg:[&>div:nth-child(3n-2)]:after:!-end-1.5 bg-white" sm={2} >
                        <div className="col-span-1 flex flex-col items-center justify-center gap-y-3.5 my-3 text-sm text-center">
                            <span>Man</span>
                            <span>26 Years</span>
                            <span>{t('comman_text.salon')}</span>
                        </div>
                        <div className="col-span-3 flex flex-col gap-y-3 justify-center items-center text-center text-sm">
                            <span>Haircut - Color - Haircut - Color-Haircut - Color - Haircut - Color</span>
                            <div className='border border-borderColor rounded-lg p-1.5 flex items-center gap-3.5'>
                                <img src={image1} alt="" className='w-[30px] h-[30px] rounded-full' />
                                <span>James L.</span>
                            </div>
                        </div>
                    </RowWrap>
                    <div className="col-span-4 border border-borderColor rounded-lg text-center flex items-center justify-center p-3 bg-white">
                        <p className='text-sm'>The treatment started with a head massage, which continued with a wash, and was ended with a Haircut.The treatment started with a head massage, which continued with a wash, and was ended with a Haircut.The treatment started with a head massage, which continued with a wash, and was ended with a Haircut.</p>
                    </div>
                    <div className="col-span-2 flex flex-col gap-1.5 items-center justify-center">
                        <span><HappyIcon className='h-[30px] w-[30px]' /></span>
                        <p className='bg-primary1 text-white text-center py-[2px] px-1.5 rounded-full text-sm min-w-[160px] font-semibold'>They spoiled me</p>
                        <p className='bg-primary1 text-white text-center py-[2px] px-1.5 rounded-full text-sm min-w-[160px] font-semibold'>Great advice & result</p>
                    </div>
                    <RatingList salonReviwes={salonReviwes} dataList={dataList} textClassName="-mb-[3px]" groupClassName={"bg-white col-span-9 border p-0 flex gap-y-2 py-1 h-auto w-full justify-around items-center rounded-lg"} itemClassName={"text-12 lg:text-sm text-blue-950 justify-center  gap-1 border-e-[1.5px] last:border-e-0 border-success-100  w-full"} ratingNumberClassName={"min-w-1"} iconClassName={"h-4 w-4 lg:h-5 lg:w-5"} />
                </div>
            </div>


            {/* ----------------------------for small screen--------------------------- */}
            <ReviewCard rating={salonReviwes} dataList={dataList} groupClassName={cn(`border-0 p-0 lg:hidden ${colored && "bg-light-800"}`, groupClassName)} />

            {
                showReportReviewBtn &&
                <ButtonPrimary onClick={() => setReportReviewModalOpen(true)} size={"sm"} variant={"outline"} className={"w-full mt-3"}>
                    Report Review
                </ButtonPrimary>
            }


            <ReportReviewModal
                modalOpen={reportReviewModalOpen}
                setModalOpen={setReportReviewModalOpen}
            />
        </>
    )
}

export default withTranslation()(ReviewCardFull)