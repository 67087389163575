import React from 'react'
import moment from "moment";
import { withTranslation } from 'react-i18next';
import { cn } from '../../../../lib/utils';

const SalonUnAvailabilityListCard = ({ data, t }) => {
    return (
        <ul className={cn("time-list-card !border border-success-100 bg-light-850 rounded-[5px] !px-4 !py-2 [&>li:last-child]:border-0")}>
            {
                data?.length ? data?.map((unAvailability) => {
                    const date = moment(unAvailability?.date);
                    return (
                        date.isSameOrAfter(moment(), 'day') &&
                        <li className={cn("py-1.5", data?.length - 1 ? "border-b border-success-100" : "")}>
                            <p className="text-[15px] font-normal text-blue-950 mb-0 flex items-center !gap-2 justify-between">
                                <span>Monday</span>
                                <span>{moment(unAvailability?.date).format("D MMMM YYYY")}</span>
                            </p>
                        </li>
                    )
                }) : <li><p className="text-[15px] leading-[18px] font-normal text-blue-950 mb-0 text-center">{t("comman_text.no_data_available")}</p></li>
            }
        </ul>
    )
}

export default withTranslation()(SalonUnAvailabilityListCard)

// {
//     salonUnAvailability?.length ?
//         salonUnAvailability?.map((unAvailability) => {
//             const date = moment(unAvailability?.date);
//             return date.isSameOrAfter(moment(), 'day') && (
//                 <li>
//                     <p className="fs-15 fw-400 text_black80 mb-0">
//                         {moment(unAvailability?.date).format("D MMMM YYYY")}
//                     </p>
//                 </li>)
//         }) : <li> {t("comman_text.no_data_available")} </li>
// }