import React from 'react'

const ImageIconSingle = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_825_9)">
                <path d="M20.2836 36.3922C15.4195 36.3922 10.5585 36.4049 5.69435 36.386C2.77271 36.3765 1.04672 34.6271 1.04044 31.6724C1.02789 24.5958 1.02475 17.516 1.04044 10.4394C1.04672 7.21106 2.6315 5.5308 5.84184 5.51821C15.5137 5.47731 25.1887 5.47731 34.8605 5.52136C37.8794 5.53394 39.392 7.13553 39.3984 10.1122C39.4109 17.3461 39.4109 24.5833 39.3984 31.8171C39.392 34.8347 37.8636 36.3765 34.8698 36.3891C30.0089 36.4049 25.1478 36.3922 20.2836 36.3922ZM3.94951 30.1369C5.64099 28.4064 7.23204 26.7544 8.84821 25.1307C12.3159 21.6475 12.5732 21.6097 16.1978 24.772C17.1392 25.5933 17.7418 25.546 18.498 24.6084C20.3998 22.2485 22.3424 19.9169 24.2911 17.5947C25.8507 15.7319 27.7336 15.6659 29.3907 17.3965C29.968 18.0006 30.4953 18.6551 31.0602 19.2718C33.0247 21.4146 34.7036 23.8124 36.8062 25.8356C37.2267 25.4956 37.0916 25.1024 37.0916 24.7594C37.1011 19.9924 37.1136 15.2254 37.0916 10.4615C37.0824 8.34382 36.5205 7.81835 34.3364 7.81521C24.9847 7.80575 15.6361 7.80575 6.28431 7.81521C4.00915 7.81835 3.55411 8.28719 3.55097 10.6062C3.54155 16.5784 3.5447 22.5474 3.55411 28.5196C3.55411 28.9664 3.42231 29.454 3.94951 30.1369ZM20.2524 33.8562C25.1133 33.8562 29.9744 33.8593 34.8354 33.8529C35.6827 33.8529 36.6838 33.8876 36.9191 32.8744C37.2234 31.5624 37.3678 30.2407 36.304 29.0356C33.5331 25.8985 30.8467 22.6889 28.1322 19.5047C27.0371 18.2209 26.8142 18.2177 25.7598 19.4826C23.4533 22.2547 21.1687 25.052 18.8151 27.7833C17.6539 29.1331 17.1487 29.0576 15.9091 27.7424C15.3003 27.0973 14.5973 26.5404 14.0042 25.8796C13.1098 24.8789 12.3127 24.8822 11.3713 25.8482C9.36601 27.9124 7.29481 29.9134 5.27697 31.9651C4.85959 32.3898 4.04681 32.7611 4.28531 33.4251C4.53635 34.1142 5.38681 33.8434 5.97991 33.8434C10.7405 33.8656 15.498 33.8562 20.2524 33.8562Z" fill="currentcolor" fill-opacity="0.4" />
                <path d="M10.7761 19.1534C8.55423 19.1408 6.70898 17.3158 6.78116 15.2013C6.8502 13.2127 8.78331 11.287 10.7447 11.2556C12.9414 11.2178 14.8776 13.1403 14.8463 15.324C14.8117 17.5738 13.1203 19.166 10.7761 19.1534ZM10.7101 16.8092C11.6955 16.7966 12.3765 16.3246 12.4361 15.3712C12.5021 14.3455 11.7991 13.669 10.8482 13.6029C9.89109 13.5337 9.36387 14.2637 9.28229 15.1887C9.19756 16.1894 9.73733 16.7243 10.7101 16.8092Z" fill="currentcolor" fill-opacity="0.4" />
            </g>
            <defs>
                <clipPath id="clip0_825_9">
                    <rect width="40" height="32.7273" fill="white" transform="translate(0 4)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ImageIconSingle