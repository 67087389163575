import React, { forwardRef, useState } from 'react'
import { cn } from '../../../../lib/utils'
import { cva } from 'class-variance-authority'
import EyeIcon from '../icons/EyeIcon';
import EyeCloseIcon from '../icons/EyeCloseIcon';

const inputIconVariance = cva(
  "input-wrap peer rounded-md text-[15px] leading-[24px] font-medium w-full outline-0 border-[1.3px] border-success-100 bg-light-950 text-blue-950 placeholder:text-dark-950/30 placeholder:text-sm required:border-danger-950 invalid:border-danger-950",
  {
    variants: {
      size: {
        default: '!px-4 h-[45px]',
        sm: 'px-3.5 text-sm leading-[22px] h-[40px]',
      }
    },
    defaultVariants: {
      size: "default",
    }
  },
)

const inputSizeVariance = (size) => {
  const sizeVariants = {
    default: 'py-2.5',
    sm: 'py-1.5 leading-[26px] ',
  };
  return sizeVariants[size] || sizeVariants.default;
};


const PasswordInput = forwardRef(({ errorData, icon, errorType, variant, size, type, ref, className, label, labelIcon, labelClassName, groupClassName, inputClassName, errorClassName, require, placeholder, suffixIcon, iconClassName, buttonType, ...props }) => {
  const [visible, setVisible] = useState(true)
  return (
    <div className={cn("meetime-form-group", groupClassName)}>
      {label && <label className={cn(labelIcon ? "!flex items-center !justify-start !gap-2" : "", "mb-1.5 text-blue-950 inline-block", labelClassName)}>{labelIcon && labelIcon} <span className={cn(require ? "before:content-['*'] before:text-danger-950 before:text-2xl before:leading-[1] before:absolute before:top-0.5 before:end-0 inline-block pe-3 relative" : "")}>{label}</span></label>}
      <div className={cn(inputIconVariance({ variant, size, className }), "flex items-center !gap-2")}>
        <input  {...props} ref={ref} type={visible ? "password" : "text"} className={cn(inputSizeVariance(size), "w-full bg-transparent placeholder:text-dark-950/30 placeholder:text-sm outline-0", inputClassName)} placeholder={placeholder && placeholder} />
        <button onClick={()=>setVisible(!visible)} aria-label="button" role="button" type={buttonType ?? "button"} className={cn("right_icon leading-none", iconClassName)}>{!visible ? <EyeIcon className='h-4 w-4 text-success-950'/> : <EyeCloseIcon className='h-4 w-4 text-success-950'/>}</button>
      </div>
      {errorType && <p className={cn("error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium", errorClassName)}>{errorData}</p>}
    </div>
  )
})

export { inputIconVariance }
export default PasswordInput
