import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { useState } from 'react'
import InputCheckbox from '../inputs/InputCheckbox'
import MaleIcon from '../icons/MaleIcon'
import FeMaleIcon from '../icons/FeMaleIcon'
import BabyBoyIcon from '../icons/BabyBoyIcon'
import BabyGirlIcon from '../icons/BabyGirlIcon'
import Table from '../table/Table'
import THead from '../table/THead'
import TR from '../table/TR'
import TH from '../table/TH'
import TBody from '../table/TBody'
import TD from '../table/TD'
import NoDataFound from '../table/NoDataFound'

const ServiceTableWithSelect = ({ t, serviceList, bookingShowData, salonService, serviceChoose, categoryChoose, selectedSerice, setSelectedService }) => {
    // console.log("serviceList", serviceList);
    const [selectedRows, setSelectedRows] = useState([]);


    useEffect(() => {
        if (bookingShowData?.service?.id) {
            setSelectedRows([bookingShowData?.service?.id]);
        }
    }, [bookingShowData?.service?.id]);

    const handleRowSelect = (rowId, data) => {
        let updatedSelectedRows;
        if (selectedRows.includes(rowId)) {
            updatedSelectedRows = selectedRows.filter(id => id !== rowId);
        } else {
            updatedSelectedRows = [...selectedRows, rowId];
        }
        setSelectedRows(updatedSelectedRows);
        const filteredServices = serviceList.filter(service => updatedSelectedRows.includes(service.id));

        setSelectedService(filteredServices);

        if (selectedRows.includes(rowId)) {
            setSelectedRows(selectedRows.filter(id => id !== rowId));
        } else {
            setSelectedRows([...selectedRows, rowId]);
        }
    };

    // const handleRowSelect = (rowId, data) => {
    //     // console.log("data", data);

    //     setSelectedService(data);
    //     if (selectedRows.includes(rowId)) {
    //         setSelectedRows(selectedRows.filter(id => id !== rowId));
    //     } else {
    //         setSelectedRows([...selectedRows, rowId]);
    //     }
    // };

    const calculateDiscount = (price, discountPrice, discountType) => {
        // 1=per , 2=kr
        if (discountType == 1) {
            let discount = price * discountPrice / 100;
            return price - discount + " Dkk.";
        } else if (discountType == 2) {
            return price - discountPrice + " Dkk.";
        }
        return price + " Dkk.";
    }
    const calculateDiscount2 = (price, discountPrice, discountType) => {
        // 1=per , 2=kr
        if (discountType == 1) {
            return discountPrice + " %";
        } else if (discountType == 2) {
            return price - discountPrice + " Dkk.";
        }
        return "-";
    }

    return (
        <div className="category-table-wrapper border-[1.3px] bg-light-800 border-success-100 rounded-xl p-3 pt-0 mb-5">
            <div className="overflow-auto">
                <Table className={"text-[15px] text-blue-950 w-full border-separate border-spacing-y-1.5"}>
                    <THead>
                        <TR>
                            <TH className={"font-medium border-b-0 py-0 px-3 md:px-5 text-start !ps-16"}>Service</TH>
                            <TH className={"font-medium border-b-0 py-0 px-3 md:px-5"}>Duration</TH>
                            <TH className={"font-medium border-b-0 py-0 px-3 md:px-5"}>For</TH>
                            <TH className={"font-medium border-b-0 py-0 px-3 md:px-5 flex flex-col"}>Price <span className='text-[13px]'> Standard/Student/Senio</span></TH>
                        </TR>
                    </THead>
                    <TBody className={""}>
                        {
                            serviceList?.length ? serviceList.map((data, index) => (
                                <>
                                    {data?.category &&
                                        <TR className={"rounded-lg mb-5 overflow-hidden"}>
                                            <TD className={"px-5 py-2 border-b-0 font-semibold leading-[20px] border  rounded-md text-success-900  bg-success-150 text-center"}
                                                colSpan={7}>{data?.category}</TD>
                                        </TR>
                                    }
                                    {
                                        // data.length && data.map((service, ind) => (
                                        <TR className={"[&_.td-item]:last:border-1 [&>td:not(:last-child)]:before:w-[1px] [&>td:not(:last-child)]:before:h-[calc(100%-8px)] [&>td:not(:last-child)]:before:bg-success-100 [&>td:not(:last-child)]:before:block [&>td:not(:last-child)]:before:absolute [&>td:not(:last-child)]:before:end-0 [&>td:not(:last-child)]:before:top-1 [&>td:not(:last-child)]:relative"} key={index}>
                                            <TD className={"px-1 py-1.5 ps-2.5 border-b-0 border border-e-0 rounded-tl-md rounded-bl-md xl:min-w-[300px] flex gap-3  justify-start items-center"}>
                                                <InputCheckbox
                                                    size={"xl"}
                                                    className={"before:rounded-full peer-checked:before:!bg-success-800 peer-checked:before:!border-success-800"}
                                                    value={`data_id_${index + 1}`}
                                                    checked={selectedRows.includes(data?.id)}
                                                    onChange={() => handleRowSelect(data?.id, [data])}
                                                />
                                                <p className="text-blue-950 font-medium">{data?.service}</p>
                                            </TD>
                                            <TD className={"text-center border-b-0 border-y border-success-100 px-1 py-1.5 w-[100px] xl:w-[unset]"}><p className="text-blue-950 text-sm font-medium">{data?.duration}</p></TD>
                                            <TD className={"px-1 py-1.5 border-b-0 border-y border-success-100 w-[100px] xl:w-[unset]"}>
                                                <ul className="flex items-end text-center justify-center gap-1">
                                                    {data.for == 'Men' ? <li className="flex-shrink-0 leading-none"><MaleIcon className="w-3.5 xl:w-4 text-[#3BDFFD]" /></li> : <></>}
                                                    {data.for == 'Woman' ? <li className="flex-shrink-0 leading-none"><FeMaleIcon className="w-3.5 xl:w-4 text-blue-950/10" /></li> : <></>}
                                                    {data.for == 'Girl' ? <li className="flex-shrink-0 leading-none"><BabyBoyIcon className="w-4 xl:w-5 text-[#FE7AB6]" /></li> : <></>}
                                                    {data.for == 'Boy' ? <li className="flex-shrink-0 leading-none"><BabyGirlIcon className="w-4 xl:w-5 text-[#3BDFFD]" /></li> : <></>}
                                                </ul>
                                            </TD>
                                            <TD className={"px-1 py-1.5 border-b-0 border-y rounded-tr-md rounded-br-md border-e border-success-100 w-[220px] xl:w-[unset]"}>
                                                <ul className="flex items-center gap-1 text-center text-blue-950 font-normal">
                                                    <li className="w-1/3 text-danger-700">{calculateDiscount(data?.price, data?.discount_price, data?.discount_type)}</li>
                                                    <li>/</li>
                                                    <li className="w-1/3">{calculateDiscount2(data?.price, data?.discount_price, data?.discount_type)}</li>
                                                    <li>/</li>
                                                    <li className="w-1/3">{calculateDiscount2(data?.price, data?.discount_price, data?.discount_type)}</li>
                                                </ul>
                                            </TD>
                                        </TR>
                                        // ))
                                    }
                                </>
                            )) : <NoDataFound colSpan={7} className={"text-center"} />
                        }
                    </TBody>
                </Table>
            </div>
        </div>
    )
}

export default withTranslation()(ServiceTableWithSelect)
{/* <Table className={"text-[15px] text-blue-950 w-full border-separate border-spacing-y-1.5"}>
<THead>
    <TR>
        <TH className={"font-medium border-b-0 py-0 px-3 md:px-5 text-start !ps-16"}>Service</TH>
        <TH className={"font-medium border-b-0 py-0 px-3 md:px-5"}>Duration</TH>
        <TH className={"font-medium border-b-0 py-0 px-3 md:px-5"}>For</TH>
        <TH className={"font-medium border-b-0 py-0 px-3 md:px-5 flex flex-col"}>Price <span className='text-[13px]'> Standard/Student/Senio</span></TH>
    </TR>
</THead>
<TBody className={""}>
    <TR className={"rounded-lg mb-5 overflow-hidden"}>
        <TD className={"px-5 py-2 border-b-0 font-semibold leading-[20px] border  rounded-md text-success-900  bg-success-150 text-center"}
            colSpan={7}>Haircut</TD>
    </TR>
    {
        serviceList?.map((item, ind) => (
            <TR className={"[&_.td-item]:last:border-1 [&>td:not(:last-child)]:before:w-[1px] [&>td:not(:last-child)]:before:h-[calc(100%-8px)] [&>td:not(:last-child)]:before:bg-success-100 [&>td:not(:last-child)]:before:block [&>td:not(:last-child)]:before:absolute [&>td:not(:last-child)]:before:end-0 [&>td:not(:last-child)]:before:top-1 [&>td:not(:last-child)]:relative"} key={ind}>
                <TD className={"px-1 py-1.5 ps-2.5 border-b-0 border border-e-0 rounded-tl-md rounded-bl-md xl:min-w-[300px] flex gap-3  justify-start items-center"}>
                    <InputCheckbox
                        size={"xl"}
                        className={"before:rounded-full peer-checked:before:!bg-success-800 peer-checked:before:!border-success-800"}
                        value={`data_id_${ind + 1}`}
                        checked={selectedRows.includes(`data_id_${ind + 1}`)}
                        onChange={() => handleRowSelect(`data_id_${ind + 1}`)}
                    />
                    <p className="text-blue-950 font-medium">Short hair</p>
                </TD>
                <TD className={"text-center border-b-0 border-y border-success-100 px-1 py-1.5 w-[100px] xl:w-[unset]"}><p className="text-blue-950 text-sm font-medium">40 min.</p></TD>
                <TD className={"px-1 py-1.5 border-b-0 border-y border-success-100 w-[100px] xl:w-[unset]"}>
                    <ul className="flex items-end text-center justify-center gap-1">
                        <li className="flex-shrink-0 leading-none"><MaleIcon className="h-5" /></li>
                        <li className="flex-shrink-0 leading-none"><FeMaleIcon className="h-5" /></li>
                        <li className="flex-shrink-0 leading-none"><BabyBoyIcon className="h-3.5" /></li>
                        <li className="flex-shrink-0 leading-none"><BabyGirlIcon className="h-3.5" /></li>
                    </ul>
                </TD>
                <TD className={"px-1 py-1.5 border-b-0 border-y rounded-tr-md rounded-br-md border-e border-success-100 w-[220px] xl:w-[unset]"}>
                    <ul className="flex items-center gap-1 text-center text-blue-950 font-normal">
                        <li className="w-1/3 text-danger-700">295 Dkk.</li>
                        <li>/</li>
                        <li className="w-1/3">150 Dkk.</li>
                        <li>/</li>
                        <li className="w-1/3">295 Dkk.</li>
                    </ul>
                </TD>
            </TR>
        ))
    }
    <TR className={"rounded-lg mb-5 overflow-hidden"}>
        <TD className={"px-5 py-2 border-b-0 font-semibold leading-[20px] border  rounded-md text-success-900  bg-success-150 text-center"}
            colSpan={7}>Care Treatment</TD>
    </TR>
    {
        [...Array(5)].map((item, ind) => (
            <TR className={"[&_.td-item]:last:border-1  [&>td:not(:last-child)]:before:w-[1px] [&>td:not(:last-child)]:before:h-[calc(100%-8px)] [&>td:not(:last-child)]:before:bg-success-100 [&>td:not(:last-child)]:before:block [&>td:not(:last-child)]:before:absolute [&>td:not(:last-child)]:before:end-0 [&>td:not(:last-child)]:before:top-1 [&>td:not(:last-child)]:relative"} key={ind}>
                <TD className={"px-1 py-1.5 ps-2.5 border-b-0 border border-e-0 rounded-tl-md rounded-bl-md xl:min-w-[300px] flex gap-3  justify-start items-center"}>
                    <InputCheckbox
                        size={"xl"}
                        className={"before:rounded-full peer-checked:before:!bg-success-800 peer-checked:before:!border-success-800"}
                        value={`data_id_${ind + 20}`}
                        checked={selectedRows.includes(`data_id_${ind + 20}`)}
                        onChange={() => handleRowSelect(`data_id_${ind + 20}`)}
                    />
                    <p className="text-blue-950 font-medium">Short hair</p>
                </TD>
                <TD className={"text-center border-b-0 border-y border-success-100 px-1 py-1.5 w-[100px] xl:w-[unset]"}><p className="text-blue-950 text-sm font-medium">40 min.</p></TD>
                <TD className={"px-1 py-1.5 border-b-0 border-y border-success-100 w-[100px] xl:w-[unset]"}>
                    <ul className="flex items-end text-center justify-center gap-1">
                        <li className="flex-shrink-0 leading-none"><MaleIcon className="h-5" /></li>
                        <li className="flex-shrink-0 leading-none"><FeMaleIcon className="h-5" /></li>
                        <li className="flex-shrink-0 leading-none"><BabyBoyIcon className="h-3.5" /></li>
                        <li className="flex-shrink-0 leading-none"><BabyGirlIcon className="h-3.5" /></li>
                    </ul>
                </TD>
                <TD className={"px-1 py-1.5 border-b-0 border-y rounded-tr-md rounded-br-md border-e border-success-100 w-[220px] xl:w-[unset]"}>
                    <ul className="flex items-center gap-1 text-center text-blue-950 font-normal">
                        <li className="w-1/3 text-danger-700">295 Dkk.</li>
                        <li>/</li>
                        <li className="w-1/3">150 Dkk.</li>
                        <li>/</li>
                        <li className="w-1/3">295 Dkk.</li>
                    </ul>
                </TD>
            </TR>
        ))
    }
</TBody>
</Table> */}
