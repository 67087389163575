import React from 'react';

const PhoneIcon2 = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_520_2)">
                <mask id="mask0_520_2" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
                    <path d="M32.5583 25.9421L39.3268 32.7105C40.2244 33.6082 40.2244 35.0633 39.3268 35.9609C34.4741 40.8134 26.7918 41.3595 21.3018 37.2418L16.9822 34.0022C12.8188 30.8795 9.12039 27.1811 5.99787 23.0179L2.75815 18.6982C-1.35942 13.2081 -0.813451 5.52577 4.03915 0.67317C4.93671 -0.22439 6.39194 -0.22439 7.2895 0.67317L14.058 7.44171C14.9906 8.37423 14.9906 9.88615 14.058 10.8187L11.3904 13.4863C10.9813 13.8954 10.8799 14.5203 11.1386 15.0377C14.1297 21.0198 18.9802 25.8704 24.9622 28.8615C25.4797 29.1201 26.1046 29.0186 26.5136 28.6096L29.1814 25.9421C30.1138 25.0094 31.6259 25.0094 32.5583 25.9421Z" fill="currentColor" />
                </mask>
                <g mask="url(#mask0_520_2)">
                    <path d="M49.4579 22.5795L42.6892 15.811L22.4273 36.0729L29.1959 42.8414L49.4579 22.5795ZM24.189 -2.68923L17.4204 -9.45777L-2.84146 10.8041L3.92708 17.5727L24.189 -2.68923ZM21.5213 23.6172L24.189 20.9497L3.92708 0.687748L1.25941 3.3554L21.5213 23.6172ZM19.0504 15.811L16.3827 18.4787L36.6447 38.7407L39.3122 36.0729L19.0504 15.811ZM-8.70372 27.2946L-5.464 31.6143L17.4597 14.4215L14.22 10.1018L-8.70372 27.2946ZM8.38575 45.464L12.7054 48.7037L29.8982 25.7799L25.5785 22.5403L8.38575 45.464ZM-5.464 31.6143C-1.52691 36.8638 3.13631 41.5269 8.38575 45.464L25.5785 22.5403C22.5013 20.2324 19.7677 17.4987 17.4597 14.4215L-5.464 31.6143ZM31.3696 16.0466C28.1603 14.442 25.558 11.8397 23.9533 8.63032L-1.67614 21.4451C2.70126 30.1999 9.80014 37.2986 18.5549 41.6761L31.3696 16.0466ZM16.3827 18.4787C20.3343 14.5271 26.3713 13.5474 31.3696 16.0466L18.5549 41.6761C24.5883 44.6927 31.8749 43.5103 36.6447 38.7407L16.3827 18.4787ZM1.25941 3.3554C-3.5103 8.12511 -4.69276 15.4118 -1.67614 21.4451L23.9533 8.63032C26.4525 13.6288 25.473 19.6657 21.5213 23.6172L1.25941 3.3554ZM3.92708 17.5727C-0.735527 12.9101 -0.735585 5.35041 3.92708 0.687748L24.189 20.9497C30.7168 14.4219 30.7165 3.83845 24.189 -2.68923L3.92708 17.5727ZM42.6892 15.811C36.1616 9.28334 25.578 9.28336 19.0504 15.811L39.3122 36.0729C34.6496 40.7355 27.0901 40.7355 22.4273 36.0729L42.6892 15.811ZM49.4579 46.0918C55.9506 39.5991 55.9506 29.0724 49.4579 22.5795L29.1959 42.8414C24.4982 38.144 24.4982 30.5275 29.1959 25.8299L49.4579 46.0918ZM29.1959 25.8299C29.3851 25.6407 29.6843 25.6195 29.8982 25.7799L12.7054 48.7037C23.8994 57.0993 39.5634 55.986 49.4579 46.0918L29.1959 25.8299ZM-6.0918 -9.45777C-15.9861 0.436518 -17.0993 16.1005 -8.70372 27.2946L14.22 10.1018C14.3804 10.3157 14.3592 10.6151 14.1701 10.8041L-6.0918 -9.45777ZM14.1701 10.8041C9.4725 15.5017 1.85616 15.5018 -2.84146 10.8041L17.4204 -9.45777C10.9277 -15.9505 0.400917 -15.9505 -6.0918 -9.45777L14.1701 10.8041Z" fill="currentcolor" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_520_2">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default PhoneIcon2;
