import React from 'react'
import { withTranslation } from 'react-i18next'
import InputRadioBox from '../inputs/InputRadioBox'
import { cn } from '../../../../lib/utils'
import InputCheckbox from '../inputs/InputCheckbox'

const ServicesTypeList = ({ t, dataList, className, labelClassName, icon, type, id, name, checked, setChecked, radioName, itemClassName,handleSelect,  ...props }) => {
    return (
        <div className={cn("flex justify-center sm:justify-start items-start flex-wrap gap-2 sm:gap-x-3 sm:gap-y-3.5", className)}>
            {dataList.length ? dataList?.map((data, ind) =>
                type == 'check' ? <InputCheckbox
                    name={name}
                    id={id + ind + 1}
                    groupClassName={cn("flex-grow max-w-[165px] w-full h-full", itemClassName)}
                    className={cn(`bg-light-800 text-blue-950 rounded-lg items-center gap-1 before:hidden after:hidden peer-checked:after:hidden ${icon === false ? "p-3" : "px-2 sm:px-2.5 py-1"} border-[1.3px] border-success-100 w-full text-sm font-medium peer-checked:border-success-950 peer-checked:bg-success-200 hover:border-success-950 hover:bg-success-200 transition-all duration-300 h-full leading-none`, labelClassName)}
                    text={
                        <>
                            {icon === false ? <></> : <span className="icon flex-shrink-0 w-10 h-10 lg:w-[46px] lg:h-[46px] rounded-full bg-white flex items-center justify-center p-1.5">{data?.icon}</span>}
                            <span className="text flex-grow text-center block break-all">{data.name}</span>
                        </>
                    }
                /> :
                    <InputRadioBox
                        id={id +"_"+ ind}
                        value={data.id}
                        checked={checked === data.id ? true : false}
                        onChange={()=>{
                            if(handleSelect){
                                return handleSelect(data?.id)
                            }else{
                                return setChecked(data.id)
                            }
                        }}
                        name={radioName}
                        groupClassName={cn("flex-grow max-w-[165px] w-full h-full", itemClassName)}
                        className={cn(`bg-light-800 text-blue-950 rounded-lg items-center gap-1 before:hidden after:hidden peer-checked:after:hidden ${icon === false ? "p-3" : "px-2 sm:px-2.5 py-1"} border-[1.3px] border-success-100 w-full text-sm font-medium peer-checked:border-success-950 peer-checked:bg-success-200 hover:border-success-950 hover:bg-success-200 transition-all duration-300 h-full leading-none`, labelClassName)}
                        text={
                            <>
                                {icon === false ? <></> : <span className="icon flex-shrink-0 w-10 h-10 lg:w-[46px] lg:h-[46px] rounded-full bg-white flex items-center justify-center p-1.5">{data?.icon}</span>}
                                <span className="text flex-grow text-center block break-all">{data.name}</span>
                            </>
                        }
                    />

            ): <span className="text flex-grow block break-all">Category not found</span>}
        </div>
    )
}

export default withTranslation()(ServicesTypeList)