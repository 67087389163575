function path(root, sublink) {
  return `${root}${sublink}`;
}
const BASE_PATH = process.env.REACT_APP_BASE_URL;
const ROOTS_PATH = BASE_PATH + "/api/serviceProvider";
const ROOTS_PATH_CUSTOMER = BASE_PATH + "/api/customer";
const ROOTS_PATH_FRONT = BASE_PATH + "/api";

const AUTH_API_URL = {
  login: path(ROOTS_PATH, "/login"),
  register: path(ROOTS_PATH, "/registration"),
  otpVerify: path(ROOTS_PATH, "/verify-otp"),
  resendOtp: path(ROOTS_PATH, "/resend-otp"),
  forgotPasswordOtpVerify: path(ROOTS_PATH, "/forgot-password/verify-otp"),
  forgotPassword: path(ROOTS_PATH, "/forgot-password"),
  logout: path(ROOTS_PATH, "/logout"),
};

const FRONT_API_URL = {
  serviceType: path(ROOTS_PATH_FRONT, "/service-types"),
  subscriberRequest: path(ROOTS_PATH_FRONT, "/subscriber-request"),
  hairListing: path(ROOTS_PATH_FRONT, "/hair-types"),
  serviceCategory: path(ROOTS_PATH_FRONT, "/categories"),
  salonFilter: path(ROOTS_PATH_FRONT, "/salon-filter"),
  servicesFilter: path(ROOTS_PATH_FRONT, "/services/filter"),
  badgeFilter: path(ROOTS_PATH_FRONT, "/badges"),
  getRatingReview: path(ROOTS_PATH_FRONT, "/salon-reviews"),
  getWorkerName: path(ROOTS_PATH_FRONT, "/worker-names/:id"),
  servicetypeCategories: path(ROOTS_PATH_FRONT, "/servicetype-categories"),
  portfolioFilter: path(ROOTS_PATH_FRONT, "/portfolio-filter"),
  salonProfileView: path(ROOTS_PATH_FRONT, "/salon-profile"),
  getWorker: path(ROOTS_PATH_FRONT, "/workers"),
  resetPassword : path(ROOTS_PATH_FRONT, "/reset-password"),
}

const CUSTOMER_AUTH_API_URL = {
  login: path(ROOTS_PATH_CUSTOMER, "/login"),
  register: path(ROOTS_PATH_CUSTOMER, "/registration"),
  otpVerify: path(ROOTS_PATH_CUSTOMER, "/verify-otp"),
  resendOtp: path(ROOTS_PATH_CUSTOMER, "/resend-otp"),
  forgotPassword: path(ROOTS_PATH_CUSTOMER, "/forgot-password"),
  forgotPasswordOtpVerify: path(ROOTS_PATH_CUSTOMER, "/forgot-password/verify-otp"),
  resetPassword: path(ROOTS_PATH_CUSTOMER, "/reset-password"),
  changePassword: path(ROOTS_PATH_CUSTOMER, "/change-password"),
  logout: path(ROOTS_PATH_CUSTOMER, "/logout"),
  updateProfile: path(ROOTS_PATH_CUSTOMER, "/update-profile"),
  bookingPayment: path(ROOTS_PATH_CUSTOMER, "/bookings/payment"),
  getProfile: path(ROOTS_PATH_CUSTOMER, "/get-profile"),
  payment: path(ROOTS_PATH_CUSTOMER, "/payment"),
  getPayment: path(ROOTS_PATH_CUSTOMER, "/payment-details"),
  salonFilter: path(ROOTS_PATH_CUSTOMER, "/salon-filter"),
  hairListing: path(ROOTS_PATH_CUSTOMER, "/hair-types"),
  serviceCategory: path(ROOTS_PATH_CUSTOMER, "/categories"),
  servicetypeCategories: path(ROOTS_PATH_CUSTOMER, "/servicetype-categories"),
  serviceType: path(ROOTS_PATH_CUSTOMER, "/service-types"),
  salonProfileView: path(ROOTS_PATH_CUSTOMER, "/salon-profile"),
  getWorker: path(ROOTS_PATH_CUSTOMER, "/workers"),
  offerSend: path(ROOTS_PATH_CUSTOMER, "/offer-send"),
  checkAvailablity: path(ROOTS_PATH_CUSTOMER, "/booking/check-availablity"),
  bookingStore: path(ROOTS_PATH_CUSTOMER, "/booking/store"),
  removeData: path(ROOTS_PATH_CUSTOMER, "/bookings/remove-data"),
  checkWorkerServices: path(ROOTS_PATH_CUSTOMER, "/booking/check-worker-services"),
  employeeTimings: path(ROOTS_PATH_CUSTOMER, "/employee-timings"),
  salonFavourites: path(ROOTS_PATH_CUSTOMER, "/favourites"),
  salonFavouritesDelete: path(ROOTS_PATH_CUSTOMER, "/favourites-delete"),
  serviceDetails: path(ROOTS_PATH_CUSTOMER, "/service-details"),
  bookingsInProcess: path(ROOTS_PATH_CUSTOMER, "/bookings/in-process"),
  locationDetails: path(ROOTS_PATH_CUSTOMER, "/location-details"),
  servicesFilter: path(ROOTS_PATH_CUSTOMER, "/services/filter"),
  getNotification: path(ROOTS_PATH_CUSTOMER, "/notifications"),
  ratingStore: path(ROOTS_PATH_CUSTOMER, "/rating"),
  salonHistoryList: path(ROOTS_PATH_CUSTOMER, "/bookings"),
  favouritesStore: path(ROOTS_PATH_CUSTOMER, "/favourites"),
  addTicket: path(ROOTS_PATH_CUSTOMER, "/contact-support"),
  getTicket: path(ROOTS_PATH_CUSTOMER, "/contact-support"),
  replyTicket: path(ROOTS_PATH_CUSTOMER, "/ticket-replies"),
  messageList: path(ROOTS_PATH_CUSTOMER, "/message-list"),
  replyAddTicket: path(ROOTS_PATH_CUSTOMER, "/ticket-replies"),
  bookingShow: path(ROOTS_PATH_CUSTOMER, "/booking/show"),
  serviceDelete: path(ROOTS_PATH_CUSTOMER, "/booking/delete"),
  vouchers: path(ROOTS_PATH_CUSTOMER, "/vouchers"),
  applyVouchers: path(ROOTS_PATH_CUSTOMER, "/voucher/apply"),
  getWorkerName: path(ROOTS_PATH_CUSTOMER, "/worker-names/:id"),
  getRatingReview: path(ROOTS_PATH_CUSTOMER, "/salon-reviews"),
  badgeFilter: path(ROOTS_PATH_CUSTOMER, "/badges"),
  cancelBooking: path(ROOTS_PATH_CUSTOMER, "/booking/cancel"),
  offerRequest: path(ROOTS_PATH_CUSTOMER, "/offer-request"),
  offerRequestShow: path(ROOTS_PATH_CUSTOMER, "/offer-request/show"),
  confirmOffer: path(ROOTS_PATH_CUSTOMER, "/confirm-offer"),
  cancelOffer: path(ROOTS_PATH_CUSTOMER, "/cancel-offer"),
  reviewThoughts: path(ROOTS_PATH_CUSTOMER, "/review-thoughts"),
  portfolioFilter: path(ROOTS_PATH_CUSTOMER, "/portfolio-filter"),
  payByStripe: path(ROOTS_PATH_CUSTOMER, "/payByStripe"),
};

const PROFILE_COMPLETE_URL = {
  workerAvl: path(ROOTS_PATH, "/worker-avl"),
  confirmOffer: path(ROOTS_PATH, "/confirm-offer"),
  mySalons: path(ROOTS_PATH, "/my-salons"),
  salonNamesRoles: path(ROOTS_PATH, "/mysalons-data"),
  setDefaultSalon: path(ROOTS_PATH, "/set-default-salon"),
  hairTypes: path(ROOTS_PATH, "/hair-types"),
  salonProfile: path(ROOTS_PATH, "/salon"),
  updateSalonAvailability: path(ROOTS_PATH, "/update-salon-availability"),
  salonOnlineOffline: path(ROOTS_PATH, "/salon-status"),
  updateProfile: path(ROOTS_PATH, "/update-profile"),
  // personalInformationStore: path(ROOTS_PATH, "/personal-information"),
  candidateInterview: path(ROOTS_PATH, "/job/candidate-interview"),
  // personalGetProfile: path(ROOTS_PATH, "/personal-information/:salon_id"),
  personalInformation: path(ROOTS_PATH, "/personal-information"),
  paymentStore: path(ROOTS_PATH, "/payment"),
  providerPayment: path(ROOTS_PATH, "/provider-payment"),
  paymentStepStore: path(ROOTS_PATH, "/payment-update"),
  workerStepStore: path(ROOTS_PATH, "/worker/step-update"),
  paymentStoreUpdate: path(ROOTS_PATH, "/payment/:id"),
  setProfile: path(ROOTS_PATH, "/profile-type"),
  getProfile: path(ROOTS_PATH, "/salon-first"),
  getSalon: path(ROOTS_PATH, "/get-salon"),
  getPersonalProfile: path(ROOTS_PATH, "/get-profile"),
  updateDocument: path(ROOTS_PATH, "/update-document"),
  getWorker: path(ROOTS_PATH, "/workers"),
  bookTimings: path(ROOTS_PATH, "/bookings/timings"),
  checkBookingAvailability: path(ROOTS_PATH, "/bookings/availability"),
  bookingWorkers: path(ROOTS_PATH, "/booking/workers"),
  checkWorkerServices: path(ROOTS_PATH, "/booking/check-worker-services"),
  addBooking: path(ROOTS_PATH, "/bookings/store"),
  editBooking: path(ROOTS_PATH, "/booking/edit"),
  addWorker: path(ROOTS_PATH, "/worker"),
  getWorkerList: path(ROOTS_PATH, "/worker"),
  deleteWorker: path(ROOTS_PATH, "/worker/:id"),
  serviceTypes: path(ROOTS_PATH, "/service-types"),
  serviceStepUpdates: path(ROOTS_PATH, "/services/step-update"),
  createCategory: path(ROOTS_PATH, "/create-category"),
  createServices: path(ROOTS_PATH, "/services"),
  updateServices: path(ROOTS_PATH, "/services/:id"),
  deleteService: path(ROOTS_PATH, "/services/:id"),
  servicesFilter: path(ROOTS_PATH, "/services/filter"),
  categories: path(ROOTS_PATH, "/categories"),
  destroyServices: path(ROOTS_PATH, "/services/:id"),
  editServices: path(ROOTS_PATH, "/services/:id/edit"),
  getCustomer: path(ROOTS_PATH, "/customers"),
  isFavorites: path(ROOTS_PATH, "/customer/favourite"),
  isFavoritesDelete: path(ROOTS_PATH, "/customer/favourite-delete"),
  completeProfile : path(ROOTS_PATH,"/complete-profile"),

  addTicket: path(ROOTS_PATH, "/contact-support"),
  getTicket: path(ROOTS_PATH, "/contact-support"),
  replyTicket: path(ROOTS_PATH, "/ticket-replies"),
  replyAddTicket: path(ROOTS_PATH, "/ticket-replies"),
  messageList: path(ROOTS_PATH, "/message-list"),
  salonDelete: path(ROOTS_PATH, "/salon/:id"),
  workerDelete: path(ROOTS_PATH, "/worker/:id"),
  workerRemove: path(ROOTS_PATH, "/worker/remove/:id"),
  bookingList: path(ROOTS_PATH, "/booking/list"),
  bookingShow: path(ROOTS_PATH, "/booking/show"),
  getCalanderList: path(ROOTS_PATH, "/booking/calenderlist"),
  bookingDelay: path(ROOTS_PATH, "/bookings/delay"),
  bookingComplete: path(ROOTS_PATH, "/booking/complete"),
  bookingUpComing: path(ROOTS_PATH, "/booking/upcoming"),
  bookingDates: path(ROOTS_PATH, "/booking/dates"),
  bookingCancel: path(ROOTS_PATH, "/booking/cancel"),
  bookingConfirm: path(ROOTS_PATH, "/booking/confirm"),
  bookingArrivalConfirm: path(ROOTS_PATH, "/booking/arrival-confirm"),
  offerRequest: path(ROOTS_PATH, "/offer-request"),
  dashboard: path(ROOTS_PATH, "/dashboard"),
  addDiscount: path(ROOTS_PATH, "/services/add-discount"),
  bookingNoShowUp: path(ROOTS_PATH, "/booking/noShow"),
  //worker profile
  getNotification: path(ROOTS_PATH, "/notifications"),
  notification: path(ROOTS_PATH, "/notifications"),
  getSalonList: path(ROOTS_PATH, "/workers/salon-list"),
  workerNotification: path(ROOTS_PATH, "/notifications"),
  throughMeetime: path(ROOTS_PATH, "/workers/through-meetime"),
  workerBookingDates: path(ROOTS_PATH, "/workers/booking/dates"),
  acceptInvitation: path(ROOTS_PATH, "/workers/invitation/:id"),
  rejectInvitation: path(ROOTS_PATH, "/workers/invitation/reject/:id"),
  updateWorkerCityStatus: path(ROOTS_PATH, "/workers/update-worker-city"),
  workerCityRequest: path(ROOTS_PATH, "/workers/worker-city-request"),
  updateOfferRequestStatus: path(ROOTS_PATH, "/workers/update-offer-request-status"),
  workerProfile: path(ROOTS_PATH, "/workers/salon"),
  getSalonServices: path(ROOTS_PATH, "/workers/salon-services/:id"),
  workerEducation: path(ROOTS_PATH, "/workers/education"),
  storeWorkerProfile: path(ROOTS_PATH, "/workers/salon-profile"),
  storeBecomeWorker: path(ROOTS_PATH, "/become-worker"),
  workerAvailablity: path(ROOTS_PATH, "/workers/availablity"),
  workerDashboard: path(ROOTS_PATH, "/workers/dashboard"),
  getWorkerTicket: path(ROOTS_PATH, "/workers/contact-support"),
  addWorkerTicket: path(ROOTS_PATH, "/workers/contact-support"),
  workerMessageList: path(ROOTS_PATH, "/workers/message-list"),
  workerReplyTicket: path(ROOTS_PATH, "/workers/ticket-replies"),
  workerReplyAddTicket: path(ROOTS_PATH, "/workers/ticket-replies"),
  salonAvailablity: path(ROOTS_PATH, "/workers/salon-availablity"),
  salonsAndAvailablities: path(ROOTS_PATH, "/workers/salon-and-availablities"),
  getEducation: path(ROOTS_PATH, "/workers/education"),
  updateEducationStep: path(ROOTS_PATH, "/workers/education/step-update"),
  deleteEducation: path(ROOTS_PATH, "/workers/education/:id"),
  workerPortfolio: path(ROOTS_PATH, "/workers/portfolio"),
  getPortfolio: path(ROOTS_PATH, "/workers/portfolio"),
  showPortfolio: path(ROOTS_PATH, "/workers/portfolio/:id"),
  deletePortfolio: path(ROOTS_PATH, "/workers/portfolio/:id"),
  updatePortfolio: path(ROOTS_PATH, "/workers/portfolio/:id"),
  workerBadgesList: path(ROOTS_PATH, "/workers/badges"),
  getPayment: path(ROOTS_PATH, "/payment"),
  servicesTypeGet: path(ROOTS_PATH, "/services-type"),
  newServicesTypeGet: path(ROOTS_PATH, "/services-type/:salonId"),
  changePassword: path(ROOTS_PATH, "/change-password"),
  getProviderPayment: path(ROOTS_PATH, "/get-provider-payment"),
  // become worker both [salon + worker]
  becomeWorker: path(ROOTS_PATH, "/become-worker"),
  badgesList: path(ROOTS_PATH, "/badges"),
  badgesApply: path(ROOTS_PATH, "/badge/apply"),
  badgesApplyWorker: path(ROOTS_PATH, "/workers/badge/apply"),
  //worker booking calander
  workerBookingList: path(ROOTS_PATH, "/workers/booking/list"),
  workerBookingUpComing: path(ROOTS_PATH, "/workers/booking/upcoming"),
  WorkerGetCalanderList: path(ROOTS_PATH, "/workers/booking/calenderlist"),
  //job applay
  jobApply: path(ROOTS_PATH, "/workers/job-apply"),
  jobWorkerDetail: path(ROOTS_PATH, "/workers/job/worker-detail"),
  // services active inactive
  serviceActiveInactive: path(ROOTS_PATH, "/services/status"),

  offerRequestShow: path(ROOTS_PATH, "/offer-request/show"),
  cancelOffer: path(ROOTS_PATH, "/cancel-offer"),

  confirmType: path(ROOTS_PATH, "/booking/confirmation-type"),
  getJobListing: path(ROOTS_PATH, "/workers/job-filter"),
  servicesFilterModal: path(ROOTS_PATH, "/services-filter"),
  servicetypeCategories: path(ROOTS_PATH, "/servicetype-categories"),
  bookingsInProcess: path(ROOTS_PATH, "/bookings/in-process"),
};

const PATH_PROVIDER_SALON = {
  getSalonWorker: path(ROOTS_PATH, "/salon-workers"),
  getSalonName: path(ROOTS_PATH, "/salon-names"),
  workerDetail: path(ROOTS_PATH, "/worker/detail/:id"),
  getWorkerName: path(ROOTS_PATH, "/worker-names/:id"),
}

const PATH_PROVIDER_JOB = {
  createJob: path(ROOTS_PATH, "/job"),
  updateJob: path(ROOTS_PATH, "/job/:id"),
  deleteJob: path(ROOTS_PATH, "/job/:id"),
  getJob: path(ROOTS_PATH, "/job-list"),
  getCandidateListing: path(ROOTS_PATH, "/job/candidate-list"),
  // getJobListing: path(ROOTS_PATH, "/job-filter"),
}

const PATH_FRONT_JOB = {
  getJobListing: path(BASE_PATH, "/api/job-filter"),
  jobApply: path(BASE_PATH, "/api/job-apply"),
}

const IMAGE_UPLOAD = {
  uploadImage: path(BASE_PATH, "/api/image"),
};

// const JOB_LISTING = {
//   jobListing: path(BASE_PATH, "/api/job-filter"),
// };

const CONTACT_US = {
  contactUs: path(BASE_PATH, "/api/contact-us"),
};

const FAQ = {
  faq: path(BASE_PATH, "/api/faq"),
};

const FOOTER_URL = {
  commonSetting: path(BASE_PATH, "/api/common-setting"),
};

const PRIVACY_POLICY = {
  privacyPolicy: path(BASE_PATH, "/api/privacy-policy"),
};

const TERMS_CONDITION = {
  termsAndCondition: path(BASE_PATH, "/api/terms-of-use"),
};

const ABOUT_US = {
  aboutUs: path(BASE_PATH, "/api/about-us"),
};

export { BASE_PATH, AUTH_API_URL, CUSTOMER_AUTH_API_URL, PROFILE_COMPLETE_URL, IMAGE_UPLOAD, CONTACT_US, FAQ, PRIVACY_POLICY, TERMS_CONDITION, ABOUT_US, PATH_PROVIDER_SALON, PATH_PROVIDER_JOB, PATH_FRONT_JOB, FRONT_API_URL, FOOTER_URL };
