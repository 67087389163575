import React, { useEffect, useRef, useState } from 'react'
import RightSmallArrowIcon from './icons/RightSmallArrowIcon';
import LeftSmallArrowIcon from './icons/LeftSmallArrowIcon';
import CloseIcon from './icons/CloseIcon';
import Slider from "react-slick"
import { cn } from '../../../lib/utils';




const CustomSlider = ({ slidesToShow, className, groupClassName, nextArrowClassName, prevArrowClassName, imageList, handleRemoveImage }) => {
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div onClick={onClick} className={cn("absolute top-1/2 -translate-y-1/2 end-1 bg-white rounded-full h-8 w-8 flex items-center justify-center border border-success-100", nextArrowClassName)}><RightSmallArrowIcon className="w-4 h-4 text-blue-950" /></div>
        );
    }
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div onClick={onClick} className={cn("absolute top-1/2 z-10 -translate-y-1/2 end-[92%] bg-white rounded-full h-8 w-8 flex items-center justify-center border border-success-100", prevArrowClassName)}><LeftSmallArrowIcon className="w-4 h-4 text-blue-950" /></div>
        );
    }

    const shopSliderMain = {
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        dots: false,
    };

    return (
        <>
            <div className={cn("slider-container my-2", groupClassName)}>
                <Slider {...shopSliderMain} className='slider_flex gap_8' >
                    {imageList?.map((image, index) => {
                        return (
                            <div className={cn("max-h-[185px] rounded-lg h-full w-full overflow-hidden relative border border-success-100", className)}>
                                <div className='bg-primary1 absolute top-2 end-2 p-1 w-7 h-7 rounded-md'><CloseIcon className="w-5 h-5 text-white" onClick={() => handleRemoveImage(index)} /></div>
                                <img src={image.file} className='h-full w-full object-cover' alt="" />
                            </div>
                        )
                    })
                    }
                </Slider>
            </div>
        </>
    )
}

export default CustomSlider