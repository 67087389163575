import { enqueueSnackbar } from "notistack";
import { withTranslation } from "react-i18next";
import { cn } from "../../../../lib/utils";

function DropZone({ t, id, type, className, groupClassName, fileSelected, setFileSelected, dropzoneText, labelTitleClass, labelTextClass, removeUploadedImage, labelClassName, icon, errorType, itemId, br, accept, heading, preBr, handleGuideLineOnChange, document, single, limit, ...props }) {
    const handleFileUpload = (event, type) => {
        if (document) {
            setFileSelected(event.target.files);
        } else {
            let invalidImage = false;

            const files = event.target.files;
            const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
            const uploadedImages = [];

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();
                if (!validImageTypes.includes(file.type)) {
                    invalidImage = true;
                }

                if (invalidImage) {
                    enqueueSnackbar(t("validation_message.image_type_invalid"), {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                    return false;
                }

                if (limit && limit > 0) {
                    let totalImages = files?.length + fileSelected?.length;
                    if (totalImages > limit) {

                        enqueueSnackbar(t("only " + limit + " images are allowed"), {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                        return false;
                    }
                }

                reader.onload = () => {
                    uploadedImages.push({ image: file, file: reader.result, portfolioType:0 });

                    if (uploadedImages.length === files.length) {
                        if (single && single === true) {
                            setFileSelected(uploadedImages);

                        } else {
                            setFileSelected(prevImages => [...prevImages, ...uploadedImages]);
                        }
                    }
                };

                reader.readAsDataURL(file);
            }

        }

    };
    return (
        <div className={cn("form-group ", groupClassName)}>
            {heading ? <div className={cn("text-blue-950 mb-2", labelTextClass)}>{heading}</div> : ""}
            <label
                htmlFor={id || "dropzone-file"}
                className={cn(
                    "flex flex-col items-center justify-center w-full p-4 border border-success-100 bg-white rounded-lg cursor-pointer",
                    labelClassName,
                )}>
                <div className={cn("flex flex-col items-center justify-center ", className)}>
                    {icon ? icon : <></>}
                    <h6 className={cn("text-sm font-medium text-blue-950/50 flex flex-col text-center leading-4 mt-2.5", labelTitleClass)}>
                        {dropzoneText ? dropzoneText : <><span>Drag & Drop</span> {preBr && <br />} <span>Or</span> {br && <br />} <span>Click her and choose file</span></>}
                    </h6>
                </div>
                <input id={id || "dropzone-file"} type="file" className="hidden" onInput={handleGuideLineOnChange ?? handleFileUpload} onClick={(e) => {
                    e.target.value = null
                }} {...props} accept={accept || "image/png, image/gif, image/jpeg"} />
            </label>
        </div>
    );
}

export default withTranslation()(DropZone)