import React, { useEffect, useState } from 'react'
import Card from '../Card'
import { cn } from '../../../../lib/utils'
import { ActiveInactiveSwitch } from './ActiveInactiveSwitch'
import { Switch } from '@headlessui/react'
import { enqueueSnackbar } from 'notistack'
import { withTranslation } from 'react-i18next'
import { PROFILE_COMPLETE_URL } from '../../../api/axios'
import Http from '../../../../Http'
import { useMutation } from 'react-query'


const ActiveInactiveCompo = ({ t, className, id, switchClassName, salonDataId, header, headerClassName, groupClassName, children, cityData, workerCityData, workerProfileData, setFieldValue, offerRequestStatus, ...props }) => {
    const [isActive, setIsActive] = useState(true)
    // const [offerRequestIsActive, setOfferRequestIsActive] = useState(true)

    const handleToggle = async () => {
        setIsActive(!isActive)
        // setOfferRequestIsActive(!offerRequestIsActive)
        if (id === "on_the_go") {
            const status = isActive ? 0 : 1
            const salon_city_id = cityData?.id
            const data = { salon_city_id, status }
            await updateWorkerCity({ data });
        }
        else {
            const offer_request_status = isActive ? 0 : 1
            const salon_id = salonDataId
            const data = { salon_id, offer_request_status }
            await updateOfferRequestStatus({ data });

        }

    }



    useEffect(() => {
        if (workerCityData && cityData) {
            const cityStatus = workerCityData.find(city => city.salon_city_id === cityData.id);
            setIsActive(cityStatus ? cityStatus.status === 1 : true);
        }
        if (id === "offer_request") {
            setIsActive(offerRequestStatus === 1 ? true : false);
        }
    }, [workerCityData, cityData, id, offerRequestStatus]);

    const { mutateAsync: updateWorkerCity } = useMutation(
        async (data) => {
            try {
                const response = await Http.post(PROFILE_COMPLETE_URL.updateWorkerCityStatus, data.data);
                return { responseData: response.data.data, requestData: data }
            } catch (error) {
                const errorData = error?.response?.data?.errors;
                const errorDataMsg = error?.response?.data?.message;
                if (errorData) {
                    Object.values(errorData).forEach(message => {
                        enqueueSnackbar(message, {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                }
                else if (errorDataMsg) {
                    enqueueSnackbar(errorDataMsg, {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            }
        },
        {
            onSuccess: () => {
                enqueueSnackbar(t("snackbar_message.on_the_go_city_updated_successfully"), {
                    variant: "success",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            }
        }
    )

    const { mutateAsync: updateOfferRequestStatus } = useMutation(
        async (data) => {
            try {
                const response = await Http.post(PROFILE_COMPLETE_URL.updateOfferRequestStatus, data.data);
                return { responseData: response.data.data, requestData: data }
            } catch (error) {
                const errorData = error?.response?.data?.errors;
                const errorDataMsg = error?.response?.data?.message;
                if (errorData) {
                    Object.values(errorData).forEach(message => {
                        enqueueSnackbar(message, {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                }
                else if (errorDataMsg) {
                    enqueueSnackbar(errorDataMsg, {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            }
        },
        {
            onSuccess: () => {
                enqueueSnackbar(t("snackbar_message.offer_request_status_updated_successfully"), {
                    variant: "success",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            }
        }
    )

    return (
        <Card className={cn("w-full ", groupClassName)}>
            <div className={cn("card-header flex justify-between items-center flex-wrap gap-2 w-full", headerClassName)}>
                {header && header}
                <Switch
                    id={props?.id}
                    // checked={id === "on_the_go" ? isActive : offerRequestIsActive}
                    checked={isActive}
                    onChange={handleToggle}
                    className={cn("relative inline-flex h-[30px] w-[160px] shrink-0 cursor-pointer rounded-full border border-success-100 transition-colors duration-200 ease-in-out focus:outline-[0px] bg-light-950 text-black", className)} >
                    <div className='grid grid-cols-2 w-full h-full items-center'>
                        <span className="">Inactive</span>
                        <span className="">Active</span>
                    </div>
                    <span aria-hidden="true" className={cn("pointer-events-none absolute top-0 py-[4px] font-medium text-white inline-block h-full w-1/2 transform rounded-full shadow-lg transition duration-200 ease-in-out bg-success-950", isActive ? 'translate-x-full' : 'translate-x-0')}>{!isActive ? "Inactive" : "Active"}</span>
                </Switch>
            </div>
            <div className={`${isActive ? "block" : "opacity-30 pointer-events-none"}`}>
                {children}
            </div>
        </Card>
    )
}

export default withTranslation()(ActiveInactiveCompo)