import { Badge, Calendar } from 'antd'
import React, { useCallback, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import LeftSmallArrowIcon from '../icons/LeftSmallArrowIcon'
import RightSmallArrowIcon from '../icons/RightSmallArrowIcon'
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import utcPlugin from 'dayjs/plugin/utc'
import ButtonPrimary from '../buttons/ButtonPrimary'
import moment from "moment";
import { cn } from '../../../../lib/utils'
import InputRadioBox from '../inputs/InputRadioBox'
import Slider from 'react-slick'
import "dayjs/locale/zh-cn"


dayjs.extend(utcPlugin);
dayjs.extend(customParseFormat);




function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <button onClick={onClick} className={cn('absolute top-1/2 -translate-y-1/2 end-1 h-7 w-7 flex items-center justify-center border-none', className)}><RightSmallArrowIcon className="w-3.5 h-3.5 text-white" /></button>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button onClick={onClick} className={cn('absolute top-1/2 z-10 -translate-y-1/2 start-1 h-7 w-7 flex items-center justify-center border-none', className)}><LeftSmallArrowIcon className="w-3.5 h-3.5 text-white" /></button>
    );
}

const TimeSlider = ({ t,selectedTime,setSelectedTime, className, sliderItemClassName, iconClassName, ...props }) => {

    const slider = useRef()
    const nextSlide = () => {
        if (slider.current) {
            slider.current.slickNext();
        }
    };

    const prevSlide = () => {
        if (slider.current) {
            slider.current.slickPrev();
        }
    };





    const generateTimeOptions = () => {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const formattedHour = hour.toString().padStart(2, '0');
                const formattedMinute = minute.toString().padStart(2, '0');
                options.push(`${formattedHour}:${formattedMinute}`);
            }
        }
        return options;
    };

    const timeOptions = generateTimeOptions();



    const singleSlideScroll = {
        slidesToShow: 9,
        slidesToScroll: 1,
        speed: 10,
        dots: false,
        infinite: false,
        vertical: true,
        verticalSwiping: true,
        arrows: false
    }


    return (


        <div className={cn(`rounded-[10px] px-3.5 flex justify-center items-center w-full flex-col`, className)}>
            <div className='py-2.5 border-b-[2px] border-success-100 w-full text-center'>
                <h6 className="mb-0 text-[15px] font-semibold text-blue-950">
                    {t('comman_text.time')}
                </h6>
            </div>
            <div className='flex flex-col justify-center  items-center w-full px-3.5 pt-2'>
                <button className={cn("flex items-center justify-center")} onClick={prevSlide}>
                    <LeftSmallArrowIcon className={cn(`w-5 h-5 rotate-90 text-blue-950`, iconClassName)} />
                </button>
                <div className='flex justify-center items-center'>
                    <Slider {...singleSlideScroll} className="time_slider slider_custom_arrow max-w-[150px] h-full max-h-[250px] overflow-hidden" ref={slider}>
                        {timeOptions.map((time, ind) => {
                            return (
                                <div className="slider_item" >
                                    <InputRadioBox
                                        groupClassName="p-2 h-[40px]"
                                        name="time"
                                        id={ind}
                                        text={time}
                                        labelClassName={"text-sm"}
                                        value={time}
                                        checked={selectedTime === time ? true : false}
                                        className={cn(`before:hidden after:hidden peer-checked:bg-success-950 px-4 w-full flex justify-center items-center py-2 peer-checked:text-white text-[17px] rounded-md font-400 peer-checked:font-semibold`, sliderItemClassName)}
                                        onChange={(e) => setSelectedTime(e.target.value)}
                                    />
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <button className={cn("flex items-center justify-center")} onClick={nextSlide}>
                    <RightSmallArrowIcon className={cn(`w-5 h-5 rotate-90 text-blue-950`, iconClassName)} />
                </button>
            </div>
        </div>
    )
}

export default withTranslation()(TimeSlider)