import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LoginIcon from '../../view/uiComponents/icons/header-footer-icon/LoginIcon';
import RegisterIcon from '../../view/uiComponents/icons/header-footer-icon/RegisterIcon';
import { InputLanguageSwitch } from '../../view/uiComponents/inputs/InputLanguageSwitch';
import LogoImageIcon from '../component/LogoImageIcon';
import UserLoginDropDownMenu from '../component/UserLoginDropDownMenu';
import WithoutLoginMenuData from '../menuData/WithoutLoginMenuData';
import { authStore } from '../../contexts/AuthProvider';
import CustomerLoginMenuData from '../menuData/CustomerLoginMenuData';

function FrontHeader({ t, ...props }) {
    const isAuthenticated = authStore((state) => state?.isAuthenticated)
    return (
        <>
            <header className='fixed top-0 w-full z-[999]'>
                <div className='bg-blue-500 w-full h-[65px] md:h-auto'>
                    <LogoImageIcon className="w-[130px] h-[90px] absolute start-0" />

                    <div className='hidden md:flex justify-end items-center gap-7 py-[15px] text-white pe-8'>
                        {!isAuthenticated ?
                            <>
                                <Link className='flex gap-2 font-semibold items-center' to={"/login"}>
                                    <LoginIcon className="w-5 h-5" />
                                    <span>{t('comman_text.login')}</span>
                                </Link>
                                <Link className='flex gap-2 font-semibold items-center' to={"/register"}>
                                    <RegisterIcon className="w-5 h-5" />
                                    <span>{t('comman_text.register')}</span>
                                </Link>
                            </> : <></>
                        }
                        <InputLanguageSwitch className="w-[100px]" />
                        <UserLoginDropDownMenu data={WithoutLoginMenuData} />
                    </div>
                </div>
            </header>
        </>
    )
}

export default withTranslation()(FrontHeader)
