import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import UserLineIcon from '../../../../uiComponents/icons/line-icon/UserLineIcon';
import { StepsWithTitle } from '../../../../uiComponents/StepsWithTitle';
import MenuTabs from '../../../../uiComponents/tabs/MenuTabs';
import EducationExpertise from '../component/EducationExpertise';
import PersonalInformation from '../component/PersonalInformation';
import WorkerAvailability from '../component/WorkerAvailability';
import WorkerProfile from '../component/WorkerProfile';
import PaymentInformation from '../component/PaymentInformation';
import AccountSettings from '../component/AccountSettings';
import BusinessProfile from '../../salonProfile/component/BusinessProfile';
import Availability from '../../salonProfile/component/Availability';
import Services from '../../salonProfile/component/Services';
import AddWorker from '../../salonProfile/component/AddWorker';
import Finance from '../../salonProfile/component/Finance';
import { useQuery } from 'react-query';
import Http from '../../../../../../Http';
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios';
import { authStore } from '../../../../../contexts/AuthProvider';
import { useLocation } from 'react-router-dom';

const BothProfile = (props) => {
    const { t } = props
    const { state } = useLocation();
    const salonId = authStore((state) => state.salonDataId);
    const salopStep = authStore((state) => state.salonProfileStep);
    const updateSaloneDataId = authStore((state) => state.updateSaloneDataId);
    const is_profile_complete = authStore((state) => state.is_profile_complete);
    const becomeWorkerStep = authStore((state) => state.becomeWorkerStep);

    const currentStep = salopStep == 0 || !salopStep ? 1 : salopStep && state ? state.selectedTab : salopStep


    const [step, setStep] = useState(salopStep == 0 || !salopStep ? 1 : salopStep && state ? state.selectedTab : salopStep);
    // const [current, setCurrent] = useState(salopStep == 0 || !salopStep ? 1 : salopStep && state ? state.selectedTab : salopStep);

    const [salonAvailability, setSalonAvailability] = useState([]);
    const [onTheGoAvailability, setOnTheGoAvailability] = useState([]);
    const [salonUnAvailability, setSalonUnAvailability] = useState([]);

    const [serviceLocation, setServiceLocation] = useState(false);
    const [salonLocation, setSalonLocation] = useState(false);

    const { data: userProfileData2, refetch: userProfile2 } = useQuery(
        ["userProfileSetting2", salonId],
        async () => {
            const response = await Http.post(PROFILE_COMPLETE_URL.getProfile, {
                salon_id: salonId
            }).then((res) => res.data);

            if (response && response?.data?.availability) {
                if (response?.data?.availability?.salon?.length) {
                    setSalonAvailability(response.data.availability.salon);
                }
                if (response?.data?.availability?.onthego?.length) {
                    setOnTheGoAvailability(response.data.availability.onthego);
                }
                if (response?.data?.unavailability?.length) {
                    response?.data?.unavailability.map((unavailability) => {
                        unavailability.date = unavailability.holiday_date
                        unavailability.salon = unavailability.service_location == 3 || unavailability.service_location == 1 ? true : false;
                        unavailability.onTheGo = unavailability.service_location == 3 || unavailability.service_location == 2 ? true : false;
                    });
                    setSalonUnAvailability(response?.data?.unavailability);
                }
            }
            updateSaloneDataId(response.data.id);
            return response;
        },
        { refetchOnWindowFocus: false }
    );


    const { data: salonAvlData, refetch: saloneGetList } = useQuery(
        ["getSalonAvailability2", salonId],
        async () => {
            if (salonId) {
                return await Http.get(PROFILE_COMPLETE_URL.salonAvailablity, { params: { salon_id: salonId } }).then(
                    (res) => res?.data?.data
                );
            }
        },
        { refetchOnWindowFocus: false }
    );
    const { data: workerProfileData2, refetch } = useQuery(
        ["workerProfileData2", salonId],
        async () => {
            return await Http.post(PROFILE_COMPLETE_URL.workerProfile, {
                "salon_id": salonId,
                'is_dummy': !salonId ? true : false
            }).then(
                (res) => {
                    // setLoadingScreen(false);
                    return res?.data?.data
                }
            );
        },
        { refetchOnWindowFocus: false }
    );

    useEffect(() => {
        if (workerProfileData2) {
            if (workerProfileData2?.service_location && workerProfileData2?.service_location != 3) {
                setServiceLocation(workerProfileData2?.service_location);
            } else {
                setServiceLocation(workerProfileData2?.worker?.service_location)
            }
            setSalonLocation(workerProfileData2?.service_location ? workerProfileData2?.service_location : false)
            if (is_profile_complete && step < 6) {
                setStep((becomeWorkerStep.length == 1 || becomeWorkerStep?.includes(5) ? 1 : state && state?.selectedTab ? state?.selectedTab : Math.max(...becomeWorkerStep)) + 5);
            }
        }
    }, [workerProfileData2])

    const handelNextClick = () => {
        setStep(step + 1);
        userProfile2()
        saloneGetList()
    };

    const handelDummyNextClick = () => {
        setStep(step + 2);
    };

    useEffect(() => {
        if (is_profile_complete == true) {
            userProfile2()
            saloneGetList()
        }
    }, [is_profile_complete])

    const AuthTabsRegister = [
        {
            id: "1",
            icon: <UserLineIcon className="w-6 h-6 md:w-8 md:h-8" />,
            title: t('comman_text.profile')
        },
        {
            id: "2",
            icon: <UserLineIcon className="w-6 h-6 md:w-8 md:h-8" />,
            title: 'Salon Availability',
            disabled: is_profile_complete == 0 && salopStep < 2
        },
        {
            id: "3",
            icon: <UserLineIcon className="w-6 h-6 md:w-8 md:h-8" />,
            title: 'Services',
            disabled: is_profile_complete == 0 && salopStep < 3
        },
        {
            id: "4",
            icon: <UserLineIcon className="w-6 h-6 md:w-8 md:h-8" />,
            title: 'Workers',
            disabled: is_profile_complete == 0 && salopStep < 4
        },
        {
            id: "5",
            icon: <UserLineIcon className="w-6 h-6 md:w-8 md:h-8" />,
            title: "Finance",
            disabled: is_profile_complete == 0 && salopStep < 5
        },
        {
            id: "6",
            icon: <UserLineIcon className="w-6 h-6 md:w-8 md:h-8" />,
            title: "Worker Profile",
            disabled: is_profile_complete && !becomeWorkerStep?.includes(1) ? true : false
        },
        {
            id: "7",
            icon: <UserLineIcon className="w-6 h-6 md:w-8 md:h-8" />,
            title: t("comman_text.worker_availability"),
            disabled: is_profile_complete && !becomeWorkerStep?.includes(2) ? true : false
        },
        {
            id: "8",
            icon: <UserLineIcon className="w-6 h-6 md:w-8 md:h-8" />,
            title: t("comman_text.personal_information"),
            disabled: is_profile_complete && !becomeWorkerStep?.includes(3) ? true : false
        },
        {
            id: "9",
            icon: <UserLineIcon className="w-6 h-6 md:w-8 md:h-8" />,
            title: t("comman_text.education_expertise"),
            disabled: is_profile_complete && !becomeWorkerStep?.includes(4) ? true : false
        },
        {
            id: "10",
            icon: <UserLineIcon className="w-6 h-6 md:w-8 md:h-8" />,
            title: t("comman_text.payment"),
            disabled: is_profile_complete && !becomeWorkerStep?.includes(5) ? true : false
        }
    ]
    return (
        <div className="main-content-wrap">
            <StepsWithTitle stepData={AuthTabsRegister} step={step} setStep={setStep} />
            <MenuTabs tabsData={AuthTabsRegister} isClickEvent={true} step={step} setStep={setStep} />
            {step === 1 &&
                <BusinessProfile
                    stepsNextClick={handelNextClick}
                    userProfileData={userProfileData2}
                    salonAvailability={salonAvailability}
                    onTheGoAvailability={onTheGoAvailability}
                    salonUnAvailability={salonUnAvailability}
                    refetch={userProfile2}
                />}
            {step === 2 && <Availability
                stepsNextClick={handelNextClick}
                salonAvailabilityData={salonAvailability}
                onTheGoAvailabilityData={onTheGoAvailability}
                salonUnAvailabilityData={salonUnAvailability}
                refetch={userProfile2}
            />}
            {step === 3 && <Services
                stepsNextClick={handelNextClick}
                userProfileData={userProfileData2}
                refetch={userProfile2}
                profile_setup={true}
            />}
            {step === 4 && <AddWorker stepsNextClick={handelNextClick} />}
            {step === 5 && <Finance step={step} setStep={setStep} />}

            {/* ---------------------------worker ---------------------------- */}
            {(step === 6 && Boolean(is_profile_complete)) &&
                <WorkerProfile step={step} setStep={setStep} stepsNextClick={handelNextClick}
                    workerProfileData={workerProfileData2}
                    serviceLocation={serviceLocation} salonLocation={salonLocation} handelDummyNextClick={handelDummyNextClick} />}
            {(step === 7 && Boolean(is_profile_complete)) &&
                <WorkerAvailability step={step} setStep={setStep} stepsNextClick={handelNextClick}
                    salonListData={salonAvlData}
                    serviceLocation={serviceLocation} />}
            {(step === 8 && Boolean(is_profile_complete)) &&
                <PersonalInformation step={step} setStep={setStep} handelDummyNextClick={handelDummyNextClick} stepsNextClick={handelNextClick} />}
            {(step === 9 && Boolean(is_profile_complete)) &&
                <EducationExpertise step={step} setStep={setStep} stepsNextClick={handelNextClick} />}
            {(step === 10 && Boolean(is_profile_complete)) &&
                <PaymentInformation step={step} setStep={setStep} stepsNextClick={handelNextClick} />}
        </div>
    )
}

export default withTranslation()(BothProfile)