import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary';
import CloseIcon from '../uiComponents/icons/CloseIcon';
import { withTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import { PATH_BOTH, PATH_PROVIDER } from '../../routes/path';
import { authStore } from '../../contexts/AuthProvider';

const NewSalonJoinRequestModal = ({ modalOpen, setModalOpen, newWorkerJoinList, refetchWorkerData,profile_setup, t={t}}) => {

    const navigate = useNavigate();
    const is_profile_complete = authStore((state) => state?.is_profile_complete)
    const profileType = authStore((state) => state?.profileType)

    const handleGotIt = async () => {
        setModalOpen(false);
        refetchWorkerData();
        if(is_profile_complete){
            navigate(PATH_PROVIDER.salonProfile,{state:{selectedTab:5}});
        }

        if(profileType == 2){
            if(profile_setup){
                navigate(PATH_PROVIDER.salonProfile, {state:{selectedTab:5}})
            }else{
                navigate(PATH_PROVIDER.editSalon, { state: { selectedTab: 4 }})
            }
        }else if(profileType == 4){
            navigate(PATH_BOTH.bothProfile, {state:{selectedTab:5}})
        }
    };

    return (
        <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[9999]" onClose={() => setModalOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <form id={"add-bulk-address"} className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[560px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start align-middle shadow-110 transition-all">
                                <div className="modal-header pe-7 mb-6">
                                    <h2 className="font-semibold text-xl text-center text-blue-950">{t('workers_added_modal.workers_added')}</h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setModalOpen(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body flex flex-col justify-center items-center">
                                    <div className="flex flex-col justify-center items-center gap-4 mb-4">
                                        <h5 className='text-center text-base font-normal text-blue-950'>{t('workers_added_modal.new_members_added')}</h5>
                                        <div className='text-center'>
                                            <p className='text-base font-medium text-blue-950 mb-2'>{t('workers_added_modal.new_members')}:</p>
                                            {newWorkerJoinList?.map((worker, index) => (
                                                !worker.worker_id && (
                                                    <p key={index} className='text-base font-medium text-blue-950 mb-1'>- {worker.worker_email} </p>
                                                )))
                                            }
                                        </div>
                                        <h5 className='text-center text-base font-normal text-blue-950'>{t('workers_added_modal.if_worker_does_not_have_acc')}</h5>
                                    </div>
                                    <ButtonPrimary type="button" onClick={handleGotIt} size="sm" className="w-full max-w-[250px] mx-auto" >{t('workers_added_modal.got_it')}</ButtonPrimary>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </form>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withTranslation()(NewSalonJoinRequestModal);