import React, { useState } from 'react'
import InputCheckbox from '../inputs/InputCheckbox';
import LabelWrap from '../inputs/LabelWrap';
import { withTranslation } from 'react-i18next';
import TrustedBadgeIcon from '../icons/badge-icon/TrustedBadgeIcon';
import StudentBadgeIcon from '../icons/badge-icon/StudentBadgeIcon';
import SkilledBadgeIcon from '../icons/badge-icon/SkilledBadgeIcon';
import InfoFIllIcon from '../icons/InfoFIllIcon';
import Badge2 from './Badge2';


function BadgeEarnedFilter({ t, checkedList, setCheckedList, badgeEarnedList, allCheckLabel, groupClassname, checkStart, ...props }) {

    const handleCheckboxChange = (option) => {
        if (checkedList?.includes(option)) {
            setCheckedList(checkedList?.filter(item => item !== option))
        } else {
            setCheckedList([...checkedList, option]);
        }
    };





    return (
        < div className="flex flex-wrap w-full gap-4" >
            {
                badgeEarnedList?.map((item, index) => (
                    <InputCheckbox
                        size={"lg"}
                        id={`item_id_____${index + 1}`}
                        key={index}
                        groupClassName={"w-[165px] h-full"}
                        className={`rounded-lg  xl:rounded-2xl before:absolute peer-checked:before:bg-success-800 peer-checked:before:border-success-800 before:border-0 before:rounded-full items-center gap-1 py-1 md:py-1.5 px-2 md:px-2.5 border-[1.3px] border-success-100 w-full font-medium transition-all duration-300 h-full ${checkStart ? "before:-start-1.5  before:-top-1.5 peer-checked:after:!start-0 peer-checked:after:!-top-1" : "before:-end-1.5  before:-top-1.5 peer-checked:after:!start-[unset] peer-checked:after:-end-0 peer-checked:after:!-top-1"}`}
                        text={
                            <>
                                <Badge2 data={item} groupClassName='border-0 p-0 gap-1 w-full' className='text-blue-950 text-sm text-center w-[80px]' />
                            </>
                        }
                        value={`item_id_____${index + 1}`}
                        checked={checkedList.includes(`item_id_____${index + 1}`) ? true : false}
                        onChange={(item) => handleCheckboxChange(item.target.value)}
                    />
                ))
            }
        </div >
    )
}

export default withTranslation()(BadgeEarnedFilter);
