import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { cn } from '../../../../../lib/utils'
import WorkerProtfolioModal from '../../../modals/WorkerProtfolioModal'
import ButtonPrimary from '../../../uiComponents/buttons/ButtonPrimary'
import Card from '../../../uiComponents/Card'
import AboutUsDeatils from '../../../uiComponents/common/AboutUsDeatils'
import BadgeSlider from '../../../uiComponents/common/BadgeSlider'
import CommonSliderWrapper from '../../../uiComponents/common/CommonSliderWrapper'
import Rating from '../../../uiComponents/common/Rating'
import ReviewDetails from '../../../uiComponents/common/ReviewDetails'
import Divider from '../../../uiComponents/Divider'
import SkilledBadgeIcon from '../../../uiComponents/icons/badge-icon/SkilledBadgeIcon'
import StudentBadgeIcon from '../../../uiComponents/icons/badge-icon/StudentBadgeIcon'
import TrustedBadgeIcon from '../../../uiComponents/icons/badge-icon/TrustedBadgeIcon'
import HeartIcon from '../../../uiComponents/icons/line-icon/HeartIcon'
import StarIcon from '../../../uiComponents/icons/StarIcon'
import RowWrap from '../../../uiComponents/RowWrap'
import CustomTabs from '../../../uiComponents/tabs/CustomTabs'
import SliderTabs from '../../../uiComponents/tabs/SliderTabs'
import SpecialityCard from '../../front/components/SpecialityCard'
import CustomerDetails from './CustomerDetails'
import CustomerSkills from './CustomerSkills'
import SpecialitiesCompo from './SpecialitiesCompo'
import avatarUser from '../../../../../assets/images/user-image/avtar-user.png';
import { authStore } from '../../../../contexts/AuthProvider'
import Tooltip from '../../../uiComponents/Tooltip'


const WorkerCard = ({ t, colored, workerData, badgeTextClass, tabsData, badgeListData, badgeSlider, groupClassName, showActionBtn = false, btnGroupClassName, iconArrangeChildLast, cardTitleClass, ratingStartListClass, ratingStatListItemClass, starIconClass, startTextClass,
    ...props }) => {

    const [WorkerProtfolioModalOpen, setWorkerProtfolioModalOpen] = useState(false)
    const profileType = authStore((state) => state.profileType);
    const tabData = tabsData ?? [
        ...(workerData?.provider?.about_me ? [{
            id: "1",
            title: t('form_input_label.about_us'),
            content: <AboutUsDeatils
                groupClassName="p-2 text-center"
                text={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id a harum illo, natus sunt voluptatem cumque beatae aut vitae iure sapiente veniam, in sit quod dignissimos quas. Fuga quas, assumenda neque perspiciatis dolore reiciendis quis deleniti tempora! Consectetur, quis. Provident, eveniet hic ullam assumenda quisquam repudiandae eos iste nemo blanditiis corrupti reprehenderit debitis unde possimus soluta aliquid ut perspiciatis dolores quam minima quis impedit sit reiciendis!"}
                Data={workerData?.provider?.about_me}
                handelClickEvent={false}
            />
        }] : []),
        {
            id: "2",
            title: t('comman_text.reviews'),
            content: <ReviewDetails
                headerLeftPartClass={"items-center"}
                headerLeftTextClass={"text-sm font-medium"}
                headerReviewTextClass={"text-sm font-medium"}
                ratingStartIconClass={"w-3.5 h-3.5"}
                ratingGroupClass={"gap-0.5"}
                genderIconClass={"h-5"}
                headerLeftTextClassName={"leading-normal"}
                prevArrowClassName="h-[26px] w-[26px] flex items-center justify-center cursor-pointer border-[1.6px] bg-primary1 border-primary1 text-white -start-0.5"
                nextArrowClassName="h-[26px] w-[26px] flex items-center justify-center cursor-pointer border-[1.6px] bg-primary1 border-primary1 text-white -end-1.5"
                arrowIconClass="w-3.5 h-3.5"
                item={3}
                slidesToShow={1}
                handelClickEvent={false}
            />
        },
    ];

    const BadgeList = badgeListData ?? (workerData?.worker_badges?.length > 0 ? workerData.worker_badges.map((badges, ind) => {
        return {
            id: badges.id.toString(),
            title: (
                <>
                    <div className='flex gap-1'>
                        <Tooltip title={badges?.badge?.name}>
                            <span>{badges?.badge?.name}</span>
                        </Tooltip>
                        <span>{badges?.badge?.name}</span>
                    </div>
                </>
            ),
            badge: badges?.badge?.image ? <img src={badges.badge.image} className='h-[76px] w-[76px]' alt={badges.badge.name} /> : null
        };
    }) : []);
    const smtabdata = [
        {
            id: "1",
            title: 'About Us',
            content: <AboutUsDeatils groupClassName="p-2 text-center" text={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id a harum illo, natus sunt voluptatem cumque beatae aut vitae iure sapiente veniam, in sit quod dignissimos quas. Fuga quas, assumenda neque perspiciatis dolore reiciendis quis deleniti tempora! Consectetur, quis. Provident, eveniet hic ullam assumenda quisquam repudiandae eos iste nemo blanditiis corrupti reprehenderit debitis unde possimus soluta aliquid ut perspiciatis dolores quam minima quis impedit sit reiciendis! Aspernatur modi voluptas commodi odit consequuntur consectetur distinctio fugit rem blanditiis architecto, unde necessitatibus qui optio ex omnis nesciunt, reiciendis officia perferendis. Ratione totam consequatur quam fugiat doloremque quos impedit tempora dolorem consectetur id."} colored={colored}
                Data={workerData?.provider?.about_me}
                handelClickEvent={false}/>
        },
        {
            id: "2",
            title: 'Reviews',
            content: <ReviewDetails
                headerLeftPartClass={"items-center"}
                headerLeftTextClass={"text-sm font-medium"}
                headerReviewTextClass={"text-sm font-medium"}
                ratingStartIconClass={"w-3.5 h-3.5"}
                ratingGroupClass={"gap-0.5"}
                genderIconClass={"h-5"}
                headerLeftTextClassName={"leading-normal"}
                prevArrowClassName="h-[26px] w-[26px] flex items-center justify-center cursor-pointer border-[1.6px] bg-primary1 border-primary1 text-white -start-0.5"
                nextArrowClassName="h-[26px] w-[26px] flex items-center justify-center cursor-pointer border-[1.6px] bg-primary1 border-primary1 text-white -end-1.5"
                arrowIconClass="w-3.5 h-3.5"
                item={3}
                smSlidesToShow={2}
                xsSlidesToShow={1}
                colored={colored}
            />
        },
        {
            id: "3",
            title: 'Specialities',
            content: <SpecialitiesCompo colored={colored}
                workers={workerData}/>
        },
        {
            id: "4",
            title: 'Skills',
            content: <CustomerSkills BadgeList={BadgeList} colored={colored} workerData={workerData}/>
        },
    ]

    return (
        <>
            {/* desktop view card*/}
            <Card className={cn('hidden lg:block sm:py-3.5', groupClassName)}>
                <div className="grid grid-cols-12 gap-1.5 xxl:gap-3">
                    <div className="col-span-2">
                        <div className="w-[100px] h-[100px] xl:w-[146px] xl:h-[146px] mx-auto relative">
                            {
                                (HeartIcon && profileType == "") &&
                                <button className='absolute top-0 left-1.5 bg-white w-[37px] h-[37px] rounded-full overflow-hidden flex items-center justify-center border border-borderColor'>
                                    <HeartIcon className="w-6 h-6 text-blue-800 mt-1 ms-0.5" />
                                </button>
                            }
                            <div className="w-full h-full rounded-full overflow-hidden">
                                <img src={workerData?.provider?.profile ? workerData?.provider?.profile : avatarUser} alt="" className='object-cover object-center w-full h-full' />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-4 flex flex-col gap-1 xxl:gap-2">
                        <div className={`p-1 xxl:px-2 border border-borderColor rounded-[10px] ${colored ? "bg-light-800" : "bg-white"}`}>
                            <div className="flex justify-between items-center gap-2">
                                <h3 className={cn('font-semibold text-blue-950 text-xl !leading-[1.2]', cardTitleClass)}>{workerData?.provider?.first_name} {workerData?.provider?.last_name}</h3>
                                <Rating starCount={1} rate={workerData?.rating} selected={1} starClass="w-5 h-5" wrapperClass="flex-row gap-2 p-0 border-0 me-3.5" textClassName="m-0 font-medium text-blue-950 leading-[20px]" />
                            </div>
                            <Divider className="my-1" />
                            <ul className={cn('grid grid-cols-2 gap-1 w-full', ratingStartListClass)}>
                                <li className={cn("flex items-center gap-2", ratingStatListItemClass)}>
                                    <StarIcon className={cn('icon h-3.5 w-3.5 flex-shrink-0 text-success-800', starIconClass)} />
                                    <p className={cn('text-[13px] leading-none text-blue-950', startTextClass)}>{workerData?.rating?.worker ?? 0} {t("check_box_label.worker")}</p>
                                </li>
                                <li className={cn("flex items-center gap-2 justify-end", ratingStatListItemClass)}>
                                    <StarIcon className={cn('icon h-3.5 w-3.5 flex-shrink-0 text-success-800', starIconClass)} />
                                    <p className={cn('text-[13px] leading-none text-blue-950', startTextClass)}>{workerData?.rating?.price_quality ?? 0} {t("check_box_label.quality_price")}</p>
                                </li>
                                <li className={cn("flex items-center gap-2", ratingStatListItemClass)}>
                                    <StarIcon className={cn('icon h-3.5 w-3.5 flex-shrink-0 text-success-800', starIconClass)} />
                                    <p className={cn('text-[13px] leading-none text-blue-950', startTextClass)}>{workerData?.rating?.service ?? 0} {t("check_box_label.service")}</p>
                                </li>
                                <li className={cn("flex items-center gap-2 justify-end me-1", ratingStatListItemClass)}>
                                    <StarIcon className={cn('icon h-3.5 w-3.5 flex-shrink-0 text-success-800', starIconClass)} />
                                    <p className={cn('text-[13px] leading-none text-blue-950', startTextClass)}> {workerData?.rating?.atmosphere ?? 0} {t("check_box_label.atmosphere")}</p>
                                </li>
                            </ul>
                        </div>
                        <div className={`p-1 xxl:px-2 border border-borderColor rounded-[10px] h-full ${colored ? "bg-light-800" : "bg-white"}`}>
                            <CustomerDetails setWorkerProtfolioModalOpen={setWorkerProtfolioModalOpen} workerData={workerData} />
                        </div>
                    </div>
                    <div className="col-span-2">
                        <SpecialityCard iconArrangeChildLast={iconArrangeChildLast} title={t('comman_text.specialities')} groupClassName={`flex-col gap-1.5 px-2 py-2.5 ${colored ? "bg-light-800" : "bg-white"}`} titleClassName="sm:py-1" listClassName="md:flex-col gap-1" workerSpecialities={workerData?.services_id} handelClickEvent={false} />
                    </div>
                    <div className="col-span-4 ">
                        <CustomTabs
                            tabsData={tabData}
                            tabGroupClass={`h-full`}
                            tabListClass={`border border-success-950 mb-2 w-[165px] lg:w-[180px] mx-auto text-sucess-900 rounded-[10px]`}
                            tabButtonClass='!p-1 sm:text-[15px] md:text-[15px] lg:text-[15px] text-[14px] text-success-900'
                            tabItemClass='h-full'
                            tabPanelsClass='h-[calc(100%-35px)]'
                        />
                    </div>
                </div>

                <div className="grid grid-cols-12 gap-1.5 xxl:gap-3 mt-2">
                    <div className="col-span-6">
                        <RowWrap className={`h-full grid gap-7 grid-cols-12 border border-borderColor rounded-[10px] px-3 py-2 ${colored ? "bg-light-800" : "bg-white"}`} sm={2}>
                            <div className="col-span-4 flex flex-col items-center justify-center ms-4">
                                <p className='text-blue-950 text-[15px] mb-1 -ms-2'>{t('comman_text.certificate')}</p>
                                <CommonSliderWrapper SliderClassName={cn(workerData?.educations?.length > 0 ? "[&_.slick-track>.slick-slide]:!mx-auto [&_.slick-track>.slick-slide]:!size-[60px]" : "")} slidesToShow={1} countClassName="-ms-3.5 text-blue-950 text-[15px]" arrowBtnGroupClassName="mt-1.5" hideBtnOnFirstLastSlide={true} defaultArrows={true} arrowButton={"hidden"} sildNoShow={workerData?.educations?.length > 0 ? true : false} groupClassName="max-w-[170px] xxl:max-w-[200px] flex-shrink-0 w-full" nextArrowClassName="bg-primary1 text-white w-7 h-7 xxl:w-9 xxl:h-9 end-5 xl:end-3 border-primary1" prevArrowClassName="bg-primary1 text-white w-7 h-7 xxl:w-9 xxl:h-9 start-6 xl:start-4 border-primary1">
                                    {
                                        workerData?.educations?.length > 0 ? workerData?.educations?.map((items) => (
                                            // <StudentBadgeIcon className='h-[60px] w-[60px]' />
                                            <img src={items?.front_image} className='block mx-auto !size-[60px] flex-shrink-0' />
                                            // {workerData?.provider?.profile}
                                        )) : <p className='font-light text-center text-sm text-blue-950 ms-1'>No Certificate</p>
                                    }
                                </CommonSliderWrapper>
                            </div>
                            {/* --------------------no education------------- */}
                            {/* <div className="col-span-8 text-center h-full w-full">
                                <h5 className='font-light text-[15px] text-blue-950'>No Education</h5>
                                <Divider className="my-2" />
                                <div className="flex items-center justify-center h-[calc(100%-34px)]">
                                    <p className='font-light text-sm text-blue-950'>No Education</p>
                                </div>
                            </div> */}

                            {/* -----------------education exist ------------------ */}

                            <div className="col-span-8 text-center h-full w-full">
                                <h5 className='font-light text-[15px]'>{t('form_input_label.name_study')} <span className='text-blue-950 opacity-40'> </span></h5>
                                <Divider className="my-2" />
                                <div className="flex items-center justify-center h-[calc(100%-34px)]">
                                    <p className='font-light text-[14px] text-blue-950'>
                                        {
                                            workerData?.educations?.length > 0 ?
                                                workerData?.educations.map((education) => education.keywords).join(" - ")
                                                : "No Education"
                                        }
                                    </p>
                                </div>
                            </div>
                        </RowWrap>
                    </div>
                    {workerData?.hair_type?.length > 0 &&
                        <>
                            <div className={`col-span-2 border border-borderColor rounded-[10px] p-2 text-center ${colored ? "bg-light-800" : "bg-white"}`}>
                                <h5 className='text-[15px] text-blue-950'>{t("form_input_label.hair_type")}</h5>
                                <Divider className="my-1.5" />
                                <div className="flex items-center justify-center h-[calc(100%-34px)]">
                                    <p className='font-light text-sm text-blue-950'>
                                        <ul>
                                            {workerData.hair_type.map(hair => (
                                                <li key={hair.id}>{hair.name}</li>
                                            ))}
                                        </ul>
                                    </p>
                                </div>
                            </div>

                        </>
                    }
                    <div className="col-span-4">
                        {badgeSlider && <BadgeSlider badgelist={workerData?.worker_badges} badgeGroupClassName={`flex-col gap-2 ${colored ? "bg-light-800" : "bg-white"}`} badgeTextClass={badgeTextClass} handelClickEvent={false} />}
                    </div>
                </div>
                {
                    showActionBtn && <div className={cn('grid grid-cols-1 sm:grid-cols-2 md:flex items-center justify-center gap-3 md:gap-5 mt-4', btnGroupClassName)}>
                        <ButtonPrimary className={"w-full md:max-w-[200px] text-base lg:text-lg px-0"} size="sm" >See attachments (3)</ButtonPrimary>
                        <ButtonPrimary className={"w-full md:max-w-[200px] text-base lg:text-lg px-0 bg-danger-850 hover:bg-danger-900 border-danger-850 hover:border-danger-900"} size="sm" >Not Interrested</ButtonPrimary>
                        <ButtonPrimary className={"w-full md:max-w-[200px] text-base lg:text-lg px-0"} size="sm" > Contact</ButtonPrimary>
                        <ButtonPrimary className={"w-full md:max-w-[200px] text-base lg:text-lg px-0"} size="sm" variant={"outline"}>Add Worker</ButtonPrimary>
                    </div>
                }
            </Card>
            {/* mobile view card*/}
            <Card className={cn("lg:hidden block", groupClassName)}>
                <div className="">
                    <div className="flex items-center justify-center gap-5">
                        <div className="w-[100px] h-[100px] relative">
                            <button className='absolute top-0 left-1.5 bg-white w-[37px] h-[37px] rounded-full overflow-hidden flex items-center justify-center border border-borderColor'>
                                <HeartIcon className="w-6 h-6 text-blue-800 mt-1 ms-0.5" />
                            </button>
                            <div className="w-full h-full rounded-full overflow-hidden">
                                <img src={workerData?.provider?.profile} alt="" className='object-cover object-center w-full h-full' />
                            </div>
                        </div>
                        <div className="flex flex-col justify-center gap-2">
                            <h3 className={cn('font-semibold text-blue-950 text-xl !leading-[1.2]', cardTitleClass)}>{workerData?.provider?.first_name} {workerData?.provider?.last_name}</h3>
                            <Rating starCount={1} rate={4.5} selected={1} starClass="w-5 h-5" wrapperClass="flex-row gap-2 p-0 border-0 me-3.5" textClassName="m-0 font-medium text-blue-950 leading-[20px]" />
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className={`border border-borderColor rounded-lg p-1.5 my-2.5 w-full h-full md:order-2 order-1 ${colored ? "bg-light-800" : "bg-white"}`}>
                        <ul className={cn('grid grid-cols-2 sm:grid-cols-4 gap-1 w-full', ratingStartListClass)}>
                            <li className={cn("flex items-center gap-2", ratingStatListItemClass)}>
                                <StarIcon className={cn('icon h-3.5 w-3.5 flex-shrink-0 text-success-800', starIconClass)} />
                                <p className={cn('text-[13px] leading-none text-blue-950', startTextClass)}>4 Worker</p>
                            </li>
                            <li className={cn("flex items-center gap-2", ratingStatListItemClass)}>
                                <StarIcon className={cn('icon h-3.5 w-3.5 flex-shrink-0 text-success-800', starIconClass)} />
                                <p className={cn('text-[13px] leading-none text-blue-950', startTextClass)}>4 Quality/Price</p>
                            </li>
                            <li className={cn("flex items-center gap-2", ratingStatListItemClass)}>
                                <StarIcon className={cn('icon h-3.5 w-3.5 flex-shrink-0 text-success-800', starIconClass)} />
                                <p className={cn('text-[13px] leading-none text-blue-950', startTextClass)}>4 Service</p>
                            </li>
                            <li className={cn("flex items-center gap-2", ratingStatListItemClass)}>
                                <StarIcon className={cn('icon h-3.5 w-3.5 flex-shrink-0 text-success-800', starIconClass)} />
                                <p className={cn('text-[13px] leading-none text-blue-950', startTextClass)}>4 Atmosphere</p>
                            </li>
                        </ul>
                    </div>
                    <div className={`p-1.5 border border-borderColor rounded-[10px] h-full w-full md:order-1 order-2 ${colored ? "bg-light-800" : "bg-white"}`}>
                        <CustomerDetails setWorkerProtfolioModalOpen={setWorkerProtfolioModalOpen} workerData={workerData} />
                    </div>
                </div>
                <div className="py-2">
                    <CustomTabs tabsData={smtabdata} tabGroupClass={`h-full hidden sm:block`} tabListClass={`border border-success-950 mb-2  mx-auto text-sucess-900 rounded-[8px]`} tabButtonClass='!p-1 sm:text-[15px]  md:text-[15px] lg:text-[15px] text-[14px] text-success-900' tabItemClass='h-full' tabPanelsClass='h-[calc(100%-35px)]' />
                    <SliderTabs tabListClass="mb-2" tabButtonClass="py-1.5" tabsData={smtabdata} tabGroupClass='sm:hidden block' />
                </div>
                {workerData?.hair_type?.length > 0 &&
                    <div className={`border border-borderColor rounded-lg p-1.5 text-center ${colored ? "bg-light-800" : "bg-white"}`}>
                        <h5 className='text-[15px] text-blue-950'>{t("form_input_label.hair_type")}</h5>
                        <Divider className="my-1.5" />
                        <div className="flex items-center justify-center h-[calc(100%-34px)]">
                            <p className='font-light text-sm text-blue-950'>   <ul>
                                {workerData.hair_type.map(hair => (
                                    <li key={hair.id}>{hair.name}</li>
                                ))}
                            </ul></p>
                        </div>
                    </div>
                }
                <div className="mt-2">
                    {badgeSlider && Array.isArray(BadgeList) && BadgeList.length > 0 && (
                        <BadgeSlider
                            slidesToShow={2}
                            badgelist={workerData?.worker_badges}
                            badgeTextClass={badgeTextClass}
                            badgeGroupClassName={`flex-col gap-1.5 px-2 py-2.5 ${colored ? "bg-light-800" : "bg-white"}`}
                        />
                    )}
                </div>


                {
                    showActionBtn && <div className={cn('grid grid-cols-1 xsm:grid-cols-2 md:flex items-center justify-center !gap-y-2 gap-3 lg:gap-5 mt-4', btnGroupClassName)}>
                        <ButtonPrimary className={"w-full md:max-w-[200px] text-base lg:text-lg px-0"} size="sm" >See attachments (3)</ButtonPrimary>
                        <ButtonPrimary className={"w-full md:max-w-[200px] text-base lg:text-lg px-0 bg-danger-850 hover:bg-danger-900 border-danger-850 hover:border-danger-900"} size="sm" >Not Interrested</ButtonPrimary>
                        <ButtonPrimary className={"w-full md:max-w-[200px] text-base lg:text-lg px-0"} size="sm" > Contact</ButtonPrimary>
                        <ButtonPrimary className={"w-full md:max-w-[200px] text-base lg:text-lg px-0"} size="sm" variant={"outline"}>Add Worker</ButtonPrimary>
                    </div>
                }

            </Card>



            <WorkerProtfolioModal
                workerData={workerData}
                portfolioData={workerData?.protfolios}
                modalOpen={WorkerProtfolioModalOpen}
                setModalOpen={setWorkerProtfolioModalOpen}
            />
        </>
    )
}

export default withTranslation()(WorkerCard)