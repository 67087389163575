import React, { useEffect, useState } from 'react'
import Card from '../../../uiComponents/Card'
import ButtonPrimary from '../../../uiComponents/buttons/ButtonPrimary'
import FilterIcon from '../../../uiComponents/icons/FilterIcon'
import SortIcon from '../../../uiComponents/icons/SortIcon'
import PortfolioCard from '../../vendor_panel/profiles/component/PortfolioCard'
import SortByModal from '../../../modals/SortByModal'
import { withTranslation } from 'react-i18next'
import FindSalonProfileFilterModal from '../../../modals/FindSalonProfileFilterModal'
import PlusLineIcon from '../../../uiComponents/icons/PlusLineIcon'
import AddPortfolioShortModal from '../../../modals/AddPortfolioShortModal'
import AddPortfolioWithUploadImageModal from '../../../modals/AddPortfolioWithUploadImageModal'
import { useMutation } from 'react-query'
import { FRONT_API_URL } from '../../../../api/axios'
import Http from '../../../../../Http'
import { enqueueSnackbar } from 'notistack'
import { authStore } from '../../../../contexts/AuthProvider'


const SalonDetailsPortfolio = ({ t, salonData, addPortfolio, workerData, ...props }) => {

    const [FindSalonProfileFilterModalOpen, setFindSalonProfileFilterModalOpen] = useState(false)
    const [addPortfolioWithUploadImageModal, setAddPortfolioWithUploadImageModal] = useState(false)
    const [SortByModalOpen, setSortByModalOpen] = useState(false)
    const [addPortfolioShortModal, setAddPortfolioShortModal] = useState(false)
    const [selectedSortBy, setselectedSortBy] = useState([])
    const [filterData, setFilterData] = useState([]);
    const [filterResult, setFilterResult] = useState([]);
    const salonDataId = authStore((state) => state.salonDataId)
    const [servicesIds, setServicesIds] = useState([]);
    const [selectCategoryTpes, setCategorytype] = useState([]);


    const SortByOption = [
        {
            id: 'latest',
            label: 'Latest'
        },
        {
            id: 'oldest',
            label: 'Oldest'
        },
        {
            id: "review_asc",
            label: t("comman_text.review_asc"),
        },
        {
            id: "review_desc",
            label: t("comman_text.review_des"),
        },
        {
            id: "customer_served_asc",
            label: t("comman_text.customer_asc"),
        },
        {
            id: "customer_served_desc",
            label: t("comman_text.customer_desc"),
        },
    ]

    const totalPortfolioCount = workerData?.reduce((count, worker) => {
        return count + (worker.protfolios ? worker.protfolios.length : 0);
    }, 0);

    const onApplyFilters = async (filterData) => {
        setFilterData(filterData);
        try {
            const response = await handleFilerData(filterData);
            // Process the response as needed
        } catch (error) {
            console.error('Failed to apply filters', error);
        }
    };

    const { mutateAsync: handleFilerData } = useMutation(
        async (data) => {
            data.salon_id = salonDataId
            try {
                return await Http.post(FRONT_API_URL.portfolioFilter, data);

            } catch (error) {
                console.error('API Error:', error);
                throw error;
            }
        },
        {
            onSuccess: (response) => {

                setFilterResult(response?.data?.data);

                // console.log("api call success", response?.data?.data);
            },
            onError: (error) => {
                enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            },
        }
    );

    useEffect(() => {
        handleFilerData({})
    }, [])

    return (
        <div>
            <Card className={"mb-2.5 md:mb-4 sm:px-2.5 sm:py-[6.5px] py-[6.5px] px-2.5 my-2.5"}>
                <div className="flex items-center justify-between">
                    <h6 className='text-sm text-blue-950 leading-[1]'> {totalPortfolioCount} Portfolios</h6>
                    <div className="items-center gap-2 md:flex hidden">
                        {
                            addPortfolio && <ButtonPrimary size={"sm"} className={"py-1 px-3 rounded-md"} variant={"outline"} onClick={() => setAddPortfolioShortModal(true)}>
                                <span className='-mt-1'><PlusLineIcon className="w-5 h-5" /></span>
                                Portfolio
                            </ButtonPrimary>
                        }
                        <ButtonPrimary size={"sm"} className={"py-1 px-3 rounded-md"} onClick={() => setFindSalonProfileFilterModalOpen(true)}>
                            <span><FilterIcon className="w-5 h-5" /></span>
                            Filter
                        </ButtonPrimary>
                        <ButtonPrimary size={"sm"} className={"py-1 px-3 rounded-md"} onClick={() => setSortByModalOpen(true)}>
                            <span><SortIcon className="w-5 h-5" /></span>
                            Sort By
                        </ButtonPrimary>
                    </div>
                    <div className="items-center gap-2 md:hidden flex">
                        {
                            addPortfolio && <ButtonPrimary size={"sm"} className={"py-1 px-1 rounded-md"} variant={"outline"} onClick={() => setAddPortfolioShortModal(true)}>
                                <span className='-mt-1'><PlusLineIcon className="w-5 h-5" /></span>
                                Portfolio
                            </ButtonPrimary>
                        }
                        <ButtonPrimary size={"sm"} className={"!p-0 w-[41px] h-[37px] rounded-md"} onClick={() => setFindSalonProfileFilterModalOpen(true)}>
                            <span><FilterIcon className="w-5 h-5" /></span>
                        </ButtonPrimary>
                        <ButtonPrimary size={"sm"} className={"!p-0 w-[41px] h-[37px] rounded-md"} lick={() => setSortByModalOpen(true)}>
                            <span><SortIcon className="w-5 h-5" /></span>
                        </ButtonPrimary>
                    </div>
                </div>
            </Card>
            <Card className={"md:block hidden"}>
                <div className="grid grid-cols-2 xl:grid-cols-3 gap-5">
                    {filterData.length === 0 ? (
                        // If filterData.length === 0
                        <>
                            {/* {workerData?.map((portfolioData) => (
                                portfolioData.protfolios?.map((portfolio) => (
                                    <PortfolioCard
                                        key={portfolio.id}
                                        workerData={workerData.map(data)=> data.id === portfolio.id}
                                        PortfolioData={portfolio}
                                        hasActionBtns={false}
                                        colored
                                    />
                                ))
                            ))} */}
                            {workerData?.map((worker) =>
                                worker.protfolios?.map((portfolio) => {
                                    const matchingWorker = workerData.find(data => data.provider_id == portfolio.provider_id);
                                    return (
                                        <PortfolioCard
                                            key={portfolio.id}
                                            workerData={matchingWorker}
                                            PortfolioData={portfolio}
                                            hasActionBtns={false}
                                            colored
                                        />
                                    );
                                })
                            )}

                        </>
                    ) : (
                        filterResult?.map((portfolio) => (
                            <PortfolioCard
                                key={portfolio.id}
                                PortfolioData={portfolio}
                                hasActionBtns={false}
                                colored
                            />
                        ))
                    )}
                </div>
            </Card>
            <div className="">
                <Card className={"md:hidden block mb-2.5 md:mb-4"}>
                    {
                        workerData?.map((portfolioData) => (
                            portfolioData.protfolios?.map((portfolio) => (
                                <PortfolioCard
                                    key={portfolio.id}
                                    PortfolioData={portfolio}
                                    hasActionBtns={false}
                                    colored
                                />
                            ))
                        ))
                    }
                </Card>
            </div>




            <FindSalonProfileFilterModal
                modalOpen={FindSalonProfileFilterModalOpen}
                setModalOpen={setFindSalonProfileFilterModalOpen}
                salonData={salonData}
                servicesIds={servicesIds}
                setServicesIds={setServicesIds}
                setFilterData={setFilterData}
                onApplyFilters={onApplyFilters}
                setCategorytype={setCategorytype}
                selectCategoryTpes={selectCategoryTpes}
            />

            <SortByModal
                modalOpen={SortByModalOpen}
                setModalOpen={setSortByModalOpen}
                SortByOption={SortByOption}
                selectedSortBy={selectedSortBy}
                setselectedSortBy={setselectedSortBy}

            />
            <AddPortfolioShortModal addPortfolioShortModal={addPortfolioShortModal} setAddPortfolioShortModal={setAddPortfolioShortModal} />
            <AddPortfolioWithUploadImageModal addPortfolioWithUploadImageModal={addPortfolioWithUploadImageModal} setAddPortfolioWithUploadImageModal={setAddPortfolioWithUploadImageModal} />
        </div>
    )
}

export default withTranslation()(SalonDetailsPortfolio)