import React, { useState } from 'react';
import Slider from "react-slick";
import { cn } from '../../../../lib/utils';
import ShareModal from '../../modals/ShareModal';
import Card from '../Card';
import LeftSmallArrowIcon from '../icons/LeftSmallArrowIcon';
import HeartIcon from '../icons/line-icon/HeartIcon';
import ShareIcon from '../icons/line-icon/ShareIcon';
import RightSmallArrowIcon from '../icons/RightSmallArrowIcon';
import ServiceFacility from './ServiceFacility';
import { authStore } from '../../../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH, PATH_CUSTOMER } from '../../../routes/path';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <div onClick={onClick} className='absolute top-1/2 -translate-y-1/2 end-2 bg-white rounded-full h-8 w-8 flex items-center justify-center border border-success-100 cursor-pointer'><RightSmallArrowIcon className="w-4 h-4 text-blue-950" /></div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div onClick={onClick} className='absolute top-1/2 z-10 -translate-y-1/2 start-2 bg-white rounded-full h-8 w-8 flex items-center justify-center border border-success-100 cursor-pointer'><LeftSmallArrowIcon className="w-4 h-4 text-blue-950" /></div>
    );
}

const SalonSlider = ({ ServiceFacilityClassName, heartIcon, salonData, data, navigateUrl, handelClickEvent }) => {
    const navigate = useNavigate();
    const [shareModalOpen, setshareModalOpen] = useState(false)
    const profileType = authStore((state) => state.profileType);
    const { isAuthenticated } = authStore((state) => state)
    const shopSliderMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        dots: false,
    };
    return (
        <>
            <div className={cn("relative")} >
                <Slider {...shopSliderMain} className=''>
                    {salonData?.images?.map((image, index) => {
                        return (
                            <Card className={cn('border-[1.3px]  h-full p-0 sm:p-0 overflow-hidden relative max-h-[205px]',  handelClickEvent === false ? "" : "cursor-pointer")} onClick={() => handelClickEvent === false ? "" : navigate((isAuthenticated && profileType == "") ? PATH_CUSTOMER.findSalonProfile : PATH_AUTH.findSalonProfile, { 
                                state: { id: data?.salon_id || data?.id || salonData.id, data: salonData || data } 
                            })}>
                                <img src={image?.file} className='h-full w-full' alt="" />
                            </Card>
                        )
                    })}
                </Slider>
                <div className="absolute start-2 top-1.5 flex flex-col gap-1">
                    {
                        (heartIcon && profileType == "") &&
                        <div className="md:h-9 md:w-9  w-8 h-8 rounded-full bg-white border-[1.3px] border-success-100 flex items-center justify-center cursor-pointer">
                            <HeartIcon className='md:h-[22px] w-5 md:w-[22px] h-5 text-primary1 hover:fill-primary1' />
                        </div>
                    }
                    {
                        (ShareIcon && profileType == "") &&
                        <div className="md:h-9 md:w-9 w-8 h-8 rounded-full bg-white border-[1.3px] border-success-100 flex items-center justify-center cursor-pointer">
                            <ShareIcon onClick={() => setshareModalOpen(true)} className='md:h-[22px] w-5 md:w-[22px] h-5 text-primary1' />
                        </div>
                    }
                </div>
                <ServiceFacility salonData={salonData} className={cn('absolute top-1.5 end-2 md:hidden', ServiceFacilityClassName)} />
            </div>
            <ShareModal
                copyLink={"salonLink______________www.meetime/salon"}
                modalOpen={shareModalOpen}
                setModalOpen={setshareModalOpen}
            />
        </>
    );
}

export default SalonSlider;
