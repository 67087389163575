import React from 'react';
import Card from '../Card';
import { cn } from '../../../../lib/utils';
import SvgRender from './SvgRender';

const Badge2 = ({ badgeTitle, groupClassName, iconClassName, className, showTitle = true, data, contentClassName, ...props }) => {
    const language = localStorage.getItem('i18nextLng') || 'dk';

    return (
        <>
            <div className={cn('border-[1.3px] border-success-100 rounded-[10px] p-5 py-1.5 h-full flex items-center gap-3', groupClassName)}>
                <div className={cn('icon flex-shrink-0', iconClassName)}>
                    {/* {data?.badge || data?.icon} */}
                    <SvgRender filePath={data?.badge?.filter_image ?? data?.filter_image} iconClassName="size-16 mx-auto" />
                </div>
                <div className={cn("name text-center", contentClassName)}>
                    {(showTitle && !data) && <p className={cn(className)}>{language == 'en' ? badgeTitle?.name : badgeTitle?.dk_name}</p>}
                    {data && (
                        <p className={cn(className)}>
                            {language === 'en'
                            ? data?.badge?.name || data?.name
                            : data?.badge?.dk_name || data?.dk_name}
                        </p>
                    )}
                    {/* {showTitle && <p className={cn(className)}>{badgeTitle}</p>} */}
                    {/* {data.count && <p className={cn(className)}>{data?.count}</p>} */}
                </div>
            </div>
        </>
    );
}

export default Badge2;
