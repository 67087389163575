import { Disclosure } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useMutation } from "react-query";
import Slider from "react-slick";
import Http from "../../../../Http";
import { cn } from "../../../../lib/utils";
import { FRONT_API_URL } from "../../../api/axios";
import { authStore } from "../../../contexts/AuthProvider";
import ServiceAccordianList from "../../pages/front/components/ServiceAccordianList";
import ServiceSelectableCard from "../../pages/front/components/ServiceSelectableCard";
import ButtonPrimary from "../../uiComponents/buttons/ButtonPrimary";
import BottomArrowIcon from "../../uiComponents/icons/BottomArrowIcon";
import FilterIcon from "../../uiComponents/icons/FilterIcon";
import LeftSmallArrowIcon from "../../uiComponents/icons/LeftSmallArrowIcon";
import RightSmallArrowIcon from "../../uiComponents/icons/RightSmallArrowIcon";
import FacialTreatmentIcon from "../../uiComponents/icons/service-icon/FacialTreatmentIcon";
import HairRemovalIcon from "../../uiComponents/icons/service-icon/HairRemovalIcon";
import HairdresserIcon from "../../uiComponents/icons/service-icon/HairdresserIcon";
import InputAutocompleteWrap from "../../uiComponents/inputs/InputAutocompleteWrap";
import InputCheckbox from "../../uiComponents/inputs/InputCheckbox";
import LabelWrap from "../../uiComponents/inputs/LabelWrap";
import TextareaWrap from "../../uiComponents/inputs/TextareaWrap";
import SalonDetailsFilterModal from "../SalonDetailsFilterModal";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;


  return (
    <button
      onClick={onClick}
      className={cn(
        "absolute top-1/2 -translate-y-1/2 end-1 bg-primary1 rounded-full h-7 w-7 flex items-center justify-center border-none",
        className
      )}
    >
      <RightSmallArrowIcon className="w-3.5 h-3.5 text-white" />
    </button>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      onClick={onClick}
      className={cn(
        "absolute top-1/2 z-10 -translate-y-1/2 start-1 bg-primary1 rounded-full h-7 w-7 flex items-center justify-center border-none",
        className
      )}
    >
      <LeftSmallArrowIcon className="w-3.5 h-3.5 text-white" />
    </button>
  );
}

const OfferRequestFirstStep = ({
  t,
  activeStep,
  setActiveStep,
  handleModalClose,
  serviceList,
  servicesIds, salonService, /* promotionType, */ selectCategoryTpes,
  salonInfo,
  salonId,
  selectedIds,
  setSelectedIds,
  setBookingInfo,
}) => {
  // const serviceTypes = [
  //   {
  //     serviceTitle: "Hairdresser",
  //     serviceIcon: <HairdresserIcon className="w-8 h-8" />,
  //     services: [
  //       { id: 11, name: "Color" },
  //       { id: 12, name: "Balayage" },
  //     ],
  //   },
  //   {
  //     serviceTitle: "Manicure & Pedicure",
  //     serviceIcon: <FacialTreatmentIcon className="w-8 h-8" />,
  //     services: [
  //       { id: 13, name: "Service 11" },
  //       { id: 14, name: "Color 22" },
  //     ],
  //   },
  //   {
  //     serviceTitle: "Hair Removal",
  //     serviceIcon: <HairRemovalIcon className="w-8 h-8" />,
  //     services: [
  //       { id: 15, name: "Service 13" },
  //       { id: 16, name: "Service 14" },
  //     ],
  //   },
  //   {
  //     serviceTitle: "Physiotherapist",
  //     serviceIcon: <HairstylistIcon className="w-8 h-8" />,
  //     services: [
  //       { id: 17, name: "Service 15" },
  //       { id: 18, name: "Service 16" },
  //     ],
  //   },
  // ];

  const shopSliderMain = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [SalonDetailsFilterModalOpen, setSalonDetailsFilterModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState([]);
  const [serviceType, setServiceTypeData] = useState([]);
  const [serviceTypeChoose, setServiceTypeChoose] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryChoose, setCategoryChoose] = useState([]);
  const [address, setAddress] = useState('');
  const [addressLat, setAddressLat] = useState('');
  const [addressLong, setAddressLong] = useState('');
  const isAuthenticated = authStore((state) => state.isAuthenticated)
  const [count, setCount] = useState({});
  const [discountPrices, setDiscountPrices] = useState("");
  const [addressBooking, setAddressBooking] = useState("");
  const [reason, setReason] = useState("");
  let disscount_type_value;
  let [promotionType, setPromostionType] = useState('');

  useEffect(() => {
    serviceTypeData();
    salonService()
  }, [])

  const countArray = Object.entries(count).map(([id, countData]) => ({
    id,
    count: countData.count,
  }));

  function calculateDiscount(data, value, discount_for, originalPrice) {
    const test = value == 0 ? 1 : value;
    const price =
      (discount_for == 2 && data.student_type == 2) ||
      (discount_for == 3 && data.senior_type == 2) ||
      (discount_for == 1 && data.discount_type == 2);
    const percentage =
      (discount_for == 2 && data.student_type == 1) ||
      (discount_for == 3 && data.senior_type == 1) ||
      (discount_for == 1 && data.discount_type == 1);

    let type = "";
    if (discount_for == 1) {
      type = data.discount_type;
    }
    if (discount_for == 2) {
      type = data.student_type;
    }
    if (discount_for == 3) {
      type = data.senior_type;
    }
    let stPrice =
      discount_for == 1
        ? data.discount_price
        : discount_for == 2
          ? data.student_price
          : data.senior_price;
    if (!discountPrices) {
      stPrice = data.discount_price;
    }
    if (price && discount_for) {
      let calPrice = stPrice * test;
      let updatedOriginalPrice = originalPrice * test;
      return {
        price: data.price,
        discount: stPrice !== "0.00" ? +stPrice + " " : "",
        discountMinusData: (updatedOriginalPrice - calPrice).toFixed(2),
        originaldbPrice: updatedOriginalPrice,
        discount_for: discount_for,
        symbol: type,
      };
    }
    if (percentage && discount_for) {
      let calPrice = originalPrice * test;
      let discountPercentage = (calPrice * stPrice) / 100;
      let updatedOriginalPrice = originalPrice * test;
      return {
        price: data.price,
        discount: stPrice !== "0.00" ? +stPrice + "" : "",
        discountMinusData: (calPrice - discountPercentage).toFixed(2),
        originaldbPrice: updatedOriginalPrice,
        discount_for: discount_for,
        symbol: type,
      };
    }

    return {
      price: data.price,
    };
  }

  const { mutateAsync: serviceTypeData } = useMutation(
    async (data) => {
      try {
        const { data } = await Http.get(FRONT_API_URL.serviceType, {
          params: { salon_id: salonInfo?.id ?? "" },
        }).then((res) => res.data);
        if (data) {
          setServiceTypeData(data);
          if (data.length) {
            setServiceTypeChoose([data[0].id]);
            // subCategory([data[0].id]);
          }
        }
      } catch (error) {
        console.log(error);

      }
    }
  );

  const subCategory = async (id, value) => {
    return await Http.post(FRONT_API_URL.serviceCategory, {
      service_type_ids: serviceTypeChoose,
      salon_id: salonId ?? null,
    }).then((res) => {
      if (res.data?.data.length) {
        if (value) {
          salonService({
            service_type_ids: id,
            category_ids: "",
          });
        }
        setCategoryChoose(" ");
        setCategoryData(res.data.data);
      } else {
        setCategoryData([]);
      }
    });
  };

  const handleSelectChange = (e) => {
    setServiceTypeChoose([e]);
    subCategory([e], true);
  };

  useEffect(() => {
    if (serviceTypeChoose.length > 0) {
      subCategory();
    }
  }, [serviceTypeChoose]);

  const handleChangeAddress = (city, location) => {
    setAddress(city);
    setAddressLat(location?.lat())
    setAddressLong(location?.lng())
  }

  const handleDiscount = (value) => {
    if (!SalonDetailsFilterModalOpen) {
      setCount({});
      if (discountPrices === value) {
        setDiscountPrices('');
        disscount_type_value = '';
        setPromostionType('')
      } else {
        setDiscountPrices(value);
        disscount_type_value = value;
        setPromostionType(value)
      }
    }
    salonService();
  };

  const handleBooking = () => {
    if (isAuthenticated) {
      setActiveStep(2);
      setBookingInfo({
        'salon_id': salonId,
        'request_offer': {
          'worker_id': '',
          'services': countArray,
        },
        'discount_for': 2, // set temporary static value
        // 'service_location': onTheGoCheckd && onSalone ? 3 : onTheGoCheckd ? 2 : 1,
        'service_location': 2,
        'customer_note': reason,
        'service_address': addressBooking
      });
    } else {
      setActiveStep(activeStep + 1)
    }

  }

  const handleCheckBoxChange = (data, value) => {
    if (selectedIds?.includes(data.id)) {
      setSelectedIds(selectedIds.filter((id) => id !== data.id));
      handleChangeMinusCounter(data, value, "check");
      // salonService({ promotionType: disscount_type_value });
      // setCount((prevState) => {
      //     const newState = { ...prevState };
      //     delete newState[data?.id];
      //     return newState;
      // });
    } else {
      setSelectedIds([...selectedIds, data.id]);
      handleChangePlusCounter(data, value);
    }
  };

  const handleChangePlusCounter = (data, value) => {
    setSelectedIds([...selectedIds, data.id]);
    // value = value == 0 ? -1 : value;
    const updatedCount = value + 1;
    const discountData = calculateDiscount(
      data,
      updatedCount,
      discountPrices === "" ? data?.discount_type : discountPrices,
      data.price
    );

    setCount((prevState) => ({
      ...prevState,
      [data.id]: {
        count: updatedCount,
        ...discountData,
      },
    }));
  };

  const handleChangeMinusCounter = (data, value, check) => {
    let originalPrice = data.price;
    let serviceId = data.id;

    if (check == "check") {
      const updatedCount = 0;
      const discountData = calculateDiscount(
        data,
        updatedCount,
        discountPrices === "" ? data?.discount_type : discountPrices,
        originalPrice
      );

      setCount((prevState) => ({
        ...prevState,
        [serviceId]: {
          count: updatedCount,
          ...discountData,
        },
      }));
    } else {
      if (value === 1) {
        setCount((prevState) => ({
          ...prevState,
          [serviceId]: {
            count: 0,
            ...calculateDiscount(
              data,
              0,
              discountPrices === "" ? data?.discount_type : discountPrices,
              originalPrice
            ),
          },
        }));
        setSelectedIds((prevSelectedIds) =>
          prevSelectedIds.filter((id) => id !== serviceId)
        );
      } else if (value > 0) {
        const updatedCount = value - 1;
        const discountData = calculateDiscount(
          data,
          updatedCount,
          discountPrices === "" ? data?.discount_type : discountPrices,
          originalPrice
        );

        setCount((prevState) => ({
          ...prevState,
          [serviceId]: {
            count: updatedCount,
            ...discountData,
          },
        }));
      }
    }
  };

  const handleServiceAddress = (e) => {
    const values = e.target.value;
    setAddressBooking(values.trim())
  }

  const handleReason = (e) => {
    const values = e.target.value;
    setReason(values.trim())
  }

  return (
    <div className={`modal-body ${activeStep === 1 ? "block" : "hidden"}`}>
      <InputAutocompleteWrap
        className="rounded-lg"
        require
        size="sm"
        value={address}
        handleChange={handleChangeAddress}
        labelClassName={"text-[15px] w-full text-blue-950 "}
        label={t("comman_text.address_for_service")}
        placeholder={t("comman_text.input_address")}
      />

      <div className="flex gap-2 items-start py-3">
        <div className={"col-span-3 w-[240px] md:block hidden"}>
          <LabelWrap
            require
            labelClassName="mb-3 w-full text-[15px] font-medium text-blue-950/80 mb-2 pt-3"
            label={"Choose Services"}
          />
          <div className="">
            <ServiceAccordianList
              serviceTypeData={serviceType}
              subCategoryData={categoryData}
              setServiceTypeChoose={setServiceTypeChoose}
              serviceList={serviceList}
              salonService={salonService}
              onChange={handleSelectChange}
              value={serviceTypeChoose}
              setCategoryChoose={setCategoryChoose}
              categoryChoose={categoryChoose}
              SalonDetailsFilterModalOpen={SalonDetailsFilterModalOpen}
              serviceChoose={serviceTypeChoose}
            />
          </div>
        </div>
        <div className={"col-span-9 flex-grow  md:block hidden"}>
          <div className="flex justify-end items-end mb-1">
            <ButtonPrimary
              onClick={() => setSalonDetailsFilterModalOpen(true)}
              size={"sm"}
              className={"text-[15px] lg:text-[15px] py-1 px-3 rounded-md"}
            >
              <span>
                <FilterIcon className="w-5 h-5" />
              </span>
              Filter
            </ButtonPrimary>
          </div>
          <div className="border border-borderColor py-0.5 px-4 rounded-lg mb-2 text-center font-medium text-[15px] text-blue-950">
            Category
          </div>
          <div className="">
            {
              // [...Array(3)].map((item, index) => (
              //     <ServiceSelectableCard iconArrangeChildLast id={"_id________of_data_" + index} selectedCard={selectedCard} setSelectedCard={setSelectedCard} serviceList={serviceList} />
              // ))
              serviceList?.data?.length > 0 &&
              serviceList.data.flatMap((subArray, index) =>
                subArray?.map((data) => {
                  return (
                    <ServiceSelectableCard
                      id={"_id_of_data_" + index}
                      selectedCard={selectedCard}
                      setSelectedCard={setSelectedCard}
                      selectedIds={selectedIds}
                      handleCheckBoxChange={handleCheckBoxChange}
                      key={data.id}
                      data={data}
                      count={count}
                      handleChangePlusCounter={handleChangePlusCounter}
                      handleChangeMinusCounter={handleChangeMinusCounter}
                    />
                  );
                })
              )
            }
          </div>
        </div>
      </div>
      <div className={"md:hidden block py-3"}>
        <div className="service-choose-slider-wrapper py-1 px-1.5 border border-borderColor mb-2 rounded-[20px]">
          <Slider
            {...shopSliderMain}
            className="slider_flex gap_4 h-full px-10 slider-with-custom-navigation"
          >
            <div className="!flex items-center justify-center gap-2">
              <span>
                <HairdresserIcon className="w-[26px] h-[26px]" />
              </span>
              <h5>Hairdresser</h5>
            </div>
            <div className="!flex items-center justify-center gap-2">
              <span>
                <FacialTreatmentIcon className="w-[26px] h-[26px]" />
              </span>
              <h5>Manicure & Pedicure</h5>
            </div>
            <div className="!flex items-center justify-center gap-2">
              <span>
                <HairRemovalIcon className="w-[26px] h-[26px]" />
              </span>
              <h5>Hair Removal</h5>
            </div>
            <div className="!flex items-center justify-center gap-2">
              <span>
                <FacialTreatmentIcon className="w-[26px] h-[26px]" />
              </span>
              <h5>Physiotherapist</h5>
            </div>
          </Slider>
        </div>
        <div className="border border-borderColor rounded-lg grid grid-cols-4 gap-2 px-2 py-1.5 mb-2">
          <InputCheckbox
            mainClassName="justify-center"
            name="woman"
            id="woman"
            text={t("comman_text.woman")}
          />
          <InputCheckbox
            mainClassName="justify-center"
            name="men"
            id="men"
            text={t("comman_text.men")}
          />
          <InputCheckbox
            mainClassName="justify-center"
            name="girl"
            id="girl"
            text={t("comman_text.girl")}
          />
          <InputCheckbox
            mainClassName="justify-center"
            name="boy"
            id="boy"
            text={t("comman_text.boy")}
          />
        </div>
        <div className="">
          <ul className="flex flex-col">
            {[...Array(12)].map(() => (
              <li className="w-full">
                <Disclosure
                  as="div"
                  className="border-b border-borderColor w-full py-3 rounded-none"
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex items-center justify-center gap-3 w-full relative">
                        <h6 className="text-base text-success-900 font-medium">
                          Haircut
                        </h6>
                        <span>
                          <BottomArrowIcon
                            className={`absolute top-1/2 right-0 -translate-y-1/2 w-3 h-3 text-success-900 transition-all duration-100 ${open && "rotate-180"
                              }`}
                          />
                        </span>
                      </Disclosure.Button>
                      <Disclosure.Panel className="pt-2 text-sm text-gray-500">
                        {[...Array(3)].map((item, index) => (
                          <ServiceSelectableCard
                            iconArrangeChildLast
                            id={"_id________of_data_" + index}
                            selectedCard={selectedCard}
                            setSelectedCard={setSelectedCard}
                          />
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <TextareaWrap
        groupClassName={"w-full"}
        className="rounded-lg"
        rows={4}
        label={t("check_box_label.reason_for_request")}
        labelClassName="text-blue-950 text-[15px]"
      />

      <div className="w-full flex flex-col sm:flex-row justify-between gap-x-5 gap-y-3 mt-3">
        {/* <ButtonPrimary
          size="sm"
          className="w-full"
          onClick={() => setActiveStep(activeStep + 1)}
        >
          Continue
        </ButtonPrimary> */}
        {/* {Object.keys(count).length ? ( */}
        <ButtonPrimary onClick={() => handleBooking()} className="btn-theme w-full ">{t("button.continue")}</ButtonPrimary>
        {/* ) : (
          <ButtonPrimary className="btn-theme w-full disabled">{t("button.continue")}</ButtonPrimary>
        )} */}
      </div>

      <SalonDetailsFilterModal
        modalOpen={SalonDetailsFilterModalOpen}
        setModalOpen={setSalonDetailsFilterModalOpen}
        servicesIds={servicesIds}
        salonService={salonService}
        promotionType={promotionType}
        selectCategoryTpes={selectCategoryTpes}
        handleDiscount={handleDiscount}
        discount_for={discountPrices}
        setDiscountPrices={setDiscountPrices}
      />
    </div>
  );
};

export default withTranslation()(OfferRequestFirstStep);


